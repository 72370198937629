<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="4" offset-md="2" offset-lg="4">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Leadklasser</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createLeadratingButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="!leadratings" indeterminate></v-progress-linear>
          <v-list>
            <v-list-item v-for="leadrating in leadratings" :key="leadrating.leadrating_id">
              <v-list-item-content>
                <v-list-item-title>{{ leadrating.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ leadrating.description }}</v-list-item-subtitle>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateLeadratingButton"
                @click="$refs.entityDialog.showDialog(model, leadrating)"
              />
              <TooltipButton
                v-bind="deleteLeadratingButton"
                @click="
                  getDeleteConfirmation(
                    leadrating,
                    `Bekreft sletting av leadklasse ${leadrating.name}`,
                    `Vil du virkelig slette leadklassen ${leadrating.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet leadklassen ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EntityDialog from '@/components/dialogs/EntityDialog'
import TooltipButton from '@/components/TooltipButton'
import Leadrating from '@/models/Leadrating'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data: () => {
    return {
      loading: false,
      model: Leadrating,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny leadklasse',
        editTitle: 'Rediger leadklasse',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'description',
            type: 'textarea',
            label: 'Beskrivelse',
          },
        ],
      }
    },
    createLeadratingButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('LEADRATING_CREATE'),
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEADRATING_CREATE')
            ? 'Opprett leadklasse'
            : 'Du mangler tillatelse til å opprette leadklasser',
        },
      }
    },
    updateLeadratingButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('LEADRATING_UPDATE'),
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEADRATING_UPDATE')
            ? 'Rediger leadklasse'
            : 'Du mangler tillatelse til å redigere leadklasser',
        },
      }
    },
    deleteLeadratingButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('LEADRATING_DELETE'),
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEADRATING_DELETE')
            ? 'Slett leadklasse'
            : 'Du mangler tillatelse til å slette leadklasser',
        },
      }
    },
    leadratings() {
      let leadratings = Leadrating.query()
        .orderBy('name')
        .get()
      return leadratings
    },
  },
  methods: {
    handleEntityCreated(leadrating) {
      this.$refs.entityDialog.leadrating = leadrating
      this.$successToast(`Du opprettet leadklassen ${leadrating.name}`)
    },
    handleEntityUpdated(leadrating) {
      this.$refs.entityDialog.leadrating = leadrating
      this.$successToast(`Endringen av leadklassen ${leadrating.name} er lagret`)
    },
  },
}
</script>
