var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$currentUser)?_c('v-container',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"5","xl":"4"}},[_c('v-card',{staticClass:"mb-8"},[_c('v-card-title',{staticClass:"primary"},[_vm._v(" Konsept: "),(_vm.concept.parent_id)?[_vm._v(" "+_vm._s(_vm.concept.parent.name)+" • ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.concept.name)+" ")],2),_c('v-card-text',{staticClass:"pa-4"},[_c('dl',[(_vm.concept.parent)?[_c('dt',[_vm._v("Foreldrekonsept")]),_c('dd',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                      name: 'concept',
                      params: { concept_id: _vm.concept.parent.concept_id },
                    }}},[_vm._v(_vm._s(_vm.concept.parent.name)+" ")])],1)]:_vm._e(),_c('dt',[_vm._v("Standard pris per lead")]),_c('dd',[_vm._v(_vm._s(_vm.concept.default_lead_price))]),(_vm.childConcepts.length)?[_c('dt',[_vm._v(_vm._s(_vm.childConcepts.length)+" underkonsepter")]),_c('dd',[_c('ul',_vm._l((_vm.childConcepts),function(childConcept){return _c('li',{key:childConcept.concept_id,staticClass:"mb-1"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                          name: 'concept',
                          params: { concept_id: childConcept.concept_id },
                        }}},[_vm._v(_vm._s(childConcept.name))])],1)}),0)])]:_vm._e()],2)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"7","xl":"8"}},[_c('v-card',{staticClass:"mb-8"},[_c('v-card-title',{staticClass:"primary"},[_vm._v(" Konseptområder "),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.$refs.conceptareaDialog.showDialog(_vm.conceptareaModel, {
                  concept_id: _vm.concept.concept_id,
                })}}},'TooltipButton',_vm.createConceptareaButton,false))],1),_c('v-card-text',{staticClass:"pa-4"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"144"}}):_c('ul',{staticClass:"childconceptareas"},_vm._l((_vm.conceptareas),function(conceptarea){return _c('li',{key:conceptarea.conceptarea_id},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/conceptareas/" + (conceptarea.conceptarea_id))}},[_vm._v(" "+_vm._s(conceptarea.area.name)+" ")])],1)}),0)],1)],1)],1)],1),_c('v-speed-dial',{attrs:{"fixed":"","bottom":"","right":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","fab":""},model:{value:(_vm.showSpeedDial),callback:function ($$v) {_vm.showSpeedDial=$$v},expression:"showSpeedDial"}},[(_vm.showSpeedDial)?_c('v-icon',[_vm._v("close")]):_c('v-icon',[_vm._v("settings")])],1)]},proxy:true}],null,false,3807752648),model:{value:(_vm.showSpeedDial),callback:function ($$v) {_vm.showSpeedDial=$$v},expression:"showSpeedDial"}},[_c('div',{attrs:{"transition":"slide-y-reverse-transition"}},[_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.getDeleteConfirmation(
              _vm.concept,
              ("Bekreft sletting av konsept " + (_vm.concept.name)),
              ("Vil du virkelig slette konseptet " + (_vm.concept.name) + " for alltid?")
            )}}},'TooltipButton',_vm.deleteConceptButton,false)),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.$refs.conceptDialog.showDialog(_vm.conceptModel, _vm.concept)}}},'TooltipButton',_vm.updateConceptButton,false))],1)]),_c('EntityDialog',_vm._b({ref:"conceptDialog",on:{"entityUpdated":_vm.handleConceptUpdated}},'EntityDialog',_vm.conceptDialog,false)),_c('EntityDialog',_vm._b({ref:"conceptareaDialog",on:{"entityCreated":_vm.handleConceptareaCreated}},'EntityDialog',_vm.conceptareaDialog,false)),_c('DeleteConfirmationDialog',_vm._b({ref:"deleteConfirmationDialog",on:{"cancel":_vm.closeAndResetDeleteConfirmationDialog,"success":function($event){_vm.$router.push({ name: 'concepts' }, function () {
          _vm.$successToast('Du slettet konseptet')
        })}}},'DeleteConfirmationDialog',_vm.deleteConfirmationDialog,false)),_c('ErrorDialog',_vm._b({on:{"closeAndReset":_vm.clearErrorDialog}},'ErrorDialog',_vm.errorDialog,false))],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }