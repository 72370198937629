<template>
  <v-container v-if="$currentUser" class="pa-md-8 pa-lg-16">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="success" class="white--text align-center">
            <v-toolbar-title>Antall ufordelte leads</v-toolbar-title>
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-row class="px-4 pt-6 pb-4">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mb-6 mb-sm-0">
              <v-select
                v-model="selectedConceptId"
                :items="topLevelConcepts"
                label="Konsept"
                item-text="name"
                item-value="concept_id"
                :disabled="loading"
                dense
                hide-details
                @change="handleFiltersChange"
              />
            </v-col>
            <v-col cols="12" sm="3" md="2" xl="1">
              <v-select
                v-model="selectedYear"
                :items="years"
                label="År"
                :disabled="loading"
                dense
                hide-details
                @change="handleFiltersChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <table v-if="Object.keys(unassignedLeadsCount).length">
                <thead>
                  <tr>
                    <th>Område</th>
                    <th>{{ selectedYear }}</th>
                    <th>Januar</th>
                    <th>Februar</th>
                    <th>Mars</th>
                    <th>April</th>
                    <th>Mai</th>
                    <th>Juni</th>
                    <th>Juli</th>
                    <th>August</th>
                    <th>September</th>
                    <th>Oktober</th>
                    <th>November</th>
                    <th>Desember</th>
                  </tr>
                </thead>
                <tbody v-for="(region, regionName) in unassignedLeadsCount" :key="regionName">
                  <tr class="region-row">
                    <td>{{ regionName }}</td>
                    <td>{{ region.total }}</td>
                    <td>{{ getMonthlyData(region, '01') }}</td>
                    <td>{{ getMonthlyData(region, '02') }}</td>
                    <td>{{ getMonthlyData(region, '03') }}</td>
                    <td>{{ getMonthlyData(region, '04') }}</td>
                    <td>{{ getMonthlyData(region, '05') }}</td>
                    <td>{{ getMonthlyData(region, '06') }}</td>
                    <td>{{ getMonthlyData(region, '07') }}</td>
                    <td>{{ getMonthlyData(region, '08') }}</td>
                    <td>{{ getMonthlyData(region, '09') }}</td>
                    <td>{{ getMonthlyData(region, '10') }}</td>
                    <td>{{ getMonthlyData(region, '11') }}</td>
                    <td>{{ getMonthlyData(region, '12') }}</td>
                  </tr>
                  <tr v-for="(area, areaName) in region.areas" :key="areaName" class="area-row">
                    <td>{{ areaName }}</td>
                    <td>{{ area.total }}</td>
                    <td>{{ getMonthlyData(area, '01') }}</td>
                    <td>{{ getMonthlyData(area, '02') }}</td>
                    <td>{{ getMonthlyData(area, '03') }}</td>
                    <td>{{ getMonthlyData(area, '04') }}</td>
                    <td>{{ getMonthlyData(area, '05') }}</td>
                    <td>{{ getMonthlyData(area, '06') }}</td>
                    <td>{{ getMonthlyData(area, '07') }}</td>
                    <td>{{ getMonthlyData(area, '08') }}</td>
                    <td>{{ getMonthlyData(area, '09') }}</td>
                    <td>{{ getMonthlyData(area, '10') }}</td>
                    <td>{{ getMonthlyData(area, '11') }}</td>
                    <td>{{ getMonthlyData(area, '12') }}</td>
                  </tr>
                </tbody>
              </table>
              <template v-else>
                <em v-if="selectedConceptId && selectedYear && !loading" class="pa-4 d-block"
                  >Ingen ufordelte leads for valgt konsept / år</em
                >
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <ErrorDialog v-bind="errorDialog" @closeAndReset="clearErrorDialog" />
  </v-container>
</template>

<script>
import dateAndTime from '@/mixins/dateAndTime'
import errorDialog from '@/mixins/dialogs/errorDialog.js'
import Concept from '@/models/Concept'
import Lead from '@/models/Lead'
import ErrorDialog from '@/components/dialogs/ErrorDialog'

export default {
  components: { ErrorDialog },
  mixins: [dateAndTime, errorDialog],
  data() {
    return {
      loading: false,
      selectedConceptId: null,
      selectedYear: new Date().getFullYear(),
      years: this.getYearsFrom(2022),
      unassignedLeadsCount: {},
    }
  },
  computed: {
    topLevelConcepts() {
      return Concept.query()
        .where(concept => concept.parent_id == null)
        .get()
    },
  },
  methods: {
    async handleFiltersChange() {
      if (!this.selectedConceptId) return

      this.loading = true

      try {
        const result = await Lead.fetchUnassignedLeadsCount(
          this.selectedConceptId,
          this.selectedYear
        )
        this.unassignedLeadsCount = result.response.data
      } catch (e) {
        this.handleErrorInDialog(`Feil under innlasting av data: ${e.message}`)
      } finally {
        this.loading = false
      }
    },
    getMonthlyData(data, month) {
      const key = `${this.selectedYear}-${month}`
      return data[key] || ''
    },
  },
}
</script>

<style scoped lang="less">
.container {
  max-width: 100% !important;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.6rem;
  background: #fff;

  .theme--dark & {
    background: #333;
  }

  @media (min-width: 550px) {
    font-size: 0.75rem;
  }

  @media (min-width: 800px) {
    font-size: 0.9rem;
  }

  @media (min-width: 1150px) {
    font-size: 1rem;
  }
}

th,
td {
  padding: 0.6rem;
  text-align: right;

  @media (min-width: 1200px) {
    padding: 0.8rem;
  }

  @media (min-width: 1600px) {
    padding: 0.9rem;
  }

  @media (min-width: 1800px) {
    padding: 1rem;
  }

  &:first-child {
    text-align: left;
  }
}

th:nth-child(n + 3) {
  font-size: 0.65em;

  @media (max-width: 1000px) {
    max-width: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

td:nth-child(n + 3) {
  font-size: 0.85em;
}

th,
td {
  &:nth-child(n + 3) {
    @media (min-width: 1200px) {
      font-size: 0.8em;
    }
    @media (min-width: 1400px) {
      font-size: 0.9em;
    }
    @media (min-width: 1600px) {
      font-size: 1em;
    }
  }
}

tr {
  border-bottom: 1px solid #eee;

  .theme--dark & {
    border-color: #2d2d2d;
  }
}

td:nth-child(2) {
  text-align: right;
  border-right: 1px solid #ddd;

  .theme--dark & {
    border-color: #222;
  }
}

thead {
  tr {
    position: sticky;
    top: 0;
    background: #f5f5f5;
    border-color: #ddd;
    text-transform: uppercase;
    color: #444;

    .theme--dark & {
      background: #111;
      color: #ddd;
      border-color: #222;
    }
  }
}

.region-row {
  background: #f5f5f5;

  .theme--dark & {
    background: #111;
  }

  td:nth-child(1),
  td:nth-child(2) {
    font-weight: 500;
  }
}

.area-row td:first-child {
  padding-left: 3ch;
}
</style>
