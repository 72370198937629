import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import { setupStore } from './store'
import currentUser from './mixins/currentUser'
import validationRules from '@/mixins/validationRules'
import toast from '@/mixins/toast'
import DOMpurify from 'dompurify'
import textVersion from 'textversionjs'

Vue.prototype.$moment = moment
moment.locale('nb')

Vue.mixin(currentUser)
Vue.mixin(validationRules)
Vue.mixin(toast)

Vue.filter('moment', (val, formatOut, formatIn = '') => {
  if (val) {
    return moment(val, formatIn).format(formatOut)
  }
})
Vue.filter('currency', val => {
  if (val) {
    return Number(val)
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
})
Vue.filter('orgnr', val => {
  if (val) {
    return val
      .toString()
      .replace(/(.{3})/g, '$1 ')
      .trim()
  }
})
Vue.filter('phone', val => {
  if (val && !isNaN(val)) {
    return val
      .toString()
      .replace(/(.{2})/g, '$1 ')
      .trim()
  }
  return val
})
Vue.filter('truncateString', (originalString, numLetters, ellipsis = false) => {
  if (!originalString || !numLetters) {
    return
  }

  const truncatedString = originalString.toString().substr(0, numLetters)

  if (ellipsis && truncatedString.length < originalString.length) {
    return `${truncatedString}...`
  }

  return truncatedString
})
Vue.filter('capitalizeFirstLetter', string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
})
Vue.filter('sanitizeHtml', html => {
  return DOMpurify.sanitize(html)
})
Vue.filter('htmlToTxt', html => {
  return textVersion(html)
})
Vue.filter('pathFromUrl', function(value) {
  if (!value) return ''
  return new URL(value).pathname
})

Vue.config.productionTip = false

const store = setupStore()

const vue = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
})

vue.$mount('#app')
