import BaseModel from './BaseModel'
import Company from './Company'
import Email from './Email'

export default class LeadCompany extends BaseModel {
  static entity = 'leads_companies'

  static primaryKey = ['lead_id', 'company_id']

  static fields() {
    return {
      lead_id: this.attr(null),
      company_id: this.attr(null),
      email_id: this.attr(null),
      email: this.hasOne(Email, 'email_id', 'email_id'),
      company: this.hasOne(Company, 'company_id', 'company_id'),
    }
  }
}
