import moment from 'moment'

export default {
  computed: {
    $validationRules() {
      return {
        required: value => !!value || 'Påkrevd',
        multiselectRequired: value => value.length > 0 || 'Påkrevd',
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Ugyldig epostadresse'
          }
          return true
        },
        datetime: value => {
          if (value) {
            const format = 'YYYY-MM-DD HH:mm:ss'
            return moment(value, format, true).isValid() || `Format: ${format}`
          }
          return true
        },
        url: value => {
          if (value) {
            /* eslint-disable-next-line no-useless-escape*/
            const pattern = /https?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/
            return pattern.test(value) || 'Ugyldig URL'
          }
          return true
        },
        domainname: value => {
          if (value) {
            // Regex explanation:
            // ^ - Start of the string
            // (?!www\.) - Negative lookahead to ensure the string does not start with "www."
            // [\w-]+ - Matches the first part of the domain (subdomain or primary domain), must be at least one character
            // (\.[\w-]+)+ - Matches the domain extension and any subdomains. There must be at least one dot followed by one or more word characters or hyphens.
            // $ - End of the string
            const pattern = /^(?!www\.)([\w-]+(\.[\w-]+)+)$/
            return pattern.test(value) || 'Ugyldig domenenavn'
          }
          return true
        },
      }
    },
  },
}
