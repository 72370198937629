import BaseModel from '@/models/BaseModel'

export default class Website extends BaseModel {
  static entity = 'websites'
  static primaryKey = 'website_id'

  static fields() {
    return {
      website_id: this.attr(null),
      domainname: this.string(),
    }
  }
}
