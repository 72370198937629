import { Database } from '@vuex-orm/core'
import Apikey from '@/models/Apikey'
import ApikeyPermission from '@/models/ApikeyPermission'
import Area from '@/models/Area'
import Company from '@/models/Company'
import Concept from '@/models/Concept'
import Conceptarea from '@/models/Conceptarea'
import ConceptareaCompany from '@/models/ConceptareaCompany'
import Email from '@/models/Email'
import Emailtemplate from '@/models/Emailtemplate'
import Lead from '@/models/Lead'
import LeadCompany from '@/models/LeadCompany'
import Leadrating from '@/models/Leadrating'
import Leadtype from '@/models/Leadtype'
import Listing from '@/models/Listing'
import Pause from '@/models/Pause'
import Pausereason from '@/models/Pausereason'
import Permission from '@/models/Permission'
import Salesperson from '@/models/Salesperson'
import User from '@/models/User'
import UserPermission from '@/models/UserPermission'
import Website from '@/models/Website'

const database = new Database()

database.register(Apikey, {})
database.register(ApikeyPermission, {})
database.register(Area, {})
database.register(Company, {})
database.register(Concept, {})
database.register(Conceptarea, {})
database.register(ConceptareaCompany, {})
database.register(Email, {})
database.register(Emailtemplate, {})
database.register(Lead, {})
database.register(LeadCompany, {})
database.register(Leadrating, {})
database.register(Leadtype, {})
database.register(Listing, {})
database.register(Pause, {})
database.register(Pausereason, {})
database.register(Permission, {})
database.register(Salesperson, {})
database.register(User, {})
database.register(UserPermission, {})
database.register(Website, {})

export default database
