<template>
  <v-app>
    <MainNav />
    <v-main>
      <router-view v-if="$loggedIn" @toast="toast" />
      <LoginForm v-else />

      <v-snackbar v-model="snackbar.show" v-bind="snackbar.props">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.show = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import preferences from './mixins/preferences'
import MainNav from './components/MainNav'
import LoginForm from './components/LoginForm'
import Area from '@/models/Area'
import Concept from '@/models/Concept'
import Emailtemplate from '@/models/Emailtemplate'
import Leadrating from '@/models/Leadrating'
import Leadtype from '@/models/Leadtype'
import Pausereason from '@/models/Pausereason'
import Permission from '@/models/Permission'
import Salesperson from '@/models/Salesperson'
import User from '@/models/User'
import UserPermission from '@/models/UserPermission'
import Website from '@/models/Website'

export default {
  name: 'App',
  components: {
    MainNav,
    LoginForm,
  },
  mixins: [preferences],
  data: function() {
    return {
      snackbar: {
        show: false,
        props: this.defaultSnackbarProps,
      },
    }
  },
  computed: {
    defaultSnackbarProps() {
      return {
        color: 'primary',
        bottom: true,
        left: false,
        multiLine: false,
        right: false,
        timeout: -1,
        top: false,
        vertical: false,
      }
    },
  },
  async created() {
    if (!this.$loggedIn) return

    try {
      //fetch global data
      await Promise.all([
        Area.fetchAll(),
        Concept.fetchAll(),
        Emailtemplate.fetchAll(),
        Leadrating.fetchAll(),
        Leadtype.fetchAll(),
        Pausereason.fetchAll(),
        Permission.fetchAll(),
        Salesperson.fetchAll(),
        UserPermission.fetchAll(),
        User.fetchAll(),
        Website.fetchAll(),
      ])

      this.applyPreferences()
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
  },
  methods: {
    toast(toast) {
      this.snackbar.text = toast.text
      this.snackbar.props = toast.props ? toast.props : this.defaultSnackbarProps
      this.snackbar.show = true
    },
  },
}
</script>

<style lang="css">
html {
  --primary: #1665a7;
  --secondary: #424242;
  --accent: #82b1ff;
  --error: #ff5252;
  --info: #2196f3;
  --success: #4caf50;
  --warning: #ffc107;

  --blue: #2196f3;
  --blue-lighten-5: #e3f2fd;
  --blue-lighten-4: #bbdefb;
  --blue-lighten-3: #90caf9;
  --blue-lighten-2: #64b5f6;
  --blue-lighten-1: #42a5f5;
  --blue-darken-1: #1e88e5;
  --blue-darken-2: #1976d2;
  --blue-darken-3: #1565c0;
  --blue-darken-4: #0d47a1;
  --blue-accent-1: #82b1ff;
  --blue-accent-2: #448aff;
  --blue-accent-3: #2979ff;
  --blue-accent-4: #2962ff;

  --grey: #9e9e9e;
  --grey-lighten-5: #fafafa;
  --grey-lighten-4: #f5f5f5;
  --grey-lighten-3: #eeeeee;
  --grey-lighten-2: #e0e0e0;
  --grey-lighten-1: #bdbdbd;
  --grey-darken-1: #757575;
  --grey-darken-2: #616161;
  --grey-darken-3: #424242;
  --grey-darken-4: #212121;
}
</style>
<style lang="less">
@primary: #1665a7;
@secondary: #424242;
@accent: #82b1ff;
@error: #ff5252;
@info: #2196f3;
@success: #4caf50;
@warning: #ffc107;

@blue: #2196f3;
@blue-lighten-5: #e3f2fd;
@blue-lighten-4: #bbdefb;
@blue-lighten-3: #90caf9;
@blue-lighten-2: #64b5f6;
@blue-lighten-1: #42a5f5;
@blue-darken-1: #1e88e5;
@blue-darken-2: #1976d2;
@blue-darken-3: #1565c0;
@blue-darken-4: #0d47a1;
@blue-accent-1: #82b1ff;
@blue-accent-2: #448aff;
@blue-accent-3: #2979ff;
@blue-accent-4: #2962ff;

@grey: #9e9e9e;
@grey-lighten-5: #fafafa;
@grey-lighten-4: #f5f5f5;
@grey-lighten-3: #eeeeee;
@grey-lighten-2: #e0e0e0;
@grey-lighten-1: #bdbdbd;
@grey-darken-1: #757575;
@grey-darken-2: #616161;
@grey-darken-3: #424242;
@grey-darken-4: #212121;

a {
  color: @blue-darken-1;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default;
}

.white-space-nowrap {
  white-space: nowrap;
}

.pre-formatted {
  white-space: pre-wrap;
}

.v-card__title {
  word-break: break-word;

  .v-btn {
    color: inherit !important;
  }
}

.v-main__wrap {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
  background: rgb(238, 238, 238); /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .theme--dark & {
    background-color: transparent;
    background-image: url('./images/dark-bg.png');
    background-size: cover;
  }
}

.v-text-field__details .v-messages__message {
  /* stop validation error messages from overflowing */
  line-height: 1.3;
}

pre {
  font-family: Roboto, sans-serif;
  white-space: pre-wrap;
}

.v-textarea textarea {
  line-height: 1.5;
}

.theme--dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

//dark theme  table background-color on hover
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: @blue-darken-4;
}

.pulsating-badge .v-badge__badge {
  animation: pulsating 1.5s infinite ease-in-out;
}

@keyframes pulsating {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
