import User from '@/models/User'

export default {
  computed: {
    $currentUser() {
      const email = localStorage.getItem('email')
      if (!email) return

      return User.query()
        .with('permissions')
        .where('email', email)
        .first()
    },
  },
}
