import BaseModel from '@/models/BaseModel'

export default class Leadrating extends BaseModel {
  static entity = 'leadratings'
  static primaryKey = 'leadrating_id'

  static fields() {
    return {
      leadrating_id: this.attr(null),
      name: this.string(),
      description: this.string(),
    }
  }
}
