<template>
  <v-container class="pa-16">
    <v-row>
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card>
          <v-toolbar color="success">
            <v-toolbar-title class="white--text">Bedrifter</v-toolbar-title>
            <v-spacer />
            <TooltipButton v-bind="createCompanyButton" @click="$refs.companyDialog.showDialog()" />
          </v-toolbar>

          <!-- filters ----------------------------------------------------------------->
          <v-layout class="px-4 py-2">
            <v-row>
              <v-col>
                <v-text-field v-model="search.name" label="Firmanavn" clearable />
              </v-col>
              <v-col>
                <v-text-field v-model="search.orgnr" label="Orgnr" type="number" clearable />
              </v-col>
              <v-col>
                <v-text-field v-model="search.contactperson" label="Kontaktperson" clearable />
              </v-col>
              <v-col>
                <v-text-field v-model="search.emailaddress" label="E-post" type="email" clearable />
              </v-col>
              <v-col>
                <v-text-field v-model="search.phonenumber" label="Telefon" clearable />
              </v-col>
              <v-col>
                <v-select
                  :items="customerStatusFilterItems"
                  label="Kundeforhold"
                  clearable
                  @change="updateCustomerStatusFilter"
                />
              </v-col>
            </v-row>
          </v-layout>

          <!-- companies table ------------------------------------------------------->
          <v-data-table
            :items="companies"
            item-key="company_id"
            :loading="loading"
            v-bind.sync="dataTable"
            loading-text="Henter data"
            @update:page="fetchData"
            @update:items-per-page="fetchData"
            @hook:mounted="fetchData"
          >
            <template v-slot:item="companies">
              <tr>
                <td>
                  <router-link
                    :to="{
                      name: 'company',
                      params: { company_id: companies.item.company_id },
                    }"
                    style="text-decoration: none;"
                  >
                    {{ companies.item.name }}
                  </router-link>
                </td>
                <td class="white-space-nowrap">
                  <a
                    :href="`https://proff.no/bransjes%C3%B8k?q=${companies.item.orgnr}`"
                    target="_blank"
                  >
                    {{ companies.item.orgnr | orgnr }}
                  </a>
                </td>
                <td class="white-space-nowrap">
                  <a
                    :href="`https://proff.no/rolles%C3%B8k?q=${companies.item.contactperson}`"
                    target="_blank"
                  >
                    {{ companies.item.contactperson }}
                  </a>
                </td>
                <td class="white-space-nowrap">
                  <a :href="`tel:${companies.item.phonenumber}`">
                    {{ companies.item.phonenumber | phone }}
                  </a>
                </td>
                <td class="white-space-nowrap">
                  <a :href="`mailto:${companies.item.emailaddress}`">
                    {{ companies.item.emailaddress }}
                  </a>
                </td>
                <td>
                  <TooltipButton
                    v-bind="updateCompanyButton"
                    @click="$refs.companyDialog.showDialog(companies.item)"
                  />

                  <TooltipButton
                    v-bind="deleteCompanyButton"
                    @click="
                      getDeleteConfirmation(
                        companies.item,
                        `Bekreft sletting av bedrift ${companies.item.name}`,
                        `Vil du virkelig slette bedriften ${companies.item.name} for alltid?`
                      )
                    "
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <CompanyDialog ref="companyDialog" />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet bedriften ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import debounce from 'debounce'
import combineArrays from '@/mixins/combineArrays'
import companyDialog from '@/mixins/dialogs/companyDialog.js'
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import updateDatatableOptions from '@/mixins/dataTable'
import responseHelper from '@/mixins/responseHelper'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import CompanyDialog from '@/components/dialogs/CompanyDialog'
import TooltipButton from '@/components/TooltipButton'
import Company from '@/models/Company'

export default {
  components: {
    CompanyDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [
    combineArrays,
    companyDialog,
    deleteConfirmationDialog,
    responseHelper,
    updateDatatableOptions,
  ],
  data: () => {
    return {
      model: Company,
      loading: true,
      dataTable: {
        headers: [
          { text: 'Navn', value: 'name' },
          { text: 'Orgnr', value: 'orgnr' },
          { text: 'Kontaktperson', value: 'contactperson', sortable: false },
          { text: 'Telefon', value: 'phonenumber', sortable: false },
          { text: 'E-post', value: 'emailaddress', sortable: false },
          { text: '', value: 'edit', sortable: false },
        ],
      },
      customerStatusFilterItems: ['Prospekt', 'Aktivt kundeforhold', 'Tidligere kunde'],
      search: {},
    }
  },
  computed: {
    deleteCompanyButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('COMPANY_DELETE')) ||
            this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('COMPANY_DELETE')
              ? 'Slett bedrift'
              : 'Du mangler tillatelse til å slette bedrifter',
        },
      }
    },
    companies() {
      return Company.query()
        .orderBy(this.dataTable.sortBy, this.dataTable.sortDesc ? 'desc' : 'asc')
        .get()
    },
  },
  watch: {
    search: {
      handler: debounce(function() {
        this.fetchData()
      }, 750),
      deep: true,
    },
  },
  methods: {
    updateCustomerStatusFilter(status) {
      delete this.search.is_paying_customer
      delete this.search.is_active_customer

      if (status == 'Prospekt') {
        this.search.is_paying_customer = false
      }
      if (status == 'Aktivt kundeforhold') {
        this.search.is_paying_customer = true
        this.search.is_active_customer = true
      }
      if (status == 'Tidligere kunde') {
        this.search.is_paying_customer = true
        this.search.is_active_customer = false
      }
      this.fetchData()
    },
    async fetchData() {
      this.loading = true

      const companies = await Company.fetchPaginated({
        pagination: this.dataTable.options,
        sort: this.dataTable.sort,
        search: this.search,
      })
      this.dataTable.serverItemsLength = companies.response.data.total

      if (this.dataTable.serverItemsLength) {
        //fetch relations here
      }
      this.loading = false
    },
  },
}
</script>
