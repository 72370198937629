<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2" offset-xl="2">
        <v-select
          v-model="salespersonFilter"
          label="Selgere"
          item-text="full_name"
          item-value="salesperson_id"
          :items="salespersons"
          clearable
          multiple
          @change="fetchLeads"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <v-select
          v-model="baitSentFilter"
          label="Sendt til prospekt"
          :items="baitSentFilterItems"
          clearable
          @change="fetchLeads"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <v-menu
          v-model="showBaitAssignedDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="baitAssignedDateText"
              label="Tildelt dato"
              clearable
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="baitAssignedDateFilter"
            no-title
            clearable
            locale="no-NO"
            :first-day-of-week="1"
            range
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="8" offset-xl="2">
        <LeadsIterator
          ref="leadsIterator"
          :iterator-options="leadsIterator"
          :search="search"
          title="Agn"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import dateAndTime from '@/mixins/dateAndTime'
import leadsIterator from '@/mixins/leadsIterator'
import responseHelper from '@/mixins/responseHelper'
import LeadsIterator from '@/components/LeadsIterator'
import Salesperson from '@/models/Salesperson'

export default {
  components: {
    LeadsIterator,
  },
  mixins: [dateAndTime, deleteConfirmationDialog, leadsIterator, responseHelper],
  data: () => {
    return {
      salespersonFilter: [],
      baitAssignedDateFilter: [],
      showBaitAssignedDatePicker: false,
      baitSentFilter: null,
      baitSentFilterItems: [
        { text: 'Vis kun sendte', value: { compare: 'exists' } },
        { text: 'Vis kun ikke sendte', value: { compare: 'null' } },
      ],
    }
  },
  computed: {
    baitAssignedDateText: {
      get() {
        if (!this.baitAssignedDateFilter.length) {
          return ''
        }

        const formattedDates = this.baitAssignedDateFilter
          .toSorted() //in case the "to" date is picked before the "from" date
          .map(date => this.formatDate(date))
        return formattedDates.join(' - ')
      },
      set(newValue) {
        if (newValue == null) {
          this.baitAssignedDateFilter = []
        }
      },
    },
    salespersons() {
      return Salesperson.all()
    },
    search() {
      const searchParameters = []

      if (this.salespersonFilter.length) {
        searchParameters.push({ salesperson_id: this.salespersonFilter })
      } else {
        //default to "any salesperson"
        searchParameters.push({ salesperson_id: { compare: 'exists' } })
      }

      if (this.baitSentFilter) {
        searchParameters.push({ bait_sent_to: this.baitSentFilter })
      }

      if (this.baitAssignedDateFilter.length == 1) {
        searchParameters.push({ bait_assigned_timestamp: this.baitAssignedDateFilter[0] })
      }

      if (this.baitAssignedDateFilter.length == 2) {
        const sortedDates = this.baitAssignedDateFilter.toSorted()

        searchParameters.push(
          {
            bait_assigned_timestamp: {
              compare: 'gte',
              value: sortedDates[0],
            },
          },
          {
            bait_assigned_timestamp: {
              compare: 'lte',
              value: sortedDates[1],
            },
          }
        )
      }

      return searchParameters
    },
  },
  watch: {
    baitAssignedDateFilter: 'fetchLeads',
  },
  methods: {
    async fetchLeads() {
      await this.$nextTick()
      this.$refs.leadsIterator.fetchData()
    },
  },
}
</script>
