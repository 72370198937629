<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Pausegrunner </v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createPausereasonButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item v-for="pausereason in pausereasons" :key="pausereason.pausereason_id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ pausereason.name }}
                </v-list-item-title>
              </v-list-item-content>

              <TooltipButton
                v-bind="updatePausereasonButton"
                @click="$refs.entityDialog.showDialog(model, pausereason)"
              />

              <TooltipButton
                v-bind="deletePausereasonButton"
                @click="
                  getDeleteConfirmation(
                    pausereason,
                    `Bekreft sletting av pausegrunn ${pausereason.name}`,
                    `Vil du virkelig slette pausegrunnen ${pausereason.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet pausegrunnen ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/TooltipButton'
import Pausereason from '@/models/Pausereason'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data() {
    return {
      loading: false,
      model: Pausereason,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny pausegrunn',
        editTitle: 'Rediger pausegrunn',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createPausereasonButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('PAUSEREASON_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PAUSEREASON_CREATE')
            ? 'Opprett pausegrunn'
            : 'Du mangler tillatelse til å opprette pausegrunner',
        },
      }
    },
    updatePausereasonButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('PAUSEREASON_UPDATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PAUSEREASON_UPDATE')
            ? 'Rediger pausegrunn'
            : 'Du mangler tillatelse til å redigere pausegrunner',
        },
      }
    },
    deletePausereasonButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('PAUSEREASON_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PAUSEREASON_DELETE')
            ? 'Slett pausegrunn'
            : 'Du mangler tillatelse til å slette pausegrunner',
        },
      }
    },
    pausereasons() {
      let pausereasons = Pausereason.query()
        .orderBy('name')
        .get()

      return pausereasons
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Du opprettet pausegrunnen ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Endringen av pausegrunnen ${instance.name} er lagret`)
    },
  },
}
</script>
