import BaseModel from '@/models/BaseModel'
import Conceptarea from '@/models/Conceptarea'
import ConceptareaCompany from '@/models/ConceptareaCompany'
import Lead from '@/models/Lead'
import LeadCompany from '@/models/LeadCompany'
import Listing from '@/models/Listing'
import Pause from '@/models/Pause'

export default class Company extends BaseModel {
  static entity = 'companies'
  static primaryKey = 'company_id'

  static fields() {
    return {
      company_id: this.attr(null),
      orgnr: this.string(),
      name: this.string(),
      contactperson: this.string(),
      emailaddress: this.string(),
      phonenumber: this.string(),
      is_active_customer: this.boolean(false),
      is_paying_customer: this.boolean(false),
      is_paused: this.boolean(false),
      lead_count_all: this.number(null),
      lead_count_30_days: this.number(null),
      lead_count_90_days: this.number(null),
      lead_count_365_days: this.number(null),
      leads: this.belongsToMany(Lead, LeadCompany, 'company_id', 'lead_id'),
      listings: this.hasMany(Listing, 'company_id', 'company_id'),
      conceptareas: this.belongsToMany(
        Conceptarea,
        ConceptareaCompany,
        'company_id',
        'conceptarea_id'
      ),
      pauses: this.hasMany(Pause, 'company_id', 'company_id'),
    }
  }
}
