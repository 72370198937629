<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Bedriftsprofiler</v-toolbar-title>
            <v-spacer />
            <TooltipButton v-bind="createListingButton" @click="$refs.listingDialog.showDialog()" />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-data-table
            :items="listings"
            item-key="listing_id"
            :loading="loading"
            v-bind.sync="dataTable"
            loading-text="Henter data"
            @update:page="fetchData"
            @update:items-per-page="fetchData"
            @hook:mounted="fetchData"
          >
            <template v-slot:item.company.name="{ item }">
              <router-link
                v-if="item.company"
                :to="{
                  name: 'company',
                  params: { company_id: item.company.company_id },
                }"
                style="text-decoration: none;"
              >
                {{ item.company.name }}
              </router-link>
            </template>
            <template v-slot:item.url="{ item }">
              <a :href="item.url" target="_blank">{{ item.url | pathFromUrl }}</a>
            </template>
            <template v-slot:item.actions="{ item }">
              <TooltipButton
                v-bind="updateListingButton"
                @click="$refs.listingDialog.showDialog(item)"
              />

              <TooltipButton
                v-bind="deleteListingButton"
                @click="
                  getDeleteConfirmation(
                    item,
                    `Bekreft sletting av bedriftsprofil på ${item.url}`,
                    `Vil du virkelig slette bedriftsprofilen på ${item.url} for alltid?`
                  )
                "
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <ListingDialog ref="listingDialog" />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet bedriftsprofilen på ${deleteConfirmationDialog.entityToDelete.url}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import listingDialog from '@/mixins/dialogs/listingDialog.js'
import responseHelper from '@/mixins/responseHelper'
import dataTable from '@/mixins/dataTable'
import ListingDialog from '@/components/dialogs/ListingDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/TooltipButton'
import Company from '@/models/Company'
import Lead from '@/models/Lead'
import Listing from '@/models/Listing'

export default {
  components: {
    DeleteConfirmationDialog,
    ListingDialog,
    TooltipButton,
  },
  mixins: [dataTable, deleteConfirmationDialog, listingDialog, responseHelper],
  data() {
    return {
      loading: false,
      model: Listing,
      dataTable: {
        headers: [
          { text: 'ID', value: 'listing_id' },
          { text: 'Bedrift', value: 'company.name' },
          { text: 'Nettsted', value: 'website.domainname' },
          { text: 'URL', value: 'url' },
          { text: 'Leads', value: 'leads.length' },
          { text: 'Telefoner', value: 'phonecall_count' },
          { text: 'Visninger', value: 'impression_count' },
          { text: '', value: 'actions', sortable: false },
        ],
      },
    }
  },
  computed: {
    listings() {
      let listings = Listing.query()
        .with('company')
        .with('website')
        .with('leads')
        .get()

      return listings
    },
  },
  methods: {
    async fetchData() {
      this.loading = true

      try {
        const listings = await Listing.fetchAll()

        const companyIds = this.getIdsFromEntities(listings, 'company_id')
        const listingIds = this.getIdsFromEntities(listings, 'listing_id')
        await Promise.all([
          Company.fetchBatch(companyIds),
          await Lead.fetchBatchByKey('listing_id', listingIds),
        ])
      } catch (e) {
        console.log('Error fetching data: ', e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
