<template>
  <EntityDialog
    ref="entityDialog"
    v-bind="companyDialog"
    @entityCreated="handleEntityCreated"
    @entityUpdated="handleEntityUpdated"
  />
</template>

<script>
import EntityDialog from '@/components/dialogs/EntityDialog.vue'
import Company from '@/models/Company.js'

export default {
  name: 'CompanyDialog',
  components: {
    EntityDialog,
  },
  mixins: [],
  props: {
    show: Boolean,
  },
  data: () => {
    return {
      loading: false,
      selectedCompany: null,
    }
  },
  computed: {
    company() {
      return Company.find(this.selectedCompany?.company_id)
    },
    companyDialog() {
      return {
        createTitle: 'Opprett ny bedrift',
        editTitle: 'Rediger bedrift',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'orgnr',
            type: 'text',
            label: 'Org.nr',
            rules: [this.$validationRules.required],
          },
          {
            name: 'contactperson',
            type: 'text',
            label: 'Kontaktperson',
            rules: [this.$validationRules.required],
          },
          {
            name: 'phonenumber',
            type: 'text',
            label: 'Telefon',
            rules: [this.$validationRules.required],
          },
          {
            name: 'emailaddress',
            type: 'text',
            label: 'Epostadresse',
            rules: [this.$validationRules.required],
          },
          {
            name: 'is_paying_customer',
            type: 'switch',
            label: 'Har inngått avtale',
            disabled: this.company?.is_active_customer,
            rules: [this.$validationRules.required],
          },
          {
            name: 'is_active_customer',
            type: 'switch',
            label: 'Aktivt kundeforhold',
            disabled: !this.company?.is_paying_customer,
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.selectedCompany = instance
      this.$parent.$successToast(`Du opprettet bedriften ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$parent.$successToast(`Endringen av bedriften ${instance.name} er lagret`)
    },
    handleError(message) {
      this.$refs.entityDialog.showErrorAlert(message)
      this.loading = false
    },
    showDialog(company) {
      this.selectedCompany = company
      this.$refs.entityDialog.showDialog(Company, this.company)
    },
    closeDialog() {
      this.$refs.leadDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
