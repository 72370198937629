import BaseModel from '@/models/BaseModel'
import Company from '@/models/Company'
import Conceptarea from '@/models/Conceptarea'
import LeadCompany from '@/models/LeadCompany'
import Leadrating from '@/models/Leadrating'
import Leadtype from '@/models/Leadtype'
import Listing from '@/models/Listing'
import Salesperson from '@/models/Salesperson'
import { splitDatetime } from '@/mixins/dateAndTime'

const CAMPAIGN_IDENTIFYING_STRINGS = ['utm_medium=cpc', 'utm_campaign']

export default class Lead extends BaseModel {
  static entity = 'leads'
  static primaryKey = 'lead_id'

  static fields() {
    return {
      lead_id: this.attr(null),
      conceptarea_id: this.attr(null),
      leadtype_id: this.number(9),
      leadrating_id: this.number(9),
      salesperson_id: this.number(),
      listing_id: this.attr(null),
      created_timestamp: this.attr({
        date: '',
        time: '',
      }),
      source_url: this.attr(null),
      content: this.attr({}),
      bait_assigned_timestamp: this.string().nullable(),
      bait_sent_to: this.string().nullable(),
      bait_sent_timestamp: this.string().nullable(),
      is_from_campaign: this.boolean(false),
      leadtype: this.hasOne(Leadtype, 'leadtype_id', 'leadtype_id'),
      leadrating: this.hasOne(Leadrating, 'leadrating_id', 'leadrating_id'),
      salesperson: this.hasOne(Salesperson, 'salesperson_id', 'salesperson_id'),
      listing: this.hasOne(Listing, 'listing_id', 'listing_id'),
      companies: this.belongsToMany(Company, LeadCompany, 'lead_id', 'company_id'),
      conceptarea: this.hasOne(Conceptarea, 'conceptarea_id', 'conceptarea_id'),
      leadCompanies: this.hasMany(LeadCompany, 'lead_id', 'lead_id'),
    }
  }

  static fetchUnassignedLeadsCount(concept_id, year) {
    if (!concept_id) return

    let url = `/leads/unassigned/count?concept_id=${concept_id}`

    if (year) {
      url = `${url}&year=${year}`
    }

    return this.api().get(url, { save: false })
  }

  static fetchBatch(ids, deleteAll = true, config = {}) {
    config.dataTransformer = dataTransformer
    return super.fetchBatch(ids, deleteAll, config)
  }

  static fetchPaginated(params, config = {}) {
    config.dataTransformer = dataTransformer
    return super.fetchPaginated(params, true, config)
  }

  static updateOne(id, data, config = {}) {
    config.dataTransformer = dataTransformer
    return super.updateOne(id, data, config)
  }

  static createOne(data, config = {}) {
    config.dataTransformer = dataTransformer
    return super.createOne(data, config)
  }

  static createOrUpdateOne(id, data, config = {}) {
    config.dataTransformer = dataTransformer
    return super.createOrUpdateOne(id, data, config)
  }
}

function dataTransformer(response) {
  let leads = response.data

  if (!Array.isArray(leads)) {
    leads = leads.data
  }

  leads = splitDatetimes(leads)
  leads = parseSourceURL(leads)
  leads = splitSourceUrl(leads)
  leads = parseJSON(leads)

  return leads
}

function splitDatetimes(leads) {
  return leads.map(lead => {
    lead.created_timestamp = splitDatetime(lead.created_timestamp)
    return lead
  })
}

function splitSourceUrl(leads) {
  const result = leads.map(lead => {
    if (lead.source_url) {
      lead.source_url = new URL(lead.source_url)
    } else {
      lead.source_url = {}
    }
    return lead
  })
  return result
}

function parseJSON(leads) {
  return leads.map(lead => {
    lead.content = JSON.parse(lead.content)
    return lead
  })
}

function parseSourceURL(leads) {
  return leads.map(lead => {
    lead.is_from_campaign = false

    const matchingStrings = CAMPAIGN_IDENTIFYING_STRINGS.filter(identifyingString =>
      lead.source_url?.includes(identifyingString)
    )

    if (matchingStrings.length > 0) {
      lead.is_from_campaign = true
    }

    return lead
  })
}
