import BaseModel from './BaseModel'

export default class Pausereason extends BaseModel {
  static entity = 'pausereasons'
  static primaryKey = 'pausereason_id'

  static fields() {
    return {
      pausereason_id: this.attr(null),
      name: this.string(),
    }
  }
}
