<template>
  <nav>
    <v-toolbar color="primary" dark>
      <v-toolbar-title class="headline">
        <span>leads</span>
        <span class="font-weight-light">united</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-if="$loggedIn">
        <v-toolbar-items>
          <v-btn :to="{ name: 'home' }" text exact>Hjem</v-btn>

          <v-menu close-on-content-click offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">Leads</v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  if ($route.name !== 'leads') {
                    $router.push({ name: 'leads' })
                  }
                "
              >
                <v-list-item-title>Alle leads</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'unassigned-leads-count') {
                    $router.push({ name: 'unassigned-leads-count' })
                  }
                "
              >
                <v-list-item-title>Antall ufordelte leads</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn :to="{ name: 'baits' }" text exact>Agn</v-btn>
          <v-btn :to="{ name: 'companies' }" text exact>Bedrifter</v-btn>
          <v-btn :to="{ name: 'listings' }" text exact>Bedriftsprofiler</v-btn>
          <v-btn :to="{ name: 'concepts' }" text exact>Konsepter</v-btn>

          <v-menu close-on-content-click offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">Administrasjon</v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  if ($route.name !== 'websites') {
                    $router.push({ name: 'websites' })
                  }
                "
              >
                <v-list-item-title>Nettsteder</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'areas') {
                    $router.push({ name: 'areas' })
                  }
                "
              >
                <v-list-item-title>Områder</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'users') {
                    $router.push({ name: 'users' })
                  }
                "
              >
                <v-list-item-title>Brukere</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'permissions') {
                    $router.push({ name: 'permissions' })
                  }
                "
              >
                <v-list-item-title>Tillatelser</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'leadratings') {
                    $router.push({ name: 'leadratings' })
                  }
                "
              >
                <v-list-item-title>Leadklasser</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'leadtypes') {
                    $router.push({ name: 'leadtypes' })
                  }
                "
              >
                <v-list-item-title>Lead-typer</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'keys') {
                    $router.push({ name: 'keys' })
                  }
                "
              >
                <v-list-item-title>API-nøkler</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'emailtemplates') {
                    $router.push({ name: 'emailtemplates' })
                  }
                "
              >
                <v-list-item-title>Eposttemplates</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  if ($route.name !== 'pausereasons') {
                    $router.push({ name: 'pausereasons' })
                  }
                "
              >
                <v-list-item-title>Pausegrunner</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <v-spacer />

        <v-toolbar-items>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="{ name: 'profile' }" v-on="on">
                <v-icon>person</v-icon>
              </v-btn>
            </template>
            <span>Din profil</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="logOut" v-on="on">
                <v-icon>exit_to_app</v-icon>
              </v-btn></template
            >
            <span>Logg ut</span>
          </v-tooltip>
        </v-toolbar-items>
      </template>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      loggedIn: localStorage.getItem('token') != undefined,
      showAdminDropdown: false,
    }
  },
  methods: {
    logOut() {
      localStorage.removeItem('token')
      localStorage.removeItem('email')
      this.$router.go()
    },
  },
}
</script>
