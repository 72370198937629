import BaseModel from './BaseModel'

export default class ApikeyPermission extends BaseModel {
  static entity = 'apikeys_permissions'

  static primaryKey = ['apikey_id', 'permission_id']

  static fields() {
    return {
      apikey_id: this.attr(null),
      permission_id: this.attr(null),
    }
  }
}
