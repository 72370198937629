<template>
  <BaseDialog
    v-if="leadCompany"
    ref="leadCompanyDialog"
    v-bind="baseDialog"
    @closeAndReset="closeDialog"
  >
    <template slot="form">
      <v-form ref="form" autocomplete="off">
        <template v-if="lead.listing == null">
          <v-switch
            v-model="isFreebie"
            label="Fordel til prospekt"
            :disabled="loading || hasError || leadCompany.$id != null"
          />
          <v-switch
            v-model="assignToAnyCompany"
            label="Fordel til bedrift utenfor konseptområdet"
            :disabled="loading || hasError || leadCompany.$id != null"
          />
          <v-select
            v-if="!isFreebie && !assignToAnyCompany"
            v-model="leadCompany.company_id"
            :items="customers"
            label="Fordel til kunde"
            item-text="name"
            item-value="company_id"
            :hint="
              !customers.length ? 'Det finnes ingen passende kunder å fordele dette leadet til' : ''
            "
            :persistent-hint="!customers.length"
            :rules="[$validationRules.required]"
            :disabled="loading || hasError || leadCompany.$id != null || !customers.length"
            @change="handleCompanyField"
          />
          <v-select
            v-if="isFreebie && !assignToAnyCompany"
            v-model="leadCompany.company_id"
            :items="prospects"
            label="Fordel til prospekt"
            item-text="name"
            item-value="company_id"
            :hint="
              !prospects.length
                ? 'Det finnes ingen passende prospekts å fordele dette leadet til'
                : ''
            "
            :persistent-hint="!prospects.length"
            :rules="[$validationRules.required]"
            :disabled="loading || hasError || leadCompany.$id != null || !prospects.length"
            @change="handleCompanyField"
          />
          <LookupAutocomplete
            v-if="!isFreebie && assignToAnyCompany"
            v-model="leadCompany.company_id"
            v-bind="customerLookupAutocomplete"
            @change="handleCompanyField"
          />
          <LookupAutocomplete
            v-if="isFreebie && assignToAnyCompany"
            v-model="leadCompany.company_id"
            v-bind="prospectLookupAutocomplete"
            @change="handleCompanyField"
          />
        </template>
        <v-select
          v-else
          v-model="leadCompany.company_id"
          :items="[lead.listing.company]"
          label="Send til kunde"
          item-text="name"
          item-value="company_id"
          :rules="[$validationRules.required]"
          :disabled="loading || hasError || leadCompany.$id != null"
          @change="handleCompanyField"
        />
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import responseHelper from '@/mixins/responseHelper'
import LookupAutocomplete from '@/components/controls/LookupAutocomplete'
import BaseDialog from '@/components/dialogs/BaseDialog'
import Conceptarea from '@/models/Conceptarea'
import ConceptareaCompany from '@/models/ConceptareaCompany'
import Company from '@/models/Company'
import Lead from '@/models/Lead'
import LeadCompany from '@/models/LeadCompany'

export default {
  name: 'LeadCompanyDialog',
  components: {
    BaseDialog,
    LookupAutocomplete,
  },
  mixins: [entityPersistence, responseHelper],
  props: {
    show: Boolean,
    leadCompany: {
      type: LeadCompany,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      hasError: false,
      isFreebie: false,
      assignToAnyCompany: false,
      customers: [],
      prospects: [],
    }
  },
  computed: {
    baseDialog() {
      return {
        show: this.show,
        entity: this.leadCompany,
        editTitle: 'Rediger lead-fordeling',
        createTitle: 'Fordel lead til bedrift',
        maxWidth: 500,
        loading: this.loading,
      }
    },
    lead() {
      if (!this.leadCompany) return

      return Lead.query()
        .whereId(this.leadCompany.lead_id)
        .with('conceptarea')
        .with('companies')
        .with('listing')
        .with('listing.company')
        .first()
    },
    existingLeadCompanies() {
      return this.lead?.companies?.map(leadCompany => leadCompany.company_id)
    },
    customerLookupAutocomplete() {
      return {
        label: 'Søk etter kunde',
        itemText: 'name',
        itemValue: 'company_id',
        model: Company,
        filters: [
          item =>
            item.is_active_customer == 1 &&
            item.is_paying_customer == 1 &&
            item.is_paused == 0 &&
            !this.existingLeadCompanies.includes(item.company_id),
        ],
        rules: [this.$validationRules.required],
        disabled: this.loading || this.hasError || this.leadCompany.$id != null,
      }
    },
    prospectLookupAutocomplete() {
      return {
        label: 'Søk etter prospekt',
        itemText: 'name',
        itemValue: 'company_id',
        model: Company,
        filters: [
          item =>
            item.is_active_customer == 0 &&
            item.is_paying_customer == 0 &&
            item.is_paused == 0 &&
            !this.existingLeadCompanies.includes(item.company_id),
        ],
        rules: [this.$validationRules.required],
        disabled: this.loading || this.hasError || this.leadCompany.$id != null,
      }
    },
  },
  watch: {
    'lead.conceptarea.conceptarea_id': async function(conceptareaId) {
      const DELETE_PREVIOUSLY_FETCHED_COMPANIES = false
      const DELETE_PREVIOUSLY_FETCHED_CONCEPTAREACOMPANIES = false

      this.customers = []
      this.prospects = []

      if (!conceptareaId) return

      this.loading = true

      let ancestors
      try {
        ancestors = await Conceptarea.fetchAncestors(conceptareaId)
      } catch (err) {
        this.hasError = true
        this.handleError([
          'Det skjedde en feil ved søk etter forfedrene til konseptområdet.',
          err.message,
        ])
        return
      }

      const ancestorIds = this.getIdsFromEntities(ancestors, 'conceptarea_id') ?? []
      const allConceptareaIds = [conceptareaId, ...ancestorIds]

      let conceptareaCompanies

      try {
        conceptareaCompanies = await ConceptareaCompany.fetchBatchByKey(
          'conceptarea_id',
          allConceptareaIds,
          DELETE_PREVIOUSLY_FETCHED_CONCEPTAREACOMPANIES
        )
      } catch (err) {
        this.hasError = true
        this.handleError([
          'Det skjedde en feil ved søk etter bedriftene i konseptområdet.',
          err.message,
        ])
        return
      }

      const companyIds = this.getIdsFromEntities(conceptareaCompanies, 'company_id')

      if (!companyIds) {
        this.loading = false
        return
      }

      try {
        await Company.fetchBatch(companyIds, DELETE_PREVIOUSLY_FETCHED_COMPANIES)
      } catch (err) {
        this.hasError = true
        this.handleError([
          'Det skjedde en feil ved innlasting av bedriftene i konseptområdet.',
          err.message,
        ])
        return
      }

      this.customers = Company.query()
        .whereIdIn(companyIds)
        .whereHasNot('leads', query => {
          return query.where('lead_id', this.leadCompany.lead_id)
        })
        .where('is_paying_customer', true)
        .where('is_active_customer', true)
        .where('is_paused', false)
        .get()

      this.prospects = Company.query()
        .whereIdIn(companyIds)
        .where('is_paying_customer', false)
        .where('is_active_customer', false)
        .where('is_paused', false)
        .get()

      this.loading = false
    },
  },
  methods: {
    async handleCompanyField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.leadCompanyDialog.clearErrorAlert()

      if (this.leadCompany.$id) {
        await this.createOrUpdateEntity(
          LeadCompany,
          { company_id: this.leadCompany.company_id },
          this.leadCompany.$id
        )
      } else {
        await this.createEntity()
      }
    },

    async createEntity() {
      this.$refs.leadCompanyDialog.clearErrorAlert()

      await this.createOrUpdateEntity(LeadCompany, {
        lead_id: this.leadCompany.lead_id,
        company_id: this.leadCompany.company_id,
      })
    },

    handleError(message) {
      this.$refs.leadCompanyDialog.showErrorAlert(message)
      this.loading = false
    },

    closeDialog() {
      this.hasError = false
      this.isFreebie = false
      this.assignToAnyCompany = false
      this.$refs.leadCompanyDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
