import BaseModel from '@/models/BaseModel'

export default class Leadtype extends BaseModel {
  static entity = 'leadtypes'
  static primaryKey = 'leadtype_id'

  static fields() {
    return {
      leadtype_id: this.attr(null),
      name: this.string(),
      color: this.string('#2196F3'),
      icon: this.string(),
    }
  }
}
