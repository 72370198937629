<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Selgere</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createSalespersonButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item
              v-for="salesperson in salespersons"
              :key="salesperson.salesperson_id"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ salesperson.first_name }} {{ salesperson.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <a :href="`mailto:${salesperson.email}`">{{ salesperson.email }}</a> •
                  <a :href="`tel:${salesperson.phone}`">{{ salesperson.phone | phone }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateSalespersonButton"
                @click="$refs.entityDialog.showDialog(model, salesperson)"
              />

              <TooltipButton
                v-bind="deleteSalespersonButton"
                @click="
                  getDeleteConfirmation(
                    salesperson,
                    `Bekreft sletting av selgeren ${salesperson.first_name} ${salesperson.last_name}`,
                    `Vil du virkelig slette selgeren ${salesperson.first_name} ${salesperson.last_name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet selgeren ${deleteConfirmationDialog.entityToDelete.first_name} ${deleteConfirmationDialog.entityToDelete.last_name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/TooltipButton'
import Salesperson from '@/models/Salesperson'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data() {
    return {
      loading: false,
      model: Salesperson,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny selger',
        editTitle: 'Rediger selger',
        fields: [
          {
            name: 'first_name',
            type: 'text',
            label: 'Fornavn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'last_name',
            type: 'text',
            label: 'Etternavn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'phone',
            type: 'text',
            label: 'Telefon',
            rules: [this.$validationRules.required],
          },
          {
            name: 'email',
            type: 'text',
            label: 'E-post',
            rules: [this.$validationRules.required, this.$validationRules.email],
          },
        ],
      }
    },
    createSalespersonButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('SALESPERSON_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('SALESPERSON_CREATE')
            ? 'Opprett selger'
            : 'Du mangler tillatelse til å opprette selgere',
        },
      }
    },
    updateSalespersonButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('SALESPERSON_UPDATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('SALESPERSON_UPDATE')
            ? 'Rediger selger'
            : 'Du mangler tillatelse til å redigere selgere',
        },
      }
    },
    deleteSalespersonButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('SALESPERSON_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('SALESPERSON_DELETE')
            ? 'Slett selger'
            : 'Du mangler tillatelse til å slette selgere',
        },
      }
    },
    salespersons() {
      let salespersons = Salesperson.query()
        .orderBy('first_name')
        .get()

      return salespersons
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Du opprettet selgeren ${instance.first_name} ${instance.last_name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(
        `Endringen av selgeren ${instance.first_name} ${instance.last_name} er lagret`
      )
    },
  },
}
</script>
