<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" xl="8" offset-xl="2">
        <LeadsIterator :iterator-options="leadsIterator" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import leadsIterator from '@/mixins/leadsIterator'
import responseHelper from '@/mixins/responseHelper'
import LeadsIterator from '@/components/LeadsIterator'

export default {
  components: {
    LeadsIterator,
  },
  mixins: [deleteConfirmationDialog, leadsIterator, responseHelper],
  data: () => {
    return {
      search: {},
    }
  },
  computed: {},
  methods: {},
}
</script>
