<template>
  <ConfirmationDialog
    ref="confirmationDialog"
    v-bind="confirmationDialog"
    @accepted="deleteEntity(entityToDelete)"
    @declined="cancelDeletingEntity"
  ></ConfirmationDialog>
</template>

<script>
import confirmationDialog from '@/mixins/dialogs/confirmationDialog'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'

export default {
  name: 'DeleteConfirmationDialog',
  components: { ConfirmationDialog },
  mixins: [confirmationDialog],
  props: {
    show: Boolean,
    title: {
      type: String,
      default: 'Bekreft sletting',
    },
    text: {
      type: String,
      default: 'Ønsker du virkelig å slette dette objektet?',
    },
    entityToDelete: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async deleteEntity() {
      const model = this.entityToDelete.constructor
      let id

      if (Array.isArray(model.primaryKey)) {
        id = {}
        model.primaryKey.forEach(key => {
          id[key] = this.entityToDelete[key]
        })
      } else {
        id = this.entityToDelete[model.primaryKey]
      }

      this.confirmationDialog.loading = true
      try {
        this.$refs.confirmationDialog.clearErrorAlert()
        await model.deleteOne(id)
        this.$emit('success')
      } catch (e) {
        this.$refs.confirmationDialog.showErrorAlert(`Kunne ikke slette: ${e.message}`)
        this.confirmationDialog.loading = false
      }
      this.confirmationDialog.loading = false
    },
    cancelDeletingEntity() {
      this.$refs.confirmationDialog.clearErrorAlert()
      this.$emit('cancel')
    },
  },
}
</script>
