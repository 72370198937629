var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-iterator',_vm._b({attrs:{"items":_vm.leads,"item-key":"lead_id","loading":_vm.loading,"no-data-text":"Fant ingen leads"},on:{"update:page":_vm.fetchData,"update:items-per-page":_vm.fetchData,"hook:beforeMount":_vm.fetchData},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"144"}})]},proxy:true},{key:"header",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-row',{staticClass:"align-end"},[_c('v-col',[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.conceptarea)?_c('TooltipButton',_vm._b({on:{"click":function($event){_vm.showLeadDialog({
                  leadtype_id: _vm.DEFAULT_LEADTYPE_ID,
                  leadrating_id: _vm.DEFAULT_LEADRATING_ID,
                  conceptarea_id: _vm.conceptarea.conceptarea_id,
                  conceptarea: _vm.conceptarea,
                  source_url: {},
                  created_timestamp: {
                    date: _vm.getCurrentDate(),
                    time: _vm.getCurrentTime(),
                  },
                  content: {},
                })}}},'TooltipButton',_vm.createLeadButton,false)):_vm._e()],1)]),_c('v-col',[(!_vm.loading && _vm.leads.length)?_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":_vm.iteratorOptions.footerProps['items-per-page-text'],"items-per-page-options":_vm.iteratorOptions.footerProps['items-per-page-options']},on:{"update:options":updateOptions}}):_vm._e()],1)],1)]}},{key:"default",fn:function(ref){
                var items = ref.items;
                var isExpanded = ref.isExpanded;
                var expand = ref.expand;
return [(_vm.loading)?_c('v-progress-circular',{staticClass:"d-block ml-auto mr-auto mt-8 mb-8",attrs:{"indeterminate":"","color":"white","size":"144"}}):_vm._l((items),function(lead){return _c('v-card',{key:lead.lead_id,staticClass:"mb-12"},[_c('v-card-title',{staticClass:"black--text",class:_vm.getLeadAssignableClass(lead)},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(lead.conceptarea)?_c('router-link',{attrs:{"to":{
                  name: 'conceptarea',
                  params: { conceptarea_id: lead.conceptarea_id },
                }}},[_vm._v(_vm._s(lead.conceptarea.concept.name)+" "+_vm._s(lead.conceptarea.area.name))]):_vm._e(),(lead.listing)?_c('router-link',{attrs:{"to":{
                  name: 'company',
                  params: { company_id: lead.listing.company_id },
                }}},[_vm._v("Bedriftsprofil: "+_vm._s(lead.listing.company.name))]):_vm._e(),(lead.is_from_campaign)?_c('v-chip',{staticClass:"mx-1 text-uppercase campaign-label",attrs:{"small":"","color":"primary"}},[_vm._v("Kampanje")]):_vm._e(),(
                  lead.is_from_campaign &&
                    !lead.conceptarea.companies_count &&
                    !lead.leadCompanies.length
                )?_c('v-avatar',{staticClass:"darken-4 white--text",attrs:{"color":"error","size":"24"},on:{"click":function($event){_vm.showEmailDialog(_vm.generateCampaignWarningEmail(lead))}}},[_vm._v("!")]):_vm._e(),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(lead.created_timestamp.date,'DD.MM.YYYY'))+" "+_vm._s(_vm._f("moment")(lead.created_timestamp.time,'HH:mm', 'hh:mm:ss'))+" ")])],1),_c('v-col',{staticClass:"d-flex align-center text-caption text-right",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"ml-auto"},[(lead.salesperson)?_c('div',[_vm._v(" Gitt som agn til "+_vm._s(lead.salesperson.full_name)+" "+_vm._s(_vm._f("moment")(lead.bait_assigned_timestamp,'DD.MM.YYYY', 'YYYY-MM-DD hh:mm:ss'))+" ")]):_vm._e(),(lead.bait_sent_to)?_c('div',[_vm._v(" Agn sendt til "+_vm._s(lead.bait_sent_to)+" "+_vm._s(_vm._f("moment")(lead.bait_sent_timestamp,'DD.MM.YYYY', 'YYYY-MM-DD hh:mm:ss'))+" ")]):_vm._e(),(lead.leadCompanies.length)?_c('div',[(lead.leadCompanies.length == 1)?_c('span',[_vm._v(" Fordelt til "),_c('router-link',{attrs:{"to":{
                        name: 'company',
                        params: { company_id: lead.leadCompanies[0].company.company_id },
                      }}},[_vm._v(_vm._s(lead.leadCompanies[0].company.name))])],1):_vm._e(),(
                      lead.leadCompanies.filter(function (leadCompany) {
                        return !(leadCompany.email && leadCompany.email.sent_timestamp != null)
                      }).length > 0
                    )?_c('v-avatar',{staticClass:"ml-1 darken-3 white--text",attrs:{"color":"error","size":"28"},on:{"click":function($event){expand(lead, !isExpanded(lead))}}},[_vm._v(" ! ")]):_vm._e(),(lead.leadCompanies.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-caption mr-1 cursorDefault"},on),[_vm._v(" Fordelt til "),_c('router-link',{attrs:{"to":{
                            name: 'company',
                            params: { company_id: lead.leadCompanies[0].company.company_id },
                          }}},[_vm._v(_vm._s(lead.leadCompanies[0].company.name)+" ")]),_vm._v(" og "+_vm._s(lead.leadCompanies.length - 1)+" til ")],1)]}}],null,true)},_vm._l((lead.leadCompanies),function(leadCompany){return _c('div',{key:leadCompany.company_id},[_vm._v(" "+_vm._s(leadCompany.company.name)+" ")])}),0):_vm._e()],1):_vm._e()]),_c('div',[(!lead.listing)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"black","icon":"","disabled":!_vm.$currentUser.hasPermission('LEAD_UPDATE') ||
                            lead.leadCompanies.length > 0},on:{"click":function($event){return _vm.showBaitDialog(lead)}}},[_c('v-icon',[_vm._v("phishing")])],1)],1)]}}],null,true)},[(!_vm.$currentUser.hasPermission('LEAD_UPDATE'))?[_vm._v(" Du mangler tillatelse til å tildele agn ")]:(lead.leadCompanies.length > 0)?[_vm._v(" Leads som allerede er fordelt til en bedrift kan ikke brukes som agn ")]:[_vm._v("Tildel agn til selger")]],2):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"black","icon":"","disabled":!_vm.$currentUser.hasPermission('LEAD_COMPANY_CREATE') ||
                            (lead.conceptarea &&
                              lead.leadCompanies.length >=
                                lead.conceptarea.max_companies_per_lead)},on:{"click":function($event){return _vm.showLeadCompanyDialog({ lead_id: lead.lead_id })}}},[_c('v-icon',[_vm._v("add_business")])],1)],1)]}}],null,true)},[(!_vm.$currentUser.hasPermission('LEAD_COMPANY_CREATE'))?[_vm._v(" Du mangler tillatelse til å fordele leads til bedrifter ")]:(
                      lead.conceptarea &&
                        lead.leadCompanies.length >=
                          (lead.conceptarea.max_companies_per_lead
                            ? lead.conceptarea.max_companies_per_lead
                            : Infinity)
                    )?[_vm._v(" Leads i dette konseptområdet kan fordeles til maksimalt "+_vm._s(lead.conceptarea.max_companies_per_lead)+" bedrifter ")]:[_vm._v("Fordel til bedrift")]],2)],1)])],1)],1),_c('v-card-text',{staticClass:"mt-5",class:("" + (_vm.$currentUser.pref_dark_mode ? 'grey--text text--lighten-3' : ''))},[(lead.source_url && lead.source_url.href)?_c('v-row',[_c('v-col',{staticClass:"pt-0",class:{
                'pb-0': _vm.$vuetify.breakpoint.xs,
                'pb-1': _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"cols":"12","sm":"4","md":"3"}},[_c('strong',[_vm._v("Kilde: ")])]),_c('v-col',{staticClass:"pt-0",class:{
                'pb-3': _vm.$vuetify.breakpoint.xs,
                'pb-1': _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"cols":"12","sm":"8","md":"9"}},[_c('span',{staticStyle:{"opacity":".35"}},[_vm._v(" "+_vm._s(lead.source_url.protocol)+"//")]),_vm._v(_vm._s(lead.source_url.hostname)),_c('span',{staticStyle:{"opacity":".35"}},[_vm._v(_vm._s(lead.source_url.pathname)+_vm._s(lead.source_url.search))])])],1):_vm._e(),_vm._l((Object.entries(lead.content)),function(ref){
              var key = ref[0];
              var value = ref[1];
return _c('v-row',{key:key},[_c('v-col',{staticClass:"pt-0",class:{
                'pb-0': _vm.$vuetify.breakpoint.xs,
                'pb-1': _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"cols":"12","sm":"4","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(key)))])]),_c('v-col',{staticClass:"pt-0",class:{
                'pb-3': _vm.$vuetify.breakpoint.xs,
                'pb-1': _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"cols":"12","sm":"8","md":"9"}},[(isExpanded(lead))?[_c('span',{staticClass:"pre-formatted"},[_vm._v(_vm._s(value.toString()))])]:[_vm._v(" "+_vm._s(_vm._f("truncateString")(value,150, true))+" ")]],2)],1)}),(isExpanded(lead))?_c('v-btn',{attrs:{"fab":"","absolute":"","bottom":"","right":"","small":"","color":"grey darken-3"},on:{"click":function($event){return expand(lead, false)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("expand_less")])],1):_c('v-btn',{attrs:{"fab":"","absolute":"","bottom":"","right":"","small":"","color":"grey darken-3"},on:{"click":function($event){return expand(lead, true)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("expand_more")])],1)],2),(isExpanded(lead) && lead.leadCompanies.length)?_c('v-card-text',{class:("" + (_vm.$currentUser.pref_dark_mode
                ? 'blue-grey darken-4 grey--text text--lighten-3'
                : 'grey lighten-3'))},_vm._l((lead.leadCompanies),function(leadCompany){return _c('v-row',{key:leadCompany.company_id,staticClass:"align-center"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"5","md":"3","lg":"4","xl":"3"}},[(leadCompany.email && leadCompany.email.sent_timestamp)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.showEmailDialog(leadCompany.email)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mark_email_read")]),_vm._v("Sendt "+_vm._s(_vm._f("moment")(leadCompany.email.sent_timestamp,'DD.MM.YYYY'))+" ")],1):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.closeLeadCompanyDialogAndShowEmailDialog(leadCompany)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("email")]),_vm._v("Ikke sendt til kunde ")],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"7","md":"9","lg":"8","xl":"9"}},[_vm._v(" "+_vm._s(leadCompany.company.name)+" "),_c('TooltipButton',{attrs:{"button":{
                  disabled:
                    (leadCompany.email && leadCompany.email.sent_timestamp != null) ||
                    !_vm.$currentUser.hasPermission('LEAD_COMPANY_DELETE') ||
                    _vm.loading,
                  icon: 'delete_forever',
                  color: 'error',
                },"tooltip":{
                  openDelay: 500,
                  bottom: true,
                  text: !_vm.$currentUser.hasPermission('LEAD_COMPANY_DELETE')
                    ? 'Du mangler tillatelse til å slette fordelinger'
                    : leadCompany.email && leadCompany.email.sent_timestamp != null
                    ? 'Du kan ikke slette en fordeling når leadet allerede er sendt til kunde'
                    : 'Slett fordeling',
                }},on:{"click":function($event){return _vm.getDeleteConfirmation(
                    leadCompany,
                    "Bekreft sletting av fordeling",
                    ("Vil du virkelig slette fordelingen til " + (leadCompany.company.name) + "?")
                  )}}})],1)],1)}),1):_vm._e(),(isExpanded(lead))?_c('v-card-actions',{class:("justify-center primary " + (lead.leadCompanies.length > 0 || !_vm.$currentUser.hasPermission('LEAD_DELETE')
                ? ''
                : 'darken-2'))},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","color":"white","disabled":_vm.loading ||
                      lead.leadCompanies.length > 0 ||
                      !_vm.$currentUser.hasPermission('LEAD_UPDATE')},on:{"click":function($event){return _vm.showLeadDialog(lead)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}],null,true)},[(!_vm.$currentUser.hasPermission('LEAD_UPDATE'))?[_vm._v(" Du mangler tillatelse til å redigere leads ")]:(lead.leadCompanies.length > 0)?[_vm._v(" Du kan ikke redigere et lead som allerede er fordelt til en bedrift ")]:[_vm._v("Rediger lead")]],2),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loading ||
                      lead.leadCompanies.length > 0 ||
                      !_vm.$currentUser.hasPermission('LEAD_DELETE')},on:{"click":function($event){return _vm.getDeleteConfirmation(
                      lead,
                      'Bekreft sletting av lead',
                      'Vil du virkelig slette dette leadet for alltid?'
                    )}}},[_c('v-icon',[_vm._v("delete_forever")])],1)],1)]}}],null,true)},[(!_vm.$currentUser.hasPermission('LEAD_DELETE'))?[_vm._v(" Du mangler tillatelse til å slette leads ")]:(lead.leadCompanies.length > 0)?[_vm._v(" Du kan ikke slette et lead som allerede er fordelt til en bedrift ")]:[_vm._v("Slett lead")]],2)],1):_vm._e()],1)})]}}])},'v-data-iterator',_vm.iteratorOptions,false,true)),_c('LeadDialog',_vm._b({on:{"closeAndReset":_vm.closeAndResetLeadDialog,"entityCreated":_vm.setNewLead,"entityUpdated":_vm.setNewLead}},'LeadDialog',_vm.leadDialog,false)),_c('LeadCompanyDialog',_vm._b({on:{"closeAndReset":_vm.closeAndResetLeadCompanyDialog,"entityCreated":_vm.closeLeadCompanyDialogAndShowEmailDialog}},'LeadCompanyDialog',_vm.leadCompanyDialog,false)),_c('BaitDialog',_vm._b({on:{"closeAndReset":_vm.closeAndResetBaitDialog,"entityUpdated":_vm.fetchData}},'BaitDialog',_vm.baitDialog,false)),_c('EmailDialog',_vm._b({ref:"emailDialog",on:{"entityCreated":_vm.setNewEmailAndUpdateLeadCompany,"closeAndReset":_vm.closeAndResetEmailDialog,"mailSent":_vm.closeAndResetEmailDialog}},'EmailDialog',_vm.emailDialog,false)),_c('DeleteConfirmationDialog',_vm._b({ref:"deleteConfirmationDialog",on:{"cancel":_vm.closeAndResetDeleteConfirmationDialog,"success":function($event){return _vm.closeAndResetDeleteConfirmationDialog("Sletting var vellykket")}}},'DeleteConfirmationDialog',_vm.deleteConfirmationDialog,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }