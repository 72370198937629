export default {
  computed: {
    createWebsiteButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('WEBSITE_CREATE')) ||
            this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('WEBSITE_CREATE')
              ? 'Opprett nettsted'
              : 'Du mangler tillatelse til å opprette nettsteder',
        },
      }
    },
    updateWebsiteButton() {
      return {
        button: {
          icon: 'edit',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('WEBSITE_UPDATE')) ||
            this.loading,
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('WEBSITE_UPDATE')
              ? 'Rediger nettsted'
              : 'Du mangler tillatelse til å redigere nettsteder',
        },
      }
    },
  },
}
