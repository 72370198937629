import Email from '@/models/Email'

export default {
  data: () => {
    return {
      emailDialog: {
        show: false,
        email: null,
      },
    }
  },
  methods: {
    showEmailDialog(email = {}) {
      if (typeof email != Email) {
        email = new Email(email)
      }
      this.emailDialog.email = email
      this.emailDialog.show = true
    },
    closeAndResetEmailDialog() {
      this.emailDialog.show = false
      this.emailDialog.email = null
    },
    setNewEmail(email) {
      this.emailDialog.email = email
    },
  },
}
