import BaseModel from '@/models/BaseModel'
import Conceptarea from '@/models/Conceptarea'

export default class Concept extends BaseModel {
  static entity = 'concepts'
  static primaryKey = 'concept_id'

  static fields() {
    return {
      concept_id: this.attr(null),
      parent_id: this.attr(null).nullable(),
      name: this.string(),
      default_lead_price: this.number(null),
      is_umbrella: this.boolean(false),
      parent: this.belongsTo(Concept, 'parent_id', 'concept_id'),
      conceptareas: this.hasMany(Conceptarea, 'concept_id', 'concept_id'),
    }
  }
}
