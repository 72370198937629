<template>
  <BaseDialog v-if="lead" ref="leadDialog" v-bind="baseDialog" @closeAndReset="closeDialog">
    <template slot="form">
      <v-form ref="form" autocomplete="off">
        <v-row class="mt-2">
          <v-col class="grey darken-4">
            <v-autocomplete
              v-model="lead.conceptarea.concept"
              label="Konsept"
              :items="concepts"
              item-text="name"
              item-value="concept_id"
              return-object
              no-data-text="Ingen treff"
              :disabled="loading"
              :rules="[$validationRules.required]"
              @change="handleConceptareaFields"
            />
            <v-autocomplete
              v-model="lead.conceptarea.area"
              label="Område"
              :items="areas"
              item-text="name"
              item-value="area_id"
              return-object
              no-data-text="Ingen treff"
              :disabled="loading"
              :rules="[$validationRules.required]"
              @change="handleConceptareaFields"
            />
            <v-menu
              v-model="showDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="lead.created_timestamp.date"
                  label="Mottatt"
                  type="date"
                  readonly
                  :rules="[$validationRules.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="lead.created_timestamp.date"
                color="success"
                :disabled="loading"
                @change="handleDatetimeField"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="showTimePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="lead.created_timestamp.time"
                  label="Klokken"
                  type="time"
                  readonly
                  :rules="[$validationRules.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="lead.created_timestamp.time"
                color="success"
                format="24hr"
                :disabled="loading"
                @change="handleDatetimeField"
              ></v-time-picker>
            </v-menu>
            <v-text-field
              v-model="lead.source_url.href"
              label="Kilde"
              :disabled="loading"
              clearable
              :rules="[$validationRules.url]"
              @change="handleSourceUrlField"
            />
            <v-select
              v-model="lead.leadtype_id"
              label="Type"
              :items="leadtypes"
              item-text="name"
              item-value="leadtype_id"
              :rules="[$validationRules.required]"
              disabled
              @change="handleLeadtypeField"
            />

            <v-select
              v-model="lead.leadrating_id"
              label="Klasse"
              :items="leadratings"
              item-text="name"
              item-value="leadrating_id"
              disabled
              @change="handleLeadratingField"
            />
          </v-col>

          <v-col cols="7">
            <h3 class="mb-3">
              Innhold:
              <TooltipButton v-bind="addContentFieldButton" @click="showContentFieldAdder = true" />
            </h3>
            <v-textarea
              v-for="field in Object.keys(lead.content)"
              :key="field.key"
              v-model="lead.content[field]"
              rows="1"
              auto-grow
              :disabled="loading"
              :label="$options.filters.capitalizeFirstLetter(field)"
              @blur="handleContentField"
            >
              <template slot="append-outer">
                <TooltipButton
                  v-if="!showDeleteContentFieldConfirmation"
                  v-bind="deleteContentFieldButton"
                  @click="showDeleteContentFieldConfirmation = true"
                />
                <template v-if="showDeleteContentFieldConfirmation">
                  <a
                    class="error--text ml-a mr-5 mt-3 white-space-nowrap"
                    @click="deleteContentField(field)"
                  >
                    Bekreft sletting
                  </a>
                  <a class="mt-3" @click="showDeleteContentFieldConfirmation = false">Avbryt</a>
                </template>
              </template>
            </v-textarea>
            <v-text-field
              v-show="showContentFieldAdder"
              v-model="newContentField"
              label="Feltnavn"
              @change="addContentField"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import dateAndTime from '@/mixins/dateAndTime.js'
import entityPersistence from '@/mixins/entityPersistence.js'
import responseHelper from '@/mixins/responseHelper'
import BaseDialog from '@/components/dialogs/BaseDialog'
import TooltipButton from '@/components/TooltipButton'
import Area from '@/models/Area'
import Concept from '@/models/Concept'
import Conceptarea from '@/models/Conceptarea'
import Lead from '@/models/Lead'
import Leadrating from '@/models/Leadrating'
import Leadtype from '@/models/Leadtype'

export default {
  name: 'LeadDialog',
  components: {
    BaseDialog,
    TooltipButton,
  },
  mixins: [entityPersistence, dateAndTime, responseHelper],
  props: {
    show: Boolean,
    lead: {
      type: Lead,
      default: null,
    },
  },
  data: () => {
    return {
      showDatePicker: false,
      showTimePicker: false,
      loading: false,
      showContentFieldAdder: false,
      newContentField: null,
      showDeleteContentFieldConfirmation: false,
    }
  },
  computed: {
    baseDialog() {
      return {
        show: this.show,
        entity: this.lead,
        editTitle: 'Rediger lead',
        createTitle: 'Opprett nytt lead',
        maxWidth: 1080,
        loading: this.loading,
      }
    },
    areas() {
      return Area.all()
    },
    concepts() {
      return Concept.all()
    },
    leadtypes() {
      return Leadtype.all()
    },
    leadratings() {
      return Leadrating.all()
    },
    addContentFieldButton() {
      return {
        button: {
          icon: 'add',
          disabled: this.showContentFieldAdder || this.loading,
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: 'Legg til felt',
        },
      }
    },
    deleteContentFieldButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: 'Slett dette feltet for alltid',
        },
      }
    },
  },
  methods: {
    async deleteContentField(field) {
      this.showDeleteContentFieldConfirmation = false

      delete this.lead.content[field]

      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(
          Lead,
          { content: JSON.stringify(this.lead.content) },
          this.lead.lead_id
        )
      } else {
        await this.createEntity()
      }
    },
    addContentField() {
      if (this.lead.content[this.newContentField] !== undefined) return
      if (!this.newContentField) return

      this.lead.content[this.newContentField] = ''

      this.showContentFieldAdder = false
      this.newContentField = null
    },
    async handleConceptareaFields() {
      if (
        !this.show ||
        !this.$refs.form.validate() ||
        !this.lead.conceptarea.concept.concept_id ||
        !this.lead.conceptarea.area.area_id
      ) {
        return
      }

      this.$refs.leadDialog.clearErrorAlert()

      const response = await Conceptarea.fetchAll({
        search: {
          concept_id: this.lead.conceptarea.concept.concept_id,
          area_id: this.lead.conceptarea.area.area_id,
        },
      })

      if (response.entities.conceptareas.length != 1) {
        this.handleError(
          `Fant ikke konseptområdet ${this.lead.conceptarea.concept.name} ${this.lead.conceptarea.area.name}`
        )
      }

      const conceptarea_id = this.getIdsFromEntities(response, 'conceptarea_id')[0]

      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(Lead, { conceptarea_id: conceptarea_id }, this.lead.lead_id)
      } else {
        await this.createEntity()
      }
    },
    async handleContentField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.leadDialog.clearErrorAlert()
      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(
          Lead,
          { content: JSON.stringify(this.lead.content) },
          this.lead.lead_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleSourceUrlField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.leadDialog.clearErrorAlert()
      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(
          Lead,
          { source_url: this.lead.source_url.href },
          this.lead.lead_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleLeadtypeField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.leadDialog.clearErrorAlert()
      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(
          Lead,
          { leadtype_id: this.lead.leadtype_id },
          this.lead.lead_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleLeadratingField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.leadDialog.clearErrorAlert()
      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(
          Lead,
          { leadrating_id: this.lead.leadrating_id },
          this.lead.lead_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleDatetimeField() {
      this.showDatePicker = false
      this.showTimePicker = false

      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.leadDialog.clearErrorAlert()

      if (this.lead.lead_id) {
        await this.createOrUpdateEntity(
          Lead,
          {
            created_timestamp: this.concatDateAndTime(this.lead.created_timestamp),
          },
          this.lead.lead_id
        )
      } else {
        await this.createEntity()
      }
    },

    async createEntity() {
      this.$refs.leadDialog.clearErrorAlert()
      await this.createOrUpdateEntity(Lead, {
        conceptarea_id: this.lead.conceptarea_id,
        leadtype_id: this.lead.leadtype_id,
        leadrating_id: this.lead.leadrating_id,
        source_url: this.lead.source_url.href,
        created_timestamp: this.concatDateAndTime(this.lead.created_timestamp),
        content: JSON.stringify(this.lead.content),
      })
    },

    handleError(message) {
      this.$refs.leadDialog.showErrorAlert(message)
      this.loading = false
    },

    closeDialog() {
      this.$refs.leadDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
