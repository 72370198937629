<template>
  <v-container class="pa-16">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="pa-4">
          <v-card-title>Innlogging</v-card-title>
          <v-form ref="form" @submit.prevent="handleLogin">
            <v-text-field
              v-model="email"
              type="email"
              label="Epost"
              :rules="[v => !!v || 'Påkrevd']"
              required
              outlined
            />
            <v-text-field
              v-model="password"
              type="password"
              label="Passord"
              :rules="[v => !!v || 'Påkrevd']"
              required
              outlined
            />
            <v-btn type="submit" color="primary" :loading="loading">
              Logg inn
            </v-btn>
          </v-form>
          <v-card-text v-if="loginError" class="red--text">{{ loginError }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => {
    return {
      loading: false,
      email: '',
      password: '',
      loginError: '',
    }
  },
  methods: {
    async handleLogin() {
      if (!this.$refs.form.validate()) return
      this.loginError = ''
      this.loading = true

      try {
        const response = await axios.post('/login', {
          password: this.password,
          email: this.email,
        })
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', this.email)
        this.$router.go()
      } catch (err) {
        this.loginError = err.message || err
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
