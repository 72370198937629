<template>
  <div>
    <v-data-iterator
      :items="leads"
      item-key="lead_id"
      :loading="loading"
      v-bind.sync="iteratorOptions"
      no-data-text="Fant ingen leads"
      @update:page="fetchData"
      @update:items-per-page="fetchData"
      @hook:beforeMount="fetchData"
    >
      <template v-slot:loading>
        <v-progress-circular indeterminate color="white" size="144" />
      </template>

      <template v-slot:header="{ pagination, options, updateOptions }">
        <v-row class="align-end">
          <v-col>
            <h2 class="mb-3">
              {{ title }}
              <TooltipButton
                v-if="conceptarea"
                v-bind="createLeadButton"
                @click="
                  showLeadDialog({
                    leadtype_id: DEFAULT_LEADTYPE_ID,
                    leadrating_id: DEFAULT_LEADRATING_ID,
                    conceptarea_id: conceptarea.conceptarea_id,
                    conceptarea: conceptarea,
                    source_url: {},
                    created_timestamp: {
                      date: getCurrentDate(),
                      time: getCurrentTime(),
                    },
                    content: {},
                  })
                "
              />
            </h2>
          </v-col>
          <v-col>
            <v-data-footer
              v-if="!loading && leads.length"
              :pagination="pagination"
              :options="options"
              :items-per-page-text="iteratorOptions.footerProps['items-per-page-text']"
              :items-per-page-options="iteratorOptions.footerProps['items-per-page-options']"
              @update:options="updateOptions"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:default="{ items, isExpanded, expand }">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="white"
          size="144"
          class="d-block ml-auto mr-auto mt-8 mb-8"
        />
        <v-card v-for="lead in items" v-else :key="lead.lead_id" class="mb-12">
          <v-card-title class="black--text" :class="getLeadAssignableClass(lead)">
            <v-row class="justify-space-between">
              <v-col cols="12" md="6">
                <router-link
                  v-if="lead.conceptarea"
                  :to="{
                    name: 'conceptarea',
                    params: { conceptarea_id: lead.conceptarea_id },
                  }"
                  >{{ lead.conceptarea.concept.name }} {{ lead.conceptarea.area.name }}</router-link
                >
                <router-link
                  v-if="lead.listing"
                  :to="{
                    name: 'company',
                    params: { company_id: lead.listing.company_id },
                  }"
                  >Bedriftsprofil: {{ lead.listing.company.name }}</router-link
                >
                <v-chip
                  v-if="lead.is_from_campaign"
                  small
                  class="mx-1 text-uppercase campaign-label"
                  color="primary"
                  >Kampanje</v-chip
                >
                <v-avatar
                  v-if="
                    lead.is_from_campaign &&
                      !lead.conceptarea.companies_count &&
                      !lead.leadCompanies.length
                  "
                  color="error"
                  class="darken-4 white--text"
                  size="24"
                  @click="showEmailDialog(generateCampaignWarningEmail(lead))"
                  >!</v-avatar
                >
                <div class="text-caption">
                  {{ lead.created_timestamp.date | moment('DD.MM.YYYY') }}
                  {{ lead.created_timestamp.time | moment('HH:mm', 'hh:mm:ss') }}
                </div>
              </v-col>
              <v-col cols="12" md="6" class="d-flex align-center text-caption text-right">
                <div class="ml-auto">
                  <div v-if="lead.salesperson">
                    Gitt som agn til {{ lead.salesperson.full_name }}
                    {{ lead.bait_assigned_timestamp | moment('DD.MM.YYYY', 'YYYY-MM-DD hh:mm:ss') }}
                  </div>
                  <div v-if="lead.bait_sent_to">
                    Agn sendt til {{ lead.bait_sent_to }}
                    {{ lead.bait_sent_timestamp | moment('DD.MM.YYYY', 'YYYY-MM-DD hh:mm:ss') }}
                  </div>
                  <div v-if="lead.leadCompanies.length">
                    <span v-if="lead.leadCompanies.length == 1">
                      Fordelt til
                      <router-link
                        :to="{
                          name: 'company',
                          params: { company_id: lead.leadCompanies[0].company.company_id },
                        }"
                        >{{ lead.leadCompanies[0].company.name }}</router-link
                      >
                    </span>
                    <v-avatar
                      v-if="
                        lead.leadCompanies.filter(leadCompany => {
                          return !(leadCompany.email && leadCompany.email.sent_timestamp != null)
                        }).length > 0
                      "
                      color="error"
                      class="ml-1 darken-3 white--text"
                      size="28"
                      @click="expand(lead, !isExpanded(lead))"
                    >
                      !
                    </v-avatar>
                    <v-tooltip v-if="lead.leadCompanies.length > 1" bottom>
                      <template v-slot:activator="{ on }">
                        <span class="text-caption mr-1 cursorDefault" v-on="on">
                          Fordelt til
                          <router-link
                            :to="{
                              name: 'company',
                              params: { company_id: lead.leadCompanies[0].company.company_id },
                            }"
                            >{{ lead.leadCompanies[0].company.name }}
                          </router-link>
                          og {{ lead.leadCompanies.length - 1 }} til
                        </span>
                      </template>
                      <div v-for="leadCompany in lead.leadCompanies" :key="leadCompany.company_id">
                        {{ leadCompany.company.name }}
                      </div>
                    </v-tooltip>
                  </div>
                </div>
                <div>
                  <v-tooltip v-if="!lead.listing" bottom open-delay="500">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          class="ml-2"
                          color="black"
                          icon
                          :disabled="
                            !$currentUser.hasPermission('LEAD_UPDATE') ||
                              lead.leadCompanies.length > 0
                          "
                          @click="showBaitDialog(lead)"
                        >
                          <v-icon>phishing</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <template v-if="!$currentUser.hasPermission('LEAD_UPDATE')">
                      Du mangler tillatelse til å tildele agn
                    </template>

                    <template v-else-if="lead.leadCompanies.length > 0">
                      Leads som allerede er fordelt til en bedrift kan ikke brukes som agn
                    </template>

                    <template v-else>Tildel agn til selger</template>
                  </v-tooltip>

                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          class="ml-2"
                          color="black"
                          icon
                          :disabled="
                            !$currentUser.hasPermission('LEAD_COMPANY_CREATE') ||
                              (lead.conceptarea &&
                                lead.leadCompanies.length >=
                                  lead.conceptarea.max_companies_per_lead)
                          "
                          @click="showLeadCompanyDialog({ lead_id: lead.lead_id })"
                        >
                          <v-icon>add_business</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <template v-if="!$currentUser.hasPermission('LEAD_COMPANY_CREATE')">
                      Du mangler tillatelse til å fordele leads til bedrifter
                    </template>

                    <template
                      v-else-if="
                        lead.conceptarea &&
                          lead.leadCompanies.length >=
                            (lead.conceptarea.max_companies_per_lead
                              ? lead.conceptarea.max_companies_per_lead
                              : Infinity)
                      "
                    >
                      Leads i dette konseptområdet kan fordeles til maksimalt
                      {{ lead.conceptarea.max_companies_per_lead }} bedrifter
                    </template>

                    <template v-else>Fordel til bedrift</template>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            class="mt-5"
            :class="`${$currentUser.pref_dark_mode ? 'grey--text text--lighten-3' : ''}`"
          >
            <v-row v-if="lead.source_url && lead.source_url.href">
              <v-col
                cols="12"
                sm="4"
                md="3"
                class="pt-0"
                :class="{
                  'pb-0': $vuetify.breakpoint.xs,
                  'pb-1': $vuetify.breakpoint.smAndUp,
                }"
              >
                <strong>Kilde: </strong>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="9"
                class="pt-0"
                :class="{
                  'pb-3': $vuetify.breakpoint.xs,
                  'pb-1': $vuetify.breakpoint.smAndUp,
                }"
              >
                <span style="opacity: .35;"> {{ lead.source_url.protocol }}//</span
                >{{ lead.source_url.hostname
                }}<span style="opacity: .35;"
                  >{{ lead.source_url.pathname }}{{ lead.source_url.search }}</span
                >
              </v-col>
            </v-row>
            <v-row v-for="[key, value] in Object.entries(lead.content)" :key="key">
              <v-col
                cols="12"
                sm="4"
                md="3"
                class="pt-0"
                :class="{
                  'pb-0': $vuetify.breakpoint.xs,
                  'pb-1': $vuetify.breakpoint.smAndUp,
                }"
              >
                <strong>{{ key | capitalizeFirstLetter }}</strong>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="9"
                class="pt-0"
                :class="{
                  'pb-3': $vuetify.breakpoint.xs,
                  'pb-1': $vuetify.breakpoint.smAndUp,
                }"
              >
                <template v-if="isExpanded(lead)">
                  <span class="pre-formatted">{{ value.toString() }}</span>
                </template>
                <template v-else>
                  {{ value | truncateString(150, true) }}
                </template>
              </v-col>
            </v-row>
            <v-btn
              v-if="isExpanded(lead)"
              fab
              absolute
              bottom
              right
              small
              color="grey darken-3"
              @click="expand(lead, false)"
            >
              <v-icon color="white">expand_less</v-icon>
            </v-btn>
            <v-btn
              v-else
              fab
              absolute
              bottom
              right
              small
              color="grey darken-3"
              @click="expand(lead, true)"
            >
              <v-icon color="white">expand_more</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text
            v-if="isExpanded(lead) && lead.leadCompanies.length"
            :class="
              `${
                $currentUser.pref_dark_mode
                  ? 'blue-grey darken-4 grey--text text--lighten-3'
                  : 'grey lighten-3'
              }`
            "
          >
            <v-row
              v-for="leadCompany in lead.leadCompanies"
              :key="leadCompany.company_id"
              class="align-center"
            >
              <v-col cols="12" sm="5" md="3" lg="4" xl="3" class="pt-0">
                <v-chip
                  v-if="leadCompany.email && leadCompany.email.sent_timestamp"
                  class="ma-2"
                  color="success"
                  @click="showEmailDialog(leadCompany.email)"
                >
                  <v-icon left>mark_email_read</v-icon>Sendt
                  {{ leadCompany.email.sent_timestamp | moment('DD.MM.YYYY') }}
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2"
                  color="error"
                  @click="closeLeadCompanyDialogAndShowEmailDialog(leadCompany)"
                >
                  <v-icon left>email</v-icon>Ikke sendt til kunde
                </v-chip>
              </v-col>
              <v-col cols="12" sm="7" md="9" lg="8" xl="9" class="pt-0">
                {{ leadCompany.company.name }}
                <TooltipButton
                  :button="{
                    disabled:
                      (leadCompany.email && leadCompany.email.sent_timestamp != null) ||
                      !$currentUser.hasPermission('LEAD_COMPANY_DELETE') ||
                      loading,
                    icon: 'delete_forever',
                    color: 'error',
                  }"
                  :tooltip="{
                    openDelay: 500,
                    bottom: true,
                    text: !$currentUser.hasPermission('LEAD_COMPANY_DELETE')
                      ? 'Du mangler tillatelse til å slette fordelinger'
                      : leadCompany.email && leadCompany.email.sent_timestamp != null
                      ? 'Du kan ikke slette en fordeling når leadet allerede er sendt til kunde'
                      : 'Slett fordeling',
                  }"
                  @click="
                    getDeleteConfirmation(
                      leadCompany,
                      `Bekreft sletting av fordeling`,
                      `Vil du virkelig slette fordelingen til ${leadCompany.company.name}?`
                    )
                  "
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            v-if="isExpanded(lead)"
            :class="
              `justify-center primary ${
                lead.leadCompanies.length > 0 || !$currentUser.hasPermission('LEAD_DELETE')
                  ? ''
                  : 'darken-2'
              }`
            "
          >
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    icon
                    color="white"
                    :disabled="
                      loading ||
                        lead.leadCompanies.length > 0 ||
                        !$currentUser.hasPermission('LEAD_UPDATE')
                    "
                    @click="showLeadDialog(lead)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </span>
              </template>
              <template v-if="!$currentUser.hasPermission('LEAD_UPDATE')">
                Du mangler tillatelse til å redigere leads
              </template>
              <template v-else-if="lead.leadCompanies.length > 0">
                Du kan ikke redigere et lead som allerede er fordelt til en bedrift
              </template>
              <template v-else>Rediger lead</template>
            </v-tooltip>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    icon
                    color="error"
                    :disabled="
                      loading ||
                        lead.leadCompanies.length > 0 ||
                        !$currentUser.hasPermission('LEAD_DELETE')
                    "
                    @click="
                      getDeleteConfirmation(
                        lead,
                        'Bekreft sletting av lead',
                        'Vil du virkelig slette dette leadet for alltid?'
                      )
                    "
                  >
                    <v-icon>delete_forever</v-icon>
                  </v-btn>
                </span>
              </template>
              <template v-if="!$currentUser.hasPermission('LEAD_DELETE')">
                Du mangler tillatelse til å slette leads
              </template>
              <template v-else-if="lead.leadCompanies.length > 0">
                Du kan ikke slette et lead som allerede er fordelt til en bedrift
              </template>
              <template v-else>Slett lead</template>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </template>
    </v-data-iterator>

    <LeadDialog
      v-bind="leadDialog"
      @closeAndReset="closeAndResetLeadDialog"
      @entityCreated="setNewLead"
      @entityUpdated="setNewLead"
    />

    <LeadCompanyDialog
      v-bind="leadCompanyDialog"
      @closeAndReset="closeAndResetLeadCompanyDialog"
      @entityCreated="closeLeadCompanyDialogAndShowEmailDialog"
    />

    <BaitDialog
      v-bind="baitDialog"
      @closeAndReset="closeAndResetBaitDialog"
      @entityUpdated="fetchData"
    />

    <EmailDialog
      ref="emailDialog"
      v-bind="emailDialog"
      @entityCreated="setNewEmailAndUpdateLeadCompany"
      @closeAndReset="closeAndResetEmailDialog"
      @mailSent="closeAndResetEmailDialog"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="closeAndResetDeleteConfirmationDialog(`Sletting var vellykket`)"
    />
  </div>
</template>

<script>
import color from '@/mixins/color'
import combineArrays from '@/mixins/combineArrays'
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog'
import baitDialog from '@/mixins/dialogs/baitDialog'
import emailDialog from '@/mixins/dialogs/emailDialog.js'
import leadCompanyDialog from '@/mixins/dialogs/leadCompanyDialog.js'
import BaitDialog from '@/components/dialogs/BaitDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EmailDialog from '@/components/dialogs/EmailDialog'
import LeadDialog from '@/components/dialogs/LeadDialog'
import LeadCompanyDialog from '@/components/dialogs/LeadCompanyDialog'
import TooltipButton from '@/components/TooltipButton.vue'
import dateAndTime from '@/mixins/dateAndTime'
import leadDialog from '@/mixins/dialogs/leadDialog.js'
import responseHelper from '@/mixins/responseHelper'
import Company from '@/models/Company'
import Conceptarea from '@/models/Conceptarea'
import Email from '@/models/Email'
import Emailtemplate from '@/models/Emailtemplate'
import Lead from '@/models/Lead'
import LeadCompany from '@/models/LeadCompany'
import Listing from '@/models/Listing'

export default {
  components: {
    BaitDialog,
    DeleteConfirmationDialog,
    EmailDialog,
    LeadDialog,
    LeadCompanyDialog,
    TooltipButton,
  },
  mixins: [
    color,
    combineArrays,
    dateAndTime,
    baitDialog,
    deleteConfirmationDialog,
    emailDialog,
    leadCompanyDialog,
    leadDialog,
    responseHelper,
  ],
  props: {
    conceptarea: {
      type: Conceptarea,
      default: undefined,
    },
    iteratorOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    search: {
      type: Array,
      default() {
        return []
      },
    },
    title: {
      type: String,
      default: 'Leads',
    },
  },
  data: () => {
    return {
      DEFAULT_LEADTYPE_ID: 10, //"Kontaktskjema"
      DEFAULT_LEADRATING_ID: 4, //"B"
      loading: false,
    }
  },
  computed: {
    leads() {
      let query = Lead.query()
        .with('leadtype')
        .with('conceptarea')
        .with('conceptarea.area')
        .with('conceptarea.concept')
        .with('leadCompanies')
        .with('leadCompanies.email')
        .with('leadCompanies.company')
        .with('leadCompanies.lead')
        .with('listing')
        .with('listing.company')
        .with('salesperson')

      query = query
        .orderBy(lead => lead.created_timestamp.date, 'desc')
        .orderBy(lead => lead.created_timestamp.time, 'desc')
        .get()

      return query
    },
    createLeadButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('LEAD_CREATE') || this.loading,
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEAD_CREATE')
            ? 'Opprett lead'
            : 'Du mangler tillatelse til å opprette leads',
        },
      }
    },
    campaignWarningEmailtemplate() {
      return Emailtemplate.query()
        .where('name', 'campaign-warning')
        .first()
    },
  },
  methods: {
    async setNewEmailAndUpdateLeadCompany(email) {
      this.setNewEmail(email)

      if (this.selectedLeadCompany?.$id) {
        await LeadCompany.updateOne(this.selectedLeadCompany.$id, {
          email_id: email.email_id,
        })
      }
    },
    async fetchData() {
      this.loading = true

      const leads = await Lead.fetchPaginated({
        pagination: this.iteratorOptions.options,
        sortBy: 'created_timestamp',
        sortDesc: true,
        search: this.search,
      })

      this.iteratorOptions.serverItemsLength = leads.response.data.total

      if (this.iteratorOptions.serverItemsLength) {
        const leadIds = this.getIdsFromEntities(leads, 'lead_id')
        const listingIds = this.getIdsFromEntities(leads, 'listing_id')

        const [leadCompanies, listings] = await Promise.all([
          LeadCompany.fetchBatchByKey('lead_id', leadIds),
          Listing.fetchBatchByKey('listing_id', listingIds),
        ])

        const emailIds = this.getIdsFromEntities(leadCompanies, 'email_id')
        const companyIdsFromLeadCompanies = this.getIdsFromEntities(leadCompanies, 'company_id')
        const companyIdsFromListings = this.getIdsFromEntities(listings, 'company_id')

        const companyIds = this.combineArraysWithoutDuplicates(
          companyIdsFromLeadCompanies,
          companyIdsFromListings
        )

        const promises = [Company.fetchBatch(companyIds), Email.fetchBatch(emailIds)]

        if (!this.conceptarea) {
          const conceptareaIds = this.getIdsFromEntities(leads, 'conceptarea_id')
          promises.push(Conceptarea.fetchBatch(conceptareaIds))
        }

        await Promise.all(promises)
      }
      this.loading = false
    },
    setNewLead(lead) {
      this.leadDialog.lead = Lead.query()
        .where('lead_id', lead.lead_id)
        .with('conceptarea')
        .with('conceptarea.concept')
        .with('conceptarea.area')
        .first()
    },
    generateCampaignWarningEmail(lead) {
      const html = this.campaignWarningEmailtemplate?.generateHtml(lead, {
        noReply: false,
        footer: false,
      })

      return {
        user_id: this.$currentUser.user_id,
        subject: `Ikke-fordelbart lead fra kampanje: ${lead.conceptarea.concept.name} ${lead.conceptarea.area.name}`,
        content: html,
        sender: 'no-reply@leadly.no',
        recipient: 'kampanjevarsler@leadly.no',
      }
    },
    getLeadAssignableClass(lead) {
      if (lead.leadCompanies.length > 0) {
        return 'assigned-lead'
      }
      if (lead.conceptarea?.companies_count == 0) {
        return 'unassignable-lead'
      }

      return 'unassigned-lead'
    },
  },
}
</script>

<style scoped lang="less">
.assigned-lead {
  background: var(--success);
}

.unassigned-lead {
  background: var(--warning);
}

.unassignable-lead {
  background: var(--error);
}

.v-avatar {
  font-size: 1.15rem !important;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.v-card__title {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

a {
  color: inherit;

  &:hover {
    color: var(--secondary);
  }
}

.campaign-label {
  pointer-events: none;
}
</style>
