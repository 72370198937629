<template>
  <BaseDialog
    v-if="show && pause"
    ref="pauseDialog"
    v-bind="baseDialog"
    @closeAndReset="closeDialog"
  >
    <template slot="form">
      <v-form ref="form" autocomplete="off">
        <v-select
          v-model="pause.pausereason_id"
          class="pausereason-select"
          :items="pausereasons"
          label="Grunn til pausing"
          item-text="name"
          item-value="pausereason_id"
          :disabled="loading"
          :hint="!pause.pausereason_id ? 'Påkrevd' : ''"
          persistent-hint
          :rules="[$validationRules.required]"
          @change="handleReasonField"
        />

        <v-menu
          v-model="showDateFromPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="pause.from_timestamp.date"
              label="Pauset fra"
              type="date"
              readonly
              :rules="[$validationRules.required]"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="pause.from_timestamp.date"
            color="success"
            :disabled="loading"
            @change="handleFromField"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="showTimeFromPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="pause.from_timestamp.time"
              label="Klokken"
              type="time"
              readonly
              :rules="[$validationRules.required]"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="pause.from_timestamp.time"
            color="success"
            format="24hr"
            :disabled="loading"
            @change="handleFromField"
          ></v-time-picker>
        </v-menu>

        <v-menu
          v-model="showDateToPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="pause.to_timestamp.date"
              label="Pauset til"
              type="date"
              readonly
              clearable
              v-on="on"
              @change="handleToField"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="pause.to_timestamp.date"
            color="success"
            :disabled="loading"
            @change="handleToField"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="showTimeToPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="pause.to_timestamp.time"
              label="Klokken"
              type="time"
              readonly
              clearable
              v-on="on"
              @change="handleToField"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="pause.to_timestamp.time"
            color="success"
            format="24hr"
            :disabled="loading"
            @change="handleToField"
          ></v-time-picker>
        </v-menu>

        <v-textarea
          v-model="pause.comment"
          label="Kommentar"
          rows="1"
          auto-grow
          clearable
          :disabled="loading"
          @change="handleCommentField"
        />
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/dialogs/BaseDialog'
import Pause from '@/models/Pause.js'
import Pausereason from '@/models/Pausereason.js'
import dateAndTime from '@/mixins/dateAndTime.js'
import entityPersistence from '@/mixins/entityPersistence.js'

export default {
  name: 'PauseDialog',
  components: { BaseDialog },
  mixins: [dateAndTime, entityPersistence],
  props: {
    show: Boolean,
    pause: {
      type: Pause,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      showDateFromPicker: false,
      showTimeFromPicker: false,
      showDateToPicker: false,
      showTimeToPicker: false,
    }
  },
  computed: {
    pausereasons() {
      return Pausereason.all()
    },
    baseDialog() {
      return {
        show: this.show,
        entity: this.pause,
        editTitle: 'Rediger pause',
        createTitle: 'Opprett ny pause',
        loading: this.loading,
      }
    },
    pauseDialog() {
      return {
        fields: [
          {
            name: 'from_timestamp',
            type: 'text',
            label: 'Pause fra',
          },
        ],
      }
    },
  },
  methods: {
    async handleFromField() {
      this.showDateFromPicker = false
      this.showTimeFromPicker = false

      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.pauseDialog.clearErrorAlert()

      if (this.pause.pause_id) {
        await this.createOrUpdateEntity(
          Pause,
          {
            from_timestamp: this.concatDateAndTime(this.pause.from_timestamp),
          },
          this.pause.pause_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleToField() {
      this.showDateToPicker = false
      this.showTimeToPicker = false

      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.pauseDialog.clearErrorAlert()

      if (this.pause.pause_id) {
        await this.createOrUpdateEntity(
          Pause,
          {
            to_timestamp: this.concatDateAndTime(this.pause.to_timestamp),
          },
          this.pause.pause_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleReasonField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.pauseDialog.clearErrorAlert()
      if (this.pause.pause_id) {
        await this.createOrUpdateEntity(
          Pause,
          { pausereason_id: this.pause.pausereason_id },
          this.pause.pause_id
        )
      } else {
        await this.createEntity()
      }
    },
    async handleCommentField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.$refs.pauseDialog.clearErrorAlert()
      if (this.pause.pause_id) {
        await this.createOrUpdateEntity(Pause, { comment: this.pause.comment }, this.pause.pause_id)
      } else {
        await this.createEntity()
      }
    },
    async createEntity() {
      this.$refs.pauseDialog.clearErrorAlert()
      await this.createOrUpdateEntity(Pause, {
        user_id: this.pause.user_id,
        company_id: this.pause.company_id,
        pausereason_id: this.pause.pausereason_id,
        from_timestamp: this.concatDateAndTime(this.pause.from_timestamp),
        to_timestamp: this.concatDateAndTime(this.pause.to_timestamp),
        comment: this.pause.comment,
      })
    },
    handleError(message) {
      this.$refs.pauseDialog.showErrorAlert(message)
      this.loading = false
    },
    closeDialog() {
      this.$refs.pauseDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>

<style>
.pausereason-select .v-messages__message {
  color: var(--error);
}
</style>
