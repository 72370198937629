<template>
  <div>
    <v-container v-if="$currentUser" class="pa-16">
      <v-row>
        <v-col cols="12" md="6" lg="5" xl="4">
          <v-card class="mb-8">
            <v-card-title class="primary">
              Konsept:
              <template v-if="concept.parent_id"> {{ concept.parent.name }} • </template>
              {{ concept.name }}
            </v-card-title>
            <v-card-text class="pa-4">
              <dl>
                <template v-if="concept.parent">
                  <dt>Foreldrekonsept</dt>
                  <dd>
                    <router-link
                      :to="{
                        name: 'concept',
                        params: { concept_id: concept.parent.concept_id },
                      }"
                      style="text-decoration: none;"
                      >{{ concept.parent.name }}
                    </router-link>
                  </dd>
                </template>
                <dt>Standard pris per lead</dt>
                <dd>{{ concept.default_lead_price }}</dd>
                <template v-if="childConcepts.length">
                  <dt>{{ childConcepts.length }} underkonsepter</dt>
                  <dd>
                    <ul>
                      <li
                        v-for="childConcept in childConcepts"
                        :key="childConcept.concept_id"
                        class="mb-1"
                      >
                        <router-link
                          :to="{
                            name: 'concept',
                            params: { concept_id: childConcept.concept_id },
                          }"
                          style="text-decoration: none;"
                          >{{ childConcept.name }}</router-link
                        >
                      </li>
                    </ul>
                  </dd>
                </template>
              </dl>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="7" xl="8">
          <v-card class="mb-8">
            <v-card-title class="primary">
              Konseptområder
              <TooltipButton
                v-bind="createConceptareaButton"
                @click="
                  $refs.conceptareaDialog.showDialog(conceptareaModel, {
                    concept_id: concept.concept_id,
                  })
                "
              />
            </v-card-title>
            <v-card-text class="pa-4">
              <v-progress-circular v-if="loading" indeterminate color="white" size="144" />
              <ul v-else class="childconceptareas">
                <li v-for="conceptarea in conceptareas" :key="conceptarea.conceptarea_id">
                  <router-link
                    :to="`/conceptareas/${conceptarea.conceptarea_id}`"
                    style="text-decoration: none;"
                  >
                    {{ conceptarea.area.name }}
                  </router-link>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        v-model="showSpeedDial"
        fixed
        bottom
        right
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="showSpeedDial" color="primary" fab>
            <v-icon v-if="showSpeedDial">close</v-icon>
            <v-icon v-else>settings</v-icon>
          </v-btn>
        </template>

        <div transition="slide-y-reverse-transition">
          <TooltipButton
            v-bind="deleteConceptButton"
            @click="
              getDeleteConfirmation(
                concept,
                `Bekreft sletting av konsept ${concept.name}`,
                `Vil du virkelig slette konseptet ${concept.name} for alltid?`
              )
            "
          />

          <TooltipButton
            v-bind="updateConceptButton"
            @click="$refs.conceptDialog.showDialog(conceptModel, concept)"
          />
        </div>
      </v-speed-dial>

      <EntityDialog
        ref="conceptDialog"
        v-bind="conceptDialog"
        @entityUpdated="handleConceptUpdated"
      />

      <EntityDialog
        ref="conceptareaDialog"
        v-bind="conceptareaDialog"
        @entityCreated="handleConceptareaCreated"
      />

      <DeleteConfirmationDialog
        ref="deleteConfirmationDialog"
        v-bind="deleteConfirmationDialog"
        @cancel="closeAndResetDeleteConfirmationDialog"
        @success="
          $router.push({ name: 'concepts' }, () => {
            $successToast('Du slettet konseptet')
          })
        "
      />

      <ErrorDialog v-bind="errorDialog" @closeAndReset="clearErrorDialog" />
    </v-container>
  </div>
</template>

<script>
import color from '@/mixins/color'
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import errorDialog from '@/mixins/dialogs/errorDialog.js'
import responseHelper from '@/mixins/responseHelper'
import Area from '@/models/Area'
import Concept from '@/models/Concept'
import Conceptarea from '@/models/Conceptarea'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EntityDialog from '@/components/dialogs/EntityDialog'
import ErrorDialog from '@/components/dialogs/ErrorDialog'
import TooltipButton from '@/components/TooltipButton'

export default {
  components: {
    DeleteConfirmationDialog,
    EntityDialog,
    ErrorDialog,
    TooltipButton,
  },
  mixins: [color, deleteConfirmationDialog, errorDialog, responseHelper],
  data: () => {
    return {
      loading: false,
      conceptToDelete: null,
      showSpeedDial: false,
      conceptModel: Concept,
      conceptareaModel: Conceptarea,
    }
  },
  computed: {
    conceptDialog() {
      return {
        editTitle: 'Rediger konsept',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'default_lead_price',
            type: 'text',
            label: 'Standard pris per lead',
            rules: [this.$validationRules.required],
          },
          {
            name: 'parent_id',
            type: 'select',
            label: 'Foreldrekonsept',
            items: Concept.query()
              .orderBy('name')
              .get(),
            itemText: 'name',
            itemValue: 'concept_id',
            clearable: true,
          },
        ],
      }
    },
    conceptareaDialog() {
      return {
        createTitle: 'Opprett konseptområde',
        editTitle: 'Rediger konseptområde',
        fields: [
          {
            name: 'area_id',
            type: 'select',
            label: 'Område',
            items: Area.query()
              .whereHasNot('conceptareas', query => {
                query.where('concept_id', this.$route.params.concept_id)
              })
              .get(),
            itemText: 'name',
            itemValue: 'area_id',
            noDataText: this.$currentUser.hasPermission('AREA_CREATE')
              ? 'Du må opprette nytt område under Administrasjon > Områder.'
              : 'Nytt område må opprettes av en administrator.',
          },
          {
            name: 'max_companies',
            type: 'text',
            label: 'Max antall bedrifter',
            hint: '0 = ubegrenset',
            persistentHint: true,
            rules: [this.$validationRules.required],
          },
          {
            name: 'max_companies_per_lead',
            type: 'text',
            label: 'Max bedrifter per lead',
            hint: '0 = ubegrenset',
            persistentHint: true,
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createConceptareaButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('CONCEPTAREA_CREATE') || this.loading,
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('CONCEPTAREA_CREATE')
            ? 'Opprett konseptområde'
            : 'Du mangler tillatelse til å opprette konseptområde',
        },
      }
    },
    updateConceptButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('CONCEPT_UPDATE') || this.loading,
          color: 'success',
          fab: true,
          small: true,
        },
        tooltip: {
          openDelay: 500,
          left: true,
          text: this.$currentUser.hasPermission('CONCEPT_UPDATE')
            ? 'Rediger konsept'
            : 'Du mangler tillatelse til å redigere konsepter',
        },
      }
    },
    deleteConceptButton() {
      return {
        button: {
          icon: 'delete_forever',
          fab: true,
          small: true,
          disabled: !this.$currentUser.hasPermission('CONCEPT_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          left: true,
          text: this.$currentUser.hasPermission('CONCEPT_DELETE')
            ? 'Slett konsept'
            : 'Du mangler tillatelse til å slette konsepter',
        },
      }
    },
    concept() {
      return Concept.query()
        .whereId(parseInt(this.$route.params.concept_id))
        .with('parent')
        .first()
    },
    conceptareas() {
      let conceptareas = Conceptarea.query()
        .where('concept_id', parseInt(this.$route.params.concept_id))
        .with('area')
        .get()

      conceptareas = conceptareas.sort((a, b) => {
        if (a.area.name < b.area.name) {
          return -1
        }
        return 1
      })

      conceptareas.some(
        (item, index) =>
          item.area.name == 'Hele Norge' && conceptareas.unshift(conceptareas.splice(index, 1)[0])
      )

      return conceptareas
    },
    childConcepts() {
      if (!this.concept) {
        return []
      }

      return Concept.query()
        .where('parent_id', this.concept.concept_id)
        .get()
    },
  },
  watch: {
    $route: 'fetchData',
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        await Conceptarea.fetchAll({
          search: { concept_id: parseInt(this.$route.params.concept_id) },
        })
      } catch (err) {
        console.error('Error fetching conceptarea: ', err)
      }
      this.loading = false
    },
    handleConceptUpdated(instance) {
      this.$refs.conceptDialog.entity = instance
      this.$successToast(`Endringen av konseptet ${instance.name} er lagret`)
    },
    handleConceptareaCreated(instance) {
      this.$refs.conceptareaDialog.entity = instance
      const area = Area.find(instance.area_id).name
      const concept = Concept.find(instance.concept_id).name
      this.$successToast(`Du opprettet konseptområdet ${concept} • ${area}`)
    },
  },
}
</script>

<style scoped lang="less">
ul {
  list-style: none;
  padding: 0;
}

dl {
  display: grid;

  @media (min-width: 550px) {
    grid-template-columns: max-content auto;
  }
}

dt {
  font-weight: bold;
  margin-bottom: 0.25em;

  &:after {
    content: ':';
    margin-right: 1em;
  }
}

dd {
  margin-bottom: 1em;
}
.childconceptareas {
  display: grid;
  gap: 0.5em;

  @media (min-width: 400px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (min-width: 1264px) {
    grid-template-columns: repeat(4, auto);
  }
  @media (min-width: 1900px) {
    grid-template-columns: repeat(6, auto);
  }
}
</style>
