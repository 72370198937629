import BaseModel from './BaseModel'
import Company from './Company'
import Pausereason from './Pausereason'
import User from './User'
import { splitDatetime } from '@/mixins/dateAndTime'

export default class Pause extends BaseModel {
  static entity = 'pauses'
  static primaryKey = 'pause_id'

  static fields() {
    return {
      pause_id: this.attr(null),
      company_id: this.attr(null),
      user_id: this.attr(null),
      pausereason_id: this.attr(null),
      from_timestamp: this.attr({
        date: '',
        time: '',
      }),
      to_timestamp: this.attr({
        date: '',
        time: '',
      }).nullable(),
      is_active: this.boolean(false),
      is_future: this.boolean(false),
      is_past: this.boolean(false),
      comment: this.string().nullable(),
      user: this.hasOne(User, 'user_id', 'user_id'),
      pausereason: this.hasOne(Pausereason, 'pausereason_id', 'pausereason_id'),
      company: this.hasOne(Company, 'company_id', 'company_id'),
    }
  }

  static fetchBatch(ids, deleteAll = true, config = {}) {
    config.dataTransformer = dataTransformer
    return super.fetchBatch(ids, deleteAll, config)
  }

  static fetchByKey(key, id, deleteAll = true, config = {}) {
    config.dataTransformer = dataTransformer
    return super.fetchByKey(key, id, deleteAll, config)
  }

  static updateOne(id, data, config = {}) {
    config.dataTransformer = dataTransformer
    return super.updateOne(id, data, config)
  }

  static createOne(data, config = {}) {
    config.dataTransformer = dataTransformer
    return super.createOne(data, config)
  }

  static createOrUpdateOne(id, data, config = {}) {
    config.dataTransformer = dataTransformer
    return super.createOrUpdateOne(id, data, config)
  }
}

function dataTransformer(response) {
  let pauses = response.data

  if (!Array.isArray(pauses)) {
    pauses = pauses.data
  }

  pauses = splitDatetimes(pauses)
  return pauses
}

function splitDatetimes(pauses) {
  return pauses.map(pause => {
    pause.from_timestamp = splitDatetime(pause.from_timestamp)
    pause.to_timestamp = splitDatetime(pause.to_timestamp)

    if (pause.from_timestamp?.date && !pause.from_timestamp?.time) {
      pause.from_timestamp.time = '00:00:00'
    }

    if (pause.to_timestamp?.date && !pause.to_timestamp?.time) {
      pause.to_timestamp.time = '00:00:00'
    }
    return pause
  })
}
