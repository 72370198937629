var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show && _vm.pause)?_c('BaseDialog',_vm._b({ref:"pauseDialog",on:{"closeAndReset":_vm.closeDialog}},'BaseDialog',_vm.baseDialog,false),[_c('template',{slot:"form"},[_c('v-form',{ref:"form",attrs:{"autocomplete":"off"}},[_c('v-select',{staticClass:"pausereason-select",attrs:{"items":_vm.pausereasons,"label":"Grunn til pausing","item-text":"name","item-value":"pausereason_id","disabled":_vm.loading,"hint":!_vm.pause.pausereason_id ? 'Påkrevd' : '',"persistent-hint":"","rules":[_vm.$validationRules.required]},on:{"change":_vm.handleReasonField},model:{value:(_vm.pause.pausereason_id),callback:function ($$v) {_vm.$set(_vm.pause, "pausereason_id", $$v)},expression:"pause.pausereason_id"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.pause.from_timestamp.date,"label":"Pauset fra","type":"date","readonly":"","rules":[_vm.$validationRules.required]}},on))]}}],null,false,3333272264),model:{value:(_vm.showDateFromPicker),callback:function ($$v) {_vm.showDateFromPicker=$$v},expression:"showDateFromPicker"}},[_c('v-date-picker',{attrs:{"color":"success","disabled":_vm.loading},on:{"change":_vm.handleFromField},model:{value:(_vm.pause.from_timestamp.date),callback:function ($$v) {_vm.$set(_vm.pause.from_timestamp, "date", $$v)},expression:"pause.from_timestamp.date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.pause.from_timestamp.time,"label":"Klokken","type":"time","readonly":"","rules":[_vm.$validationRules.required]}},on))]}}],null,false,1300806168),model:{value:(_vm.showTimeFromPicker),callback:function ($$v) {_vm.showTimeFromPicker=$$v},expression:"showTimeFromPicker"}},[_c('v-time-picker',{attrs:{"color":"success","format":"24hr","disabled":_vm.loading},on:{"change":_vm.handleFromField},model:{value:(_vm.pause.from_timestamp.time),callback:function ($$v) {_vm.$set(_vm.pause.from_timestamp, "time", $$v)},expression:"pause.from_timestamp.time"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.pause.to_timestamp.date,"label":"Pauset til","type":"date","readonly":"","clearable":""},on:{"change":_vm.handleToField}},on))]}}],null,false,71750594),model:{value:(_vm.showDateToPicker),callback:function ($$v) {_vm.showDateToPicker=$$v},expression:"showDateToPicker"}},[_c('v-date-picker',{attrs:{"color":"success","disabled":_vm.loading},on:{"change":_vm.handleToField},model:{value:(_vm.pause.to_timestamp.date),callback:function ($$v) {_vm.$set(_vm.pause.to_timestamp, "date", $$v)},expression:"pause.to_timestamp.date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.pause.to_timestamp.time,"label":"Klokken","type":"time","readonly":"","clearable":""},on:{"change":_vm.handleToField}},on))]}}],null,false,267616790),model:{value:(_vm.showTimeToPicker),callback:function ($$v) {_vm.showTimeToPicker=$$v},expression:"showTimeToPicker"}},[_c('v-time-picker',{attrs:{"color":"success","format":"24hr","disabled":_vm.loading},on:{"change":_vm.handleToField},model:{value:(_vm.pause.to_timestamp.time),callback:function ($$v) {_vm.$set(_vm.pause.to_timestamp, "time", $$v)},expression:"pause.to_timestamp.time"}})],1),_c('v-textarea',{attrs:{"label":"Kommentar","rows":"1","auto-grow":"","clearable":"","disabled":_vm.loading},on:{"change":_vm.handleCommentField},model:{value:(_vm.pause.comment),callback:function ($$v) {_vm.$set(_vm.pause, "comment", $$v)},expression:"pause.comment"}})],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }