export default {
  computed: {
    createCompanyButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('COMPANY_CREATE')) ||
            this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('COMPANY_CREATE')
              ? 'Opprett bedrift'
              : 'Du mangler tillatelse til å opprette bedrifter',
        },
      }
    },
    updateCompanyButton() {
      return {
        button: {
          icon: 'edit',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('COMPANY_UPDATE')) ||
            this.loading,
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('COMPANY_UPDATE')
              ? 'Rediger bedrift'
              : 'Du mangler tillatelse til å redigere bedrifter',
        },
      }
    },
  },
}
