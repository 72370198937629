import BaseModel from './BaseModel'
import Conceptarea from './Conceptarea'
import Company from './Company'

export default class ConceptareaCompany extends BaseModel {
  static entity = 'conceptareas_companies'

  static primaryKey = ['conceptarea_id', 'company_id']

  static fields() {
    return {
      conceptarea_id: this.attr(null),
      company_id: this.attr(null),
      company: this.hasOne(Company, 'company_id', 'company_id'),
      conceptarea: this.hasOne(Conceptarea, 'conceptarea_id', 'conceptarea_id'),
    }
  }
}
