<template>
  <v-dialog :value="show" persistent max-width="400px">
    <v-banner two-line color="error" elevation="24">
      <v-avatar slot="icon" color="error lighten-5" size="40">
        <v-icon color="error">
          error_outline
        </v-icon>
      </v-avatar>
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </v-banner>
    <v-btn tile class="error darken-2" @click="$emit('closeAndReset')">Ok</v-btn>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  props: {
    show: Boolean,
    errors: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>
