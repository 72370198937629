<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Nettsteder</v-toolbar-title>
            <v-spacer />
            <TooltipButton v-bind="createWebsiteButton" @click="$refs.websiteDialog.showDialog()" />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item v-for="website in websites" :key="website.website_id">
              <v-list-item-content>
                <v-list-item-title>{{ website.domainname }}</v-list-item-title>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateWebsiteButton"
                @click="$refs.websiteDialog.showDialog(website)"
              />

              <TooltipButton
                v-bind="deleteWebsiteButton"
                @click="
                  getDeleteConfirmation(
                    website,
                    `Bekreft sletting av nettsted ${website.name}`,
                    `Vil du virkelig slette nettstedet ${website.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <WebsiteDialog ref="websiteDialog" />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet nettstedet ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import websiteDialog from '@/mixins/dialogs/websiteDialog.js'
import responseHelper from '@/mixins/responseHelper'
import WebsiteDialog from '@/components/dialogs/WebsiteDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/TooltipButton'
import Website from '@/models/Website'

export default {
  components: {
    DeleteConfirmationDialog,
    WebsiteDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog, websiteDialog, responseHelper],
  data() {
    return {
      loading: false,
      model: Website,
    }
  },
  computed: {
    deleteWebsiteButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('WEBSITE_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('WEBSITE_DELETE')
            ? 'Slett nettsted'
            : 'Du mangler tillatelse til å slette nettsteder',
        },
      }
    },
    websites() {
      return Website.all()
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.websiteDialog.entity = instance
      this.$successToast(`Du opprettet nettstedet ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.websiteDialog.entity = instance
      this.$successToast(`Endringen av nettstedet ${instance.name} er lagret`)
    },
  },
}
</script>
