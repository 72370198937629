<template>
  <EntityDialog
    ref="entityDialog"
    v-bind="websiteDialog"
    @entityCreated="handleEntityCreated"
    @entityUpdated="handleEntityUpdated"
  />
</template>

<script>
import EntityDialog from '@/components/dialogs/EntityDialog.vue'
import Website from '@/models/Website.js'

export default {
  name: 'WebsiteDialog',
  components: {
    EntityDialog,
  },
  mixins: [],
  props: {
    show: Boolean,
  },
  data: () => {
    return {
      loading: false,
      selectedEntity: null,
    }
  },
  computed: {
    website() {
      return Website.find(this.selectedEntity?.website_id)
    },
    websiteDialog() {
      return {
        createTitle: 'Opprett nytt nettsted',
        editTitle: 'Rediger nettsted',
        fields: [
          {
            name: 'domainname',
            type: 'text',
            label: 'Domenenavn (uten https://www)',
            rules: [this.$validationRules.required, this.$validationRules.domainname],
          },
        ],
      }
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.selectedEntity = instance
      this.$parent.$successToast(`Du opprettet nettstedet ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$parent.$successToast(`Endringen av nettstedet ${instance.name} er lagret`)
    },
    handleError(message) {
      this.$refs.entityDialog.showErrorAlert(message)
      this.loading = false
    },
    showDialog(website) {
      this.selectedEntity = website
      this.$refs.entityDialog.showDialog(Website, this.website)
    },
    closeDialog() {
      this.$refs.leadDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
