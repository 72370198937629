var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"headline"},[_c('span',[_vm._v("leads")]),_c('span',{staticClass:"font-weight-light"},[_vm._v("united")])]),_c('v-spacer'),(_vm.$loggedIn)?[_c('v-toolbar-items',[_c('v-btn',{attrs:{"to":{ name: 'home' },"text":"","exact":""}},[_vm._v("Hjem")]),_c('v-menu',{attrs:{"close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Leads")])]}}],null,false,1190269017)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'leads') {
                  _vm.$router.push({ name: 'leads' })
                }}}},[_c('v-list-item-title',[_vm._v("Alle leads")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'unassigned-leads-count') {
                  _vm.$router.push({ name: 'unassigned-leads-count' })
                }}}},[_c('v-list-item-title',[_vm._v("Antall ufordelte leads")])],1)],1)],1),_c('v-btn',{attrs:{"to":{ name: 'baits' },"text":"","exact":""}},[_vm._v("Agn")]),_c('v-btn',{attrs:{"to":{ name: 'companies' },"text":"","exact":""}},[_vm._v("Bedrifter")]),_c('v-btn',{attrs:{"to":{ name: 'listings' },"text":"","exact":""}},[_vm._v("Bedriftsprofiler")]),_c('v-btn',{attrs:{"to":{ name: 'concepts' },"text":"","exact":""}},[_vm._v("Konsepter")]),_c('v-menu',{attrs:{"close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Administrasjon")])]}}],null,false,3375735308)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'websites') {
                  _vm.$router.push({ name: 'websites' })
                }}}},[_c('v-list-item-title',[_vm._v("Nettsteder")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'areas') {
                  _vm.$router.push({ name: 'areas' })
                }}}},[_c('v-list-item-title',[_vm._v("Områder")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'users') {
                  _vm.$router.push({ name: 'users' })
                }}}},[_c('v-list-item-title',[_vm._v("Brukere")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'permissions') {
                  _vm.$router.push({ name: 'permissions' })
                }}}},[_c('v-list-item-title',[_vm._v("Tillatelser")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'leadratings') {
                  _vm.$router.push({ name: 'leadratings' })
                }}}},[_c('v-list-item-title',[_vm._v("Leadklasser")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'leadtypes') {
                  _vm.$router.push({ name: 'leadtypes' })
                }}}},[_c('v-list-item-title',[_vm._v("Lead-typer")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'keys') {
                  _vm.$router.push({ name: 'keys' })
                }}}},[_c('v-list-item-title',[_vm._v("API-nøkler")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'emailtemplates') {
                  _vm.$router.push({ name: 'emailtemplates' })
                }}}},[_c('v-list-item-title',[_vm._v("Eposttemplates")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'pausereasons') {
                  _vm.$router.push({ name: 'pausereasons' })
                }}}},[_c('v-list-item-title',[_vm._v("Pausegrunner")])],1)],1)],1)],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'profile' }}},on),[_c('v-icon',[_vm._v("person")])],1)]}}],null,false,1913025346)},[_c('span',[_vm._v("Din profil")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.logOut}},on),[_c('v-icon',[_vm._v("exit_to_app")])],1)]}}],null,false,3351640511)},[_c('span',[_vm._v("Logg ut")])])],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }