import Lead from '@/models/Lead'

export default {
  data: () => {
    return {
      leadDialog: {
        show: false,
        lead: null,
      },
    }
  },
  methods: {
    showLeadDialog(lead = {}) {
      if (typeof lead != Lead) {
        lead = new Lead(lead)
      }
      this.leadDialog.lead = lead
      this.leadDialog.show = true
    },
    closeAndResetLeadDialog() {
      this.leadDialog.show = false
      this.leadDialog.lead = null
      this.leadDialog.showContentFieldAdder = false
      this.leadDialog.newContentField = null
    },
  },
}
