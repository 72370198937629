<template>
  <EntityDialog
    ref="entityDialog"
    v-bind="listingDialog"
    @entityCreated="handleEntityCreated"
    @entityUpdated="handleEntityUpdated"
  />
</template>

<script>
import EntityDialog from '@/components/dialogs/EntityDialog.vue'
import Company from '@/models/Company.js'
import Listing from '@/models/Listing.js'
import Website from '@/models/Website.js'

export default {
  name: 'ListingDialog',
  components: {
    EntityDialog,
  },
  mixins: [],
  props: {
    show: Boolean,
  },
  data: () => {
    return {
      loading: false,
      selectedEntity: null,
    }
  },
  computed: {
    listing() {
      return Listing.find(this.selectedEntity?.listing_id)
    },
    websites() {
      return Website.all()
    },
    listingDialog() {
      return {
        createTitle: 'Opprett ny bedriftsprofil',
        editTitle: 'Rediger bedriftsprofil',
        fields: [
          {
            name: 'company_id',
            type: 'lookupAutocomplete',
            label: 'Bedrift',
            disabled: this.selectedEntity?.company_id != null,
            item: this.selectedEntity?.company,
            itemValue: 'company_id',
            itemText: 'name',
            model: Company,
            rules: [this.$validationRules.required],
          },
          {
            name: 'website_id',
            type: 'select',
            label: 'Nettsted',
            items: this.websites,
            itemText: 'domainname',
            itemValue: 'website_id',
            rules: [this.$validationRules.required],
          },
          {
            name: 'url',
            type: 'text',
            label: 'URL (med https://)',
            rules: [this.$validationRules.required, this.$validationRules.url],
          },
        ],
      }
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.selectedEntity = instance
      this.$parent.$successToast(`Du opprettet bedriftsprofilen på ${instance.url}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$parent.$successToast(`Endringen av bedriftsprofilen på ${instance.url} er lagret`)
    },
    handleError(message) {
      this.$refs.entityDialog.showErrorAlert(message)
      this.loading = false
    },
    showDialog(listing) {
      this.selectedEntity = listing
      this.$refs.entityDialog.showDialog(Listing, listing)
    },
    closeDialog() {
      this.$refs.leadDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
