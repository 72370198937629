export default {
  data: () => {
    return {
      leadsIterator: {
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [10, 20, 40, 100, 200, 500],
        },
        options: {
          itemsPerPage: 20,
          page: 1,
        },
      },
    }
  },
}
