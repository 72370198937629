import BaseModel from '@/models/BaseModel'
import Conceptarea from '@/models/Conceptarea'

export default class Area extends BaseModel {
  static entity = 'areas'
  static primaryKey = 'area_id'

  static fields() {
    return {
      area_id: this.attr(null),
      parent_id: this.attr(null),
      name: this.string(),
      conceptareas: this.hasMany(Conceptarea, 'area_id', 'area_id'),
      parent: this.belongsTo(Area, 'parent_id', 'area_id'),
      childareas: this.hasMany(Area, 'parent_id'),
    }
  }
}
