<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Områder</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createAreaButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item v-for="area in areas" :key="area.area_id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ area.name }}
                </v-list-item-title>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateAreaButton"
                @click="$refs.entityDialog.showDialog(model, area)"
              />

              <TooltipButton
                v-bind="deleteAreaButton"
                @click="
                  getDeleteConfirmation(
                    area,
                    `Bekreft sletting av område ${area.name}`,
                    `Vil du virkelig slette området ${area.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet området ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/TooltipButton'
import Area from '@/models/Area'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data() {
    return {
      loading: false,
      model: Area,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett nytt område',
        editTitle: 'Rediger område',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'parent_id',
            type: 'select',
            label: 'Foreldreområde',
            items: this.areas,
            itemText: 'name',
            itemValue: 'area_id',
            clearable: true,
          },
        ],
      }
    },
    createAreaButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('AREA_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('AREA_CREATE')
            ? 'Opprett område'
            : 'Du mangler tillatelse til å opprette område',
        },
      }
    },
    updateAreaButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('AREA_UPDATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('AREA_UPDATE')
            ? 'Rediger område'
            : 'Du mangler tillatelse til å redigere område',
        },
      }
    },
    deleteAreaButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('AREA_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('AREA_DELETE')
            ? 'Slett område'
            : 'Du mangler tillatelse til å slette område',
        },
      }
    },
    areas() {
      let areas = Area.query()
        .orderBy('name')
        .get()

      areas.some(
        (item, index) => item.name == 'Hele Norge' && areas.unshift(areas.splice(index, 1)[0])
      )

      return areas
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Du opprettet området ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Endringen av området ${instance.name} er lagret`)
    },
  },
}
</script>
