import Pause from '@/models/Pause'

export default {
  data: () => {
    return {
      pauseDialog: {
        show: false,
        pause: null,
      },
    }
  },
  computed: {
    createPauseButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('PAUSE_CREATE')) || this.loading,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('PAUSE_CREATE')
              ? 'Legg til pause'
              : 'Du mangler tillatelse til å legge til pauser',
        },
      }
    },
    updatePauseButton() {
      return {
        button: {
          icon: 'edit',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('PAUSE_UPDATE')) || this.loading,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('PAUSE_UPDATE')
              ? 'Rediger pause'
              : 'Du mangler tillatelse til å redigere pauser',
        },
      }
    },
    deletePauseButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: 'Slett denne pausen',
        },
      }
    },
  },
  methods: {
    showPauseDialog(pause = {}) {
      if (typeof pause != Pause) {
        pause = new Pause(pause)
      }
      this.pauseDialog.pause = pause
      this.pauseDialog.show = true
    },
    closeAndResetPauseDialog() {
      this.pauseDialog.show = false
      this.pauseDialog.pause = null
    },
    handlePauseCreated(pause) {
      this.$refs.pauseDialog.entity = pause
      this.$parent.$successToast(
        `Du opprettet pausen ${this.formatDate(pause.from_timestamp.date)}`
      )
    },
    handlePauseUpdated(pause) {
      this.$refs.pauseDialog.entity = pause
      this.$successToast(
        `Endringen av pausen ${this.formatDate(pause.from_timestamp.date)} er lagret`
      )
      this.setNewPause(pause)
    },
    setNewPause(pause) {
      this.pauseDialog.pause = Pause.query()
        .where('pause_id', pause.pause_id)
        .with('user')
        .with('pausereason')
        .with('company')
        .first()
    },
  },
}
