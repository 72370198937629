import LeadCompany from '@/models/LeadCompany'
import Lead from '@/models/Lead'
import Company from '@/models/Company'
import Conceptarea from '@/models/Conceptarea'
import Concept from '@/models/Concept'
import Area from '@/models/Area'
import Emailtemplate from '@/models/Emailtemplate'

export default {
  data: () => {
    return {
      leadCompanyDialog: {
        show: false,
        leadCompany: null,
      },
    }
  },
  computed: {
    leadEmailtemplate() {
      return Emailtemplate.query()
        .where('name', 'lead')
        .first()
    },
    listingLeadEmailtemplate() {
      return Emailtemplate.query()
        .where('name', 'listing-lead')
        .first()
    },
  },
  methods: {
    showLeadCompanyDialog(leadCompany = {}) {
      if (typeof leadCompany != LeadCompany) {
        leadCompany = new LeadCompany(leadCompany)
      }

      this.leadCompanyDialog.leadCompany = leadCompany
      this.leadCompanyDialog.show = true
    },
    closeAndResetLeadCompanyDialog() {
      this.leadCompanyDialog.show = false
      this.leadCompanyDialog.leadCompany = null
    },
    async closeLeadCompanyDialogAndShowEmailDialog(leadCompany) {
      this.selectedLeadCompany = leadCompany
      const email = leadCompany.email
        ? leadCompany.email
        : this.generateLeadEmailContent(leadCompany)

      this.closeAndResetLeadCompanyDialog()
      this.showEmailDialog(email)
    },
    generateLeadEmailContent(leadCompany) {
      const lead = Lead.find(leadCompany.lead_id)
      const company = Company.find(leadCompany.company_id)

      let html = ''
      let subject = 'Nytt lead'

      if (lead.conceptarea_id) {
        const conceptArea = Conceptarea.find(lead.conceptarea_id)
        const concept = Concept.find(conceptArea.concept_id)
        const area = Area.find(conceptArea.area_id)

        subject = `${subject}: ${concept?.name} ${area?.name}`

        html = this.leadEmailtemplate?.generateHtml({
          area: area,
          concept: concept,
          lead: lead,
        })
      } else if (lead.listing_id) {
        html = this.listingLeadEmailtemplate?.generateHtml({
          lead: lead,
        })
      }

      return {
        user_id: this.$currentUser.user_id,
        subject: subject,
        content: html,
        sender: 'no-reply@leadly.no',
        recipient: company.emailaddress,
      }
    },
  },
}
