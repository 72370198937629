<template>
  <v-tooltip v-bind="tooltip">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          :icon="!button.text && !button.fab"
          :disabled="button.disabled"
          :color="button.color"
          :fab="button.fab"
          :small="button.small"
          @click="$emit('click')"
        >
          {{ button.text }}
          <v-icon>{{ button.icon }}</v-icon>
        </v-btn>
      </span>
    </template>
    {{ tooltip.text }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipButton',
  components: {},
  mixins: [],
  props: {
    tooltip: {
      type: Object,
      default: () => {
        return { openDelay: 500, left: false, bottom: true, text: 'Tekst' }
      },
    },
    button: {
      type: Object,
      default: function() {
        return {
          text: 'Tekst',
          icon: 'add',
          color: 'primary',
          disabled: false,
          fab: false,
          small: false,
        }
      },
    },
  },

  methods: {},
}
</script>
