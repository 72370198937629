var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$currentUser)?_c('v-container',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","offset-lg":"2"}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"success"}},[_c('v-toolbar-title',[_vm._v("Bedriftsprofiler")]),_c('v-spacer'),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.$refs.listingDialog.showDialog()}}},'TooltipButton',_vm.createListingButton,false))],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-data-table',_vm._b({attrs:{"items":_vm.listings,"item-key":"listing_id","loading":_vm.loading,"loading-text":"Henter data"},on:{"update:page":_vm.fetchData,"update:items-per-page":_vm.fetchData,"hook:mounted":_vm.fetchData},scopedSlots:_vm._u([{key:"item.company.name",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                name: 'company',
                params: { company_id: item.company.company_id },
              }}},[_vm._v(" "+_vm._s(item.company.name)+" ")]):_vm._e()]}},{key:"item.url",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(_vm._f("pathFromUrl")(item.url)))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.$refs.listingDialog.showDialog(item)}}},'TooltipButton',_vm.updateListingButton,false)),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.getDeleteConfirmation(
                  item,
                  ("Bekreft sletting av bedriftsprofil på " + (item.url)),
                  ("Vil du virkelig slette bedriftsprofilen på " + (item.url) + " for alltid?")
                )}}},'TooltipButton',_vm.deleteListingButton,false))]}}],null,false,2031087230)},'v-data-table',_vm.dataTable,false,true))],1)],1)],1),_c('ListingDialog',{ref:"listingDialog"}),_c('DeleteConfirmationDialog',_vm._b({ref:"deleteConfirmationDialog",on:{"cancel":_vm.closeAndResetDeleteConfirmationDialog,"success":function($event){return _vm.closeAndResetDeleteConfirmationDialog(
        ("Du slettet bedriftsprofilen på " + (_vm.deleteConfirmationDialog.entityToDelete.url))
      )}}},'DeleteConfirmationDialog',_vm.deleteConfirmationDialog,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }