<template>
  <BaseDialog v-if="email" ref="emailDialog" v-bind="baseDialog" @closeAndReset="closeDialog">
    <template slot="form">
      <strong v-if="email.sent_automatically" class="mb-4 d-block error--text">
        OBS: Denne eposten er allerede sendt ut fra systemet og kan ikke lenger endres.
      </strong>
      <strong v-if="email.sent_manually" class="mb-4 d-block error--text">
        OBS: Denne eposten er allerede markert som "Sendt manuelt".
      </strong>
      <v-form ref="form" autocomplete="off" class="mb-6">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="email.recipient"
              label="Til"
              :disabled="loading || email.sent_automatically"
              :rules="[$validationRules.required]"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="email.subject"
              label="Emne"
              :disabled="loading || email.sent_automatically"
              :rules="[$validationRules.required]"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="email.sent_timestamp"
              label="Sendt"
              :disabled="loading || email.sent_automatically"
              :rules="[$validationRules.required, $validationRules.datetime]"
            />
          </v-col>
        </v-row>
        <v-text-field
          v-model="comment"
          label="Kommentar"
          :disabled="loading || email.sent_automatically"
        />
      </v-form>

      <h1>Forhåndsvisning</h1>
      <div class="email-preview grey lighten-3 grey--text text--darken-4 pa-4 my-4">
        <template v-if="email.sent_timestamp">
          Sendt: {{ email.sent_timestamp | moment('DD.MM.YYYY HH:mm') }}
          <br />
        </template>
        Fra: {{ email.sender }}<br />
        Til: {{ email.recipient }}<br />
        Emne: {{ email.subject }}
        <!-- eslint-disable-next-line -->
        <div class="mt-6" v-html="$options.filters.sanitizeHtml(email.content)"/>
      </div>
      <v-btn
        class="d-inline-block"
        color="success"
        :disabled="
          loading ||
            email.sent_automatically ||
            !email.sender ||
            !email.recipient ||
            !email.subject ||
            !email.content
        "
        @click="sendEmailAutomatically(email)"
      >
        Send fra system<v-icon right>send</v-icon>
      </v-btn>
      <v-btn
        class="d-inline-block ml-6"
        color="primary"
        :disabled="
          loading ||
            email.sent_automatically ||
            !email.sender ||
            !email.recipient ||
            !email.subject ||
            !email.content
        "
        @click="sendEmailManually(email)"
      >
        <template>Send manuelt</template>
        <v-icon right>mark_email_read</v-icon>
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import debounce from 'debounce'

import entityPersistence from '@/mixins/entityPersistence.js'
import responseHelper from '@/mixins/responseHelper'
import BaseDialog from '@/components/dialogs/BaseDialog'
import Email from '@/models/Email'

export default {
  name: 'EmailDialog',
  components: {
    BaseDialog,
  },
  mixins: [entityPersistence, responseHelper],
  props: {
    show: Boolean,
    email: {
      type: Email,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      comment: null,
      emailContent: undefined,
    }
  },
  computed: {
    baseDialog() {
      return {
        show: this.show,
        entity: this.email,
        editTitle: this.email.sent_timestamp ? 'Sendt e-post' : 'Send e-post',
        createTitle: 'Opprett e-post',
        maxWidth: 1200,
        loading: this.loading,
      }
    },
  },
  watch: {
    comment: {
      handler: debounce(async function(comment) {
        if (!this.emailContent) {
          this.emailContent = this.email.content
        }
        this.email.content = `<p><em>${comment}</em></p>${this.emailContent}`
      }, 500),
    },
  },
  methods: {
    async sendEmailManually() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.email.sent_manually = true
      await this.createEntity()

      const text = this.$options.filters.htmlToTxt(this.email.content)
      let url = `mailto:${this.email.recipient}?subject=${
        this.email.subject
      }&body=${encodeURIComponent(text)}`
      window.open(url, '_blank')
      this.$emit('mailSent')
    },

    async createEntity() {
      this.$refs.emailDialog.clearErrorAlert()
      const savedEmail = await this.createOrUpdateEntity(Email, {
        user_id: this.email.user_id,
        subject: this.email.subject,
        content: this.email.content,
        sender: this.email.sender,
        recipient: this.email.recipient,
        sent_timestamp: this.email.sent_timestamp,
        sent_manually: this.email.sent_manually,
      })
      return savedEmail
    },

    async sendEmailAutomatically() {
      try {
        const savedEmail = await this.createOrUpdateEntity(
          Email,
          {
            user_id: this.email.user_id,
            subject: this.email.subject,
            content: this.email.content,
            sender: this.email.sender,
            recipient: this.email.recipient,
            sent_timestamp: this.email.sent_timestamp,
            sent_manually: this.email.sent_manually,
          },
          this.email.email_id
        )

        this.loading = true
        await savedEmail.send()

        await this.createOrUpdateEntity(
          Email,
          {
            sent_manually: false,
            sent_automatically: true,
          },
          this.email.email_id
        )

        this.loading = false
        this.$emit('mailSent')
      } catch (error) {
        this.handleError(error.message)
      }
    },

    handleError(message) {
      this.$refs.emailDialog.showErrorAlert(message)
      this.loading = false
    },

    closeDialog() {
      this.$refs.emailDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>

<style lang="less">
.email-preview {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
}
</style>
