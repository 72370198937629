import Conceptarea from '@/models/Conceptarea'

export default {
  data: () => {
    return {
      conceptareaDialog: {
        show: false,
        conceptarea: null,
      },
    }
  },
  methods: {
    showConceptareaDialog(conceptarea = {}) {
      if (typeof conceptarea != Conceptarea) {
        conceptarea = new Conceptarea(conceptarea)
      }

      this.conceptareaDialog.conceptarea = conceptarea
      this.conceptareaDialog.show = true
    },
    closeAndResetConceptareaDialog() {
      this.conceptareaDialog.show = false
      this.conceptareaDialog.conceptarea = null
    },
  },
}
