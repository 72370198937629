export default {
  data: () => {
    return {
      errorDialog: {
        show: false,
        errors: [],
      },
    }
  },
  methods: {
    clearErrorDialog() {
      this.errorDialog.errors = []
      this.errorDialog.show = false
    },

    showErrorDialog(message) {
      if (Array.isArray(message)) {
        this.errorDialog.errors.push(...message)
      } else if (typeof message == 'string') {
        this.errorDialog.errors.push(message)
      }
      this.errorDialog.show = true
    },

    handleErrorInDialog(message) {
      this.showErrorDialog(message)
      this.loading = false
    },
  },
}
