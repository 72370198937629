<template>
  <v-dialog v-model="show" width="450" persistent @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="headline primary white--text">
        <template>Legg til konseptområde</template>
        <v-spacer />
        <v-btn v-if="!loading" icon color="white" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36" />
      </v-card-title>

      <v-card-text>
        <v-form ref="form" autocomplete="off">
          <v-autocomplete
            v-model="selectedConcept"
            :items="concepts"
            label="Konsept"
            item-text="name"
            item-value="concept_id"
            return-object
            no-data-text="Ingen treff"
            :rules="[$validationRules.required]"
            :disabled="loading"
            @change="createCompanyConceptarea"
          />
          <v-autocomplete
            v-model="selectedArea"
            :items="areas"
            label="Område"
            item-text="name"
            item-value="area_id"
            return-object
            no-data-text="Ingen treff"
            :rules="[$validationRules.required]"
            :disabled="loading"
            @change="createCompanyConceptarea"
          />
        </v-form>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import responseHelper from '@/mixins/responseHelper'
import ErrorAlert from '@/components/ErrorAlert'
import Area from '@/models/Area'
import Concept from '@/models/Concept'
import Conceptarea from '@/models/Conceptarea'
import ConceptareaCompany from '@/models/ConceptareaCompany'

export default {
  name: 'ConceptareaCompanyDialog',
  components: {
    ErrorAlert,
  },
  mixins: [entityPersistence, errorAlert, responseHelper],
  props: {
    show: Boolean,
    companyId: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      selectedConcept: null,
      selectedArea: null,
    }
  },
  computed: {
    areas() {
      return Area.all()
    },
    concepts() {
      return Concept.query()
        .where('is_umbrella', false)
        .get()
    },
  },
  methods: {
    async createCompanyConceptarea() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      let conceptareaFetchResponse

      try {
        if (!this.selectedConcept?.concept_id || !this.selectedArea?.area_id) {
          throw new Error('Ugyldig konsept eller område')
        }

        conceptareaFetchResponse = await Conceptarea.fetchAll({
          search: {
            concept_id: this.selectedConcept.concept_id,
            area_id: this.selectedArea.area_id,
          },
        })
      } catch (e) {
        return this.handleError(['Det oppstod en feil ved søk etter konseptområdet.', e.message])
      }

      if (conceptareaFetchResponse.entities.conceptareas?.length !== 1) {
        return this.handleError([
          'Fant ikke konseptområdet.',
          `Konsept: ${this.selectedConcept.name}`,
          `Område: ${this.selectedArea.name}`,
        ])
      }

      const conceptarea = conceptareaFetchResponse.entities.conceptareas?.[0]

      await this.createOrUpdateEntity(ConceptareaCompany, {
        conceptarea_id: conceptarea.conceptarea_id,
        company_id: this.companyId,
      })

      this.closeDialog()
    },
    closeDialog() {
      this.selectedConcept = null
      this.selectedArea = null
      this.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
