<template>
  <div>
    <v-container v-if="$currentUser && conceptarea" class="pa-16">
      <h1 class="mb-16">
        <template v-if="conceptarea.concept.parent">
          {{ conceptarea.concept.parent.name }} >
        </template>
        {{ conceptarea.concept.name }} • {{ conceptarea.area.name }}
      </h1>

      <v-row class="justify-space-between">
        <v-col cols="12" lg="8">
          <LeadsIterator
            :conceptarea="conceptarea"
            :iterator-options="leadsIterator"
            :search="search"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <h2 class="mb-3">Bedrifter</h2>
          <v-progress-circular v-if="loading" indeterminate color="white" size="144" />
          <v-row v-else class="ma-lg-0">
            <v-col
              v-for="company in companies"
              :key="company.company_id"
              cols="12"
              sm="6"
              :md="companies.length >= 4 ? 4 : 12 / companies.length"
              lg="12"
              :class="{
                'mb-16': $vuetify.breakpoint.lgAndUp,
                'pa-0': $vuetify.breakpoint.lgAndUp,
              }"
            >
              <v-card class="primary text-center">
                <v-card-title class="justify-center pt-10">
                  {{ company.name }}
                </v-card-title>
                <v-card-subtitle class="pb-10 pt-0">
                  <div class="leadcount">
                    <span class="leadcount__count">
                      {{ company[leadCountInterval] }} lead{{
                        company[leadCountInterval] !== 1 ? 's' : ''
                      }}
                    </span>

                    <v-select
                      v-model="leadCountInterval"
                      class="leadcount__interval"
                      :items="leadCountIntervals"
                      dense
                      append-icon=""
                      hide-messages
                      hide-details
                    />
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-speed-dial
        v-if="
          $currentUser.hasPermission('CONCEPTAREA_DELETE') ||
            $currentUser.hasPermission('CONCEPTAREA_UPDATE')
        "
        v-model="fab"
        fixed
        bottom
        right
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" fab>
            <v-icon v-if="fab">close</v-icon>
            <v-icon v-else>settings</v-icon>
          </v-btn>
        </template>

        <v-btn
          v-if="$currentUser.hasPermission('CONCEPTAREA_UPDATE')"
          fab
          small
          color="success"
          @click="showConceptareaDialog(conceptarea)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="$currentUser.hasPermission('CONCEPTAREA_DELETE')"
          fab
          small
          color="error"
          @click="
            getDeleteConfirmation(
              conceptarea,
              `Bekreft sletting av konseptområde`,
              `Vil du virkelig slette dette konseptområdet for alltid?`
            )
          "
        >
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </v-speed-dial>

      <ConceptareaDialog
        v-bind="conceptareaDialog"
        @closeAndReset="closeAndResetConceptareaDialog"
      />

      <DeleteConfirmationDialog
        ref="deleteConfirmationDialog"
        v-bind="deleteConfirmationDialog"
        @cancel="closeAndResetDeleteConfirmationDialog"
        @success="closeAndResetDeleteConfirmationDialog(`Sletting var vellykket`)"
      />

      <ErrorDialog v-bind="errorDialog" @closeAndReset="clearErrorDialog" />
    </v-container>
  </div>
</template>

<script>
import color from '@/mixins/color'
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import leadsIterator from '@/mixins/leadsIterator'
import responseHelper from '@/mixins/responseHelper'
import conceptareaDialog from '@/mixins/dialogs/conceptareaDialog'
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog'
import errorDialog from '@/mixins/dialogs/errorDialog.js'
import Company from '@/models/Company'
import Conceptarea from '@/models/Conceptarea'
import ConceptareaCompany from '@/models/ConceptareaCompany'
import ConceptareaDialog from '@/components/dialogs/ConceptareaDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import ErrorDialog from '@/components/dialogs/ErrorDialog'
import LeadsIterator from '@/components/LeadsIterator'

export default {
  components: {
    ConceptareaDialog,
    DeleteConfirmationDialog,
    ErrorDialog,
    LeadsIterator,
  },
  mixins: [
    color,
    conceptareaDialog,
    deleteConfirmationDialog,
    entityPersistence,
    errorAlert,
    errorDialog,
    leadsIterator,
    responseHelper,
  ],
  data: () => {
    return {
      loading: false,
      entityToDelete: null,
      fab: false,
      leadCountInterval: 'lead_count_30_days',
      leadCountIntervals: [
        {
          text: 'siste 30 dager',
          value: 'lead_count_30_days',
        },
        {
          text: 'siste 90 dager',
          value: 'lead_count_90_days',
        },
        {
          text: 'siste 365 dager',
          value: 'lead_count_365_days',
        },
        {
          text: 'siden begynnelsen',
          value: 'lead_count_all',
        },
      ],
    }
  },
  computed: {
    conceptarea() {
      const conceptarea = Conceptarea.query()
        .whereId(parseInt(this.$route.params.conceptarea_id))
        .withAllRecursive()
        .first()
      return conceptarea
    },
    companies() {
      if (!this.conceptarea) return

      const companies = Company.query()
        .with('conceptareas')
        .whereHas('conceptareas', conceptarea => {
          conceptarea.where('conceptarea_id', this.conceptarea.conceptarea_id)
        })
        .get()
      return companies
    },
    search() {
      if (this.conceptarea?.conceptarea_id) {
        return [{ conceptarea_id: this.conceptarea.conceptarea_id }]
      }
      return []
    },
  },
  async mounted() {
    this.loading = true

    Conceptarea.fetchOne(parseInt(this.$route.params.conceptarea_id))

    let conceptareaCompanies

    try {
      conceptareaCompanies = await ConceptareaCompany.fetchByKey(
        'conceptarea_id',
        parseInt(this.$route.params.conceptarea_id)
      )
    } catch (err) {
      this.handleError([
        'Det skjedde en feil ved søk etter bedriftene i konseptområdet.',
        err.message,
      ])
      return
    }

    const companyIds = this.getIdsFromEntities(conceptareaCompanies, 'company_id')

    try {
      await Company.fetchBatch(companyIds)
    } catch (err) {
      this.handleError([
        'Det skjedde en feil ved innlasting av bedriftene i konseptområdet.',
        err.message,
      ])
      return
    }

    this.loading = false
  },
}
</script>

<style scoped lang="less">
.lead-company__name + .lead-company__name:before {
  content: ', ';
}

.leadcount {
  display: flex;
  gap: 0.5ch;
  align-items: baseline;
  justify-content: center;
  width: min-content;
  margin-left: auto;
  margin-right: auto;

  &__count {
    white-space: nowrap;
    color: #fff;
  }

  &__interval {
    font-size: 1em;
    width: min-content;

    .v-select__slot,
    .v-input__slot {
      width: auto;
    }

    .v-select__selection {
      max-width: none !important;
    }

    .v-select__selections input {
      display: none;
    }
  }
}
</style>

<style>
/* this needs to be unscoped for unknown reasons with vuetify */
.leadcount__interval .v-select__selections {
  color: #ffb74d !important;
}

.leadcount__interval .v-select__selection {
  max-width: none;
}
</style>
