import BaseModel from '@/models/BaseModel'

export default class Email extends BaseModel {
  static entity = 'emails'
  static primaryKey = 'email_id'

  static fields() {
    return {
      email_id: this.attr(null),
      user_id: this.attr(null),
      sent_timestamp: this.string().nullable(),
      subject: this.string(),
      content: this.string(),
      sender: this.string(),
      recipient: this.string(),
      sent_manually: this.boolean(false),
      sent_automatically: this.boolean(false),
    }
  }

  send(config = {}) {
    const url = `emails/${this.email_id}/send`
    return this.constructor.api().get(url, config)
  }
}
