import { Model } from '@vuex-orm/core'

export default class BaseModel extends Model {
  get primaryKey() {
    let primaryKey = { isComposite: false, key: {} }

    const keys = this.constructor.primaryKey

    if (Array.isArray(keys)) {
      primaryKey.isComposite = true

      keys.forEach(key => {
        primaryKey.key[key] = this[key]
      })
    } else {
      primaryKey.key[keys] = this[keys]
    }

    return primaryKey
  }

  static createOrUpdateOne(id, data = {}, config = {}) {
    if (id) {
      return this.updateOne(id, data, config)
    }
    return this.createOne(data, config)
  }

  static async createOne(data = {}, config = {}) {
    if (typeof data != 'object') {
      const transformedData = {}
      transformedData[this.dataField] = data
      data = transformedData
    }

    const url = `${this.entity}`
    const result = await this.api().post(url, data, config)
    return result.entities[this.entity][0]
  }

  static fetchAll(params = {}, config) {
    let searchQuery = ''

    // Ensure params.search is an array by spreading it into a new array
    const searchParameters = [].concat(params.search || [])

    searchParameters.forEach(searchParameter => {
      searchQuery = `${searchQuery}&q=${JSON.stringify(searchParameter)}`
    })

    const url = `${this.entity}?${searchQuery}`
    return this.api().get(url, config)
  }

  static fetchOne(id, deleteAll = false) {
    if (!id) return

    if (deleteAll) {
      this.deleteAll()
    }

    return this.api().get(`${this.entity}/${id}`)
  }

  static async fetchBatch(ids, deleteAll = true, config = {}) {
    if (!Array.isArray(ids) || !ids.length) {
      return
    }

    if (deleteAll) {
      this.deleteAll()
    }

    return this.api().get(`${this.entity}?ids=${ids.toString()}`, config)
  }

  static fetchByKey(key, id, deleteAll = true, config) {
    if (!key) return
    if (!id) return

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?${key}=${id}`
    return this.api().get(url, config)
  }

  static fetchBatchByKey(key, ids, deleteAll = true, config = {}) {
    if (key && ids) {
      if (deleteAll) {
        this.deleteAll()
      }
      const url = `${this.entity}?key=${key}&ids=${ids.toString()}`
      return this.api().get(url, config)
    }
  }

  static fetchPaginated(options, deleteAll = true, config = {}) {
    let searchQuery = ''

    // Ensure options.search is an array by spreading it into a new array
    const searchParameters = [].concat(options.search || [])

    searchParameters.forEach(searchParameter => {
      searchQuery = `${searchQuery}&q=${JSON.stringify(searchParameter)}`
    })

    let order = ''
    if (options.sortBy) {
      //make sure that we are trying to order by a valid field
      const matchingField = this.fields()[options.sortBy]
      if (
        matchingField &&
        (matchingField.constructor.name == 'String' || matchingField.constructor.name == 'Attr')
      ) {
        order = `&order=${options.sortBy}${options.sortDesc ? '.desc' : '.asc'}`
      }
    }

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?per_page=${options.pagination.itemsPerPage}&page=${options.pagination.page}${order}${searchQuery}`
    return this.api().get(url, { dataKey: 'data', ...config })
  }

  static async fetchBatchByFirstField(ids, deleteAll = true) {
    if (!ids) {
      return
    }

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?id1=${ids.toString()}`
    return this.api().get(url)
  }

  static async fetchBatchBySecondField(ids, deleteAll = true) {
    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?id2=${ids.toString()}`
    return this.api().get(url)
  }

  static async updateOne(id, data, config = {}) {
    if (!Number.isInteger(id) && this.primaryKey.length > 1) {
      id = id.replace('[', '')
      id = id.replace(']', '')
      id = id.replace(',', '/')
    }
    const url = `${this.entity}/${id}`
    const result = await this.api().put(url, data, config)
    return result.entities[this.entity][0]
  }

  static deleteOne(id) {
    let url = `${this.entity}`

    if (Number.isInteger(id)) {
      url = `${url}/${id}`
      return this.api().delete(url, { delete: id })
    }

    if (this.primaryKey.length > 1) {
      const compositeKey = []

      this.primaryKey.forEach(key => {
        compositeKey.push(id[key])
      })

      return this.api().delete(url, { params: id, delete: compositeKey })
    }
  }

  static deleteOnePivot(id1, id2) {
    const url = `${this.entity}/${id1}/${id2}`
    return this.api().delete(url, { delete: [id1, id2] })
  }

  delete() {
    const url = this.constructor.entity

    return this.constructor
      .api()
      .delete(url, { params: this.primaryKey.key, delete: JSON.parse(this.$id) })
  }
}
