export default {
  data: () => {
    return {
      baitDialog: {
        show: false,
        lead: null,
      },
    }
  },
  methods: {
    showBaitDialog(lead) {
      if (!lead) return

      this.baitDialog.lead = lead
      this.baitDialog.show = true
    },
    closeAndResetBaitDialog() {
      this.baitDialog.show = false
      this.baitDialog.lead = null
    },
  },
}
