import BaseModel from '@/models/BaseModel'
import Company from '@/models/Company'
import Lead from '@/models/Lead'
import Website from '@/models/Website'

export default class Listing extends BaseModel {
  static entity = 'listings'
  static primaryKey = 'listing_id'

  static fields() {
    return {
      listing_id: this.attr(null),
      website_id: this.number(),
      company_id: this.number(),
      url: this.string(),
      impression_count: this.number(),
      phonecall_count: this.number(),
      company: this.hasOne(Company, 'company_id', 'company_id'),
      website: this.hasOne(Website, 'website_id', 'website_id'),
      leads: this.hasMany(Lead, 'listing_id', 'listing_id'),
    }
  }
}
