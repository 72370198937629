import BaseModel from '@/models/BaseModel'
import { Liquid } from 'liquidjs'
const engine = new Liquid()

export default class Emailtemplate extends BaseModel {
  static entity = 'emailtemplates'
  static primaryKey = 'emailtemplate_id'

  static fields() {
    return {
      emailtemplate_id: this.attr(null),
      name: this.string(),
      description: this.string(),
      liquid: this.string(),
    }
  }

  generateHtml(input, { noReply = true, footer = true } = {}) {
    let template = this.liquid

    let html = engine.parseAndRenderSync(template, input)

    if (noReply) {
      html += getNoReply()
    }
    if (footer) {
      html += getFooter()
    }

    return html

    function getNoReply() {
      return `<hr>
              <p>
                <em>Denne e-posten kan ikke svares på.</em>
              </p>`
    }

    function getFooter() {
      return `
        <strong>LEADLY</strong><br>
        <a href="https://www.leadly.no/">www.leadly.no</a><br>
        <a href="mailto:kontakt@leadly.no">kontakt@leadly.no</a><br>
        <a href="tel:96006400">96 00 64 00</a>`
    }
  }
}
