import BaseModel from '@/models/BaseModel'
import Area from '@/models/Area'
import Concept from '@/models/Concept'
import Lead from '@/models/Lead'

export default class Conceptarea extends BaseModel {
  static entity = 'conceptareas'
  static primaryKey = 'conceptarea_id'

  static fields() {
    return {
      conceptarea_id: this.attr(null),
      concept_id: this.attr(null),
      area_id: this.attr(null),
      max_companies: this.number(null),
      max_companies_per_lead: this.number(null),
      companies_count: this.number(null),
      area: this.belongsTo(Area, 'area_id', 'area_id'),
      concept: this.belongsTo(Concept, 'concept_id', 'concept_id'),
      leads: this.hasMany(Lead, 'conceptarea_id', 'conceptarea_id'),
    }
  }

  static fetchAncestors(id, deleteAll = false, config = {}) {
    if (!id) return

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `/conceptareas/${id}/ancestors`

    return this.api().get(url, config)
  }
}
