export default {
  data: () => {
    return {
      conceptareaCompanyDialog: {
        show: false,
      },
    }
  },
  methods: {
    showConceptareaCompanyDialog(conceptareaCompany = {}) {
      this.conceptareaCompanyDialog.companyId = conceptareaCompany.company_id
      this.conceptareaCompanyDialog.show = true
    },
    closeAndResetConceptareaCompanyDialog() {
      this.conceptareaCompanyDialog.show = false
      this.conceptareaCompanyDialog.companyId = null
    },
  },
}
