import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import database from './database'

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token')

axios.interceptors.response.use(
  response => response,
  error => {
    handleError(error)
  }
)

export const setupStore = () => {
  VuexORM.use(VuexORMAxios, { axios })

  const store = new Vuex.Store({
    plugins: [VuexORM.install(database)],
  })

  return store
}

function handleError(error) {
  if (error.response.data && error.response.data.message) {
    throw Error(error.response.data.message)
  }
  throw Error(`${error.response.status} ${error.response.statusText}`)
}
