var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$currentUser && _vm.conceptarea)?_c('v-container',{staticClass:"pa-16"},[_c('h1',{staticClass:"mb-16"},[(_vm.conceptarea.concept.parent)?[_vm._v(" "+_vm._s(_vm.conceptarea.concept.parent.name)+" > ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.conceptarea.concept.name)+" • "+_vm._s(_vm.conceptarea.area.name)+" ")],2),_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('LeadsIterator',{attrs:{"conceptarea":_vm.conceptarea,"iterator-options":_vm.leadsIterator,"search":_vm.search}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Bedrifter")]),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"144"}}):_c('v-row',{staticClass:"ma-lg-0"},_vm._l((_vm.companies),function(company){return _c('v-col',{key:company.company_id,class:{
              'mb-16': _vm.$vuetify.breakpoint.lgAndUp,
              'pa-0': _vm.$vuetify.breakpoint.lgAndUp,
            },attrs:{"cols":"12","sm":"6","md":_vm.companies.length >= 4 ? 4 : 12 / _vm.companies.length,"lg":"12"}},[_c('v-card',{staticClass:"primary text-center"},[_c('v-card-title',{staticClass:"justify-center pt-10"},[_vm._v(" "+_vm._s(company.name)+" ")]),_c('v-card-subtitle',{staticClass:"pb-10 pt-0"},[_c('div',{staticClass:"leadcount"},[_c('span',{staticClass:"leadcount__count"},[_vm._v(" "+_vm._s(company[_vm.leadCountInterval])+" lead"+_vm._s(company[_vm.leadCountInterval] !== 1 ? 's' : '')+" ")]),_c('v-select',{staticClass:"leadcount__interval",attrs:{"items":_vm.leadCountIntervals,"dense":"","append-icon":"","hide-messages":"","hide-details":""},model:{value:(_vm.leadCountInterval),callback:function ($$v) {_vm.leadCountInterval=$$v},expression:"leadCountInterval"}})],1)])],1)],1)}),1)],1)],1),(
        _vm.$currentUser.hasPermission('CONCEPTAREA_DELETE') ||
          _vm.$currentUser.hasPermission('CONCEPTAREA_UPDATE')
      )?_c('v-speed-dial',{attrs:{"fixed":"","bottom":"","right":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v("close")]):_c('v-icon',[_vm._v("settings")])],1)]},proxy:true}],null,false,1289734696),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.$currentUser.hasPermission('CONCEPTAREA_UPDATE'))?_c('v-btn',{attrs:{"fab":"","small":"","color":"success"},on:{"click":function($event){return _vm.showConceptareaDialog(_vm.conceptarea)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(_vm.$currentUser.hasPermission('CONCEPTAREA_DELETE'))?_c('v-btn',{attrs:{"fab":"","small":"","color":"error"},on:{"click":function($event){return _vm.getDeleteConfirmation(
            _vm.conceptarea,
            "Bekreft sletting av konseptområde",
            "Vil du virkelig slette dette konseptområdet for alltid?"
          )}}},[_c('v-icon',[_vm._v("delete_forever")])],1):_vm._e()],1):_vm._e(),_c('ConceptareaDialog',_vm._b({on:{"closeAndReset":_vm.closeAndResetConceptareaDialog}},'ConceptareaDialog',_vm.conceptareaDialog,false)),_c('DeleteConfirmationDialog',_vm._b({ref:"deleteConfirmationDialog",on:{"cancel":_vm.closeAndResetDeleteConfirmationDialog,"success":function($event){return _vm.closeAndResetDeleteConfirmationDialog("Sletting var vellykket")}}},'DeleteConfirmationDialog',_vm.deleteConfirmationDialog,false)),_c('ErrorDialog',_vm._b({on:{"closeAndReset":_vm.clearErrorDialog}},'ErrorDialog',_vm.errorDialog,false))],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }