<template>
  <BaseDialog v-if="lead" ref="baitDialog" v-bind="baseDialog" @closeAndReset="closeDialog">
    <template slot="form">
      <v-form ref="form" autocomplete="off">
        <v-select
          v-model="lead.salesperson"
          :items="salespersons"
          label="Bruker"
          item-text="first_name"
          return-object
          clearable
          :disabled="loading || hasError"
          @change="handleSalespersonField"
        />
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import BaseDialog from '@/components/dialogs/BaseDialog'
import Lead from '@/models/Lead'
import Salesperson from '@/models/Salesperson'

export default {
  name: 'BaitDialog',
  components: {
    BaseDialog,
  },
  mixins: [entityPersistence],
  props: {
    show: Boolean,
    lead: {
      type: Lead,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      hasError: false,
    }
  },
  computed: {
    baseDialog() {
      return {
        show: this.show,
        entity: this.lead,
        editTitle: 'Tildel agn til selger',
        createTitle: 'Tildel agn til selger',
        maxWidth: 400,
        loading: this.loading,
      }
    },
    salespersons() {
      return Salesperson.query()
        .orderBy('first_name')
        .get()
    },
  },
  methods: {
    async handleSalespersonField() {
      if (!this.show) {
        return
      }

      this.$refs.baitDialog.clearErrorAlert()

      let salespersonId = null

      if (this.lead.salesperson?.salesperson_id) {
        salespersonId = this.lead.salesperson.salesperson_id
      }

      await this.createOrUpdateEntity(Lead, { salesperson_id: salespersonId }, this.lead.lead_id)
    },

    handleError(message) {
      this.$refs.baitDialog.showErrorAlert(message)
      this.lead.salesperson = null
      this.loading = false
    },

    closeDialog() {
      this.$refs.baitDialog.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
