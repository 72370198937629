export default {
  computed: {
    createListingButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('LISTING_CREATE')) ||
            this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('LISTING_CREATE')
              ? 'Opprett bedriftsprofil'
              : 'Du mangler tillatelse til å opprette bedriftsprofiler',
        },
      }
    },
    updateListingButton() {
      return {
        button: {
          icon: 'edit',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('LISTING_UPDATE')) ||
            this.loading,
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('LISTING_UPDATE')
              ? 'Rediger bedriftsprofil'
              : 'Du mangler tillatelse til å redigere bedriftsprofiler',
        },
      }
    },
    deleteListingButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('LISTING_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LISTING_DELETE')
            ? 'Slett bedriftsprofil'
            : 'Du mangler tillatelse til å slette bedriftsprofiler',
        },
      }
    },
  },
}
