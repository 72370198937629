import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home.vue'
import Apikeys from '@/views/Apikeys.vue'
import Baits from '@/views/Baits.vue'
import Areas from '@/views/Areas.vue'
import Companies from '@/views/Companies.vue'
import Company from '@/views/Company.vue'
import Concept from '@/views/Concept.vue'
import Conceptarea from '@/views/Conceptarea.vue'
import Concepts from '@/views/Concepts.vue'
import Emailtemplates from '@/views/Emailtemplates.vue'
import Leadratings from '@/views/Leadratings.vue'
import Leads from '@/views/Leads.vue'
import Leadtypes from '@/views/Leadtypes.vue'
import Listings from '@/views/Listings.vue'
import Pausereasons from '@/views/Pausereasons.vue'
import Permissions from '@/views/Permissions.vue'
import Profile from '@/views/Profile.vue'
import UnassignedLeadsCount from '@/views/UnassignedLeadsCount.vue'
import Salespersons from '@/views/Salespersons.vue'
import Users from '@/views/Users.vue'
import Websites from '@/views/Websites.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/baits',
      name: 'baits',
      component: Baits,
    },
    {
      path: '/keys',
      name: 'keys',
      component: Apikeys,
    },
    {
      path: '/areas',
      name: 'areas',
      component: Areas,
    },
    {
      path: '/companies',
      name: 'companies',
      component: Companies,
    },
    {
      path: '/companies/:company_id',
      name: 'company',
      component: Company,
    },
    {
      path: '/conceptareas/:conceptarea_id',
      name: 'conceptarea',
      component: Conceptarea,
    },
    {
      path: '/concepts',
      name: 'concepts',
      component: Concepts,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/concepts/:concept_id',
      name: 'concept',
      component: Concept,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/emailtemplates',
      name: 'emailtemplates',
      component: Emailtemplates,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/leads',
      name: 'leads',
      component: Leads,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/leads/unassigned/count',
      name: 'unassigned-leads-count',
      component: UnassignedLeadsCount,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/leadtypes',
      name: 'leadtypes',
      component: Leadtypes,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/leadratings',
      name: 'leadratings',
      component: Leadratings,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/listings',
      name: 'listings',
      component: Listings,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/pausereasons',
      name: 'pausereasons',
      component: Pausereasons,
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: Permissions,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/salespersons',
      name: 'salespersons',
      component: Salespersons,
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/websites',
      name: 'websites',
      component: Websites,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  Vue.prototype.$loggedIn = false

  try {
    const expires = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).exp

    if (expires > Date.now() / 1000) {
      Vue.prototype.$loggedIn = true
    }
  } catch (err) {
    console.error('Auth token missing or invalid')
  }

  next()
})

export default router
