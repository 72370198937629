<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Eposttemplates</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createEmailtemplateButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="!emailtemplates" indeterminate></v-progress-linear>
          <v-list>
            <v-list-item
              v-for="emailtemplate in emailtemplates"
              :key="emailtemplate.emailtemplate_id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ emailtemplate.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ emailtemplate.description }}</v-list-item-subtitle>
                <div class="text-caption">
                  {{ emailtemplate.liquid | truncateString(100, true) }}
                </div>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateEmailtemplateButton"
                @click="$refs.entityDialog.showDialog(model, emailtemplate)"
              />
              <TooltipButton
                v-bind="deleteEmailtemplateButton"
                @click="
                  getDeleteConfirmation(
                    emailtemplate,
                    `Bekreft sletting av eposttemplate ${emailtemplate.name}`,
                    `Vil du virkelig slette eposttemplatet ${emailtemplate.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet eposttemplatet ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EntityDialog from '@/components/dialogs/EntityDialog'
import TooltipButton from '@/components/TooltipButton'
import Emailtemplate from '@/models/Emailtemplate'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data: () => {
    return {
      loading: false,
      model: Emailtemplate,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett nytt eposttemplate',
        editTitle: 'Rediger eposttemplate',
        maxWidth: 1080,
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'description',
            type: 'textarea',
            label: 'Beskrivelse',
            rules: [this.$validationRules.required],
          },
          {
            name: 'liquid',
            type: 'textarea',
            label: 'Liquid template',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createEmailtemplateButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('EMAILTEMPLATE_CREATE'),
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('EMAILTEMPLATE_CREATE')
            ? 'Opprett eposttemplate'
            : 'Du mangler tillatelse til å opprette eposttemplates',
        },
      }
    },
    updateEmailtemplateButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('EMAILTEMPLATE_UPDATE'),
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('EMAILTEMPLATE_UPDATE')
            ? 'Rediger eposttemplate'
            : 'Du mangler tillatelse til å redigere eposttemplates',
        },
      }
    },
    deleteEmailtemplateButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('EMAILTEMPLATE_DELETE'),
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('EMAILTEMPLATE_DELETE')
            ? 'Slett eposttemplate'
            : 'Du mangler tillatelse til å slette eposttemplates',
        },
      }
    },
    emailtemplates() {
      let emailtemplates = Emailtemplate.query()
        .orderBy('name')
        .get()
      return emailtemplates
    },
  },
  methods: {
    handleEntityCreated(emailtemplate) {
      this.$refs.entityDialog.emailtemplate = emailtemplate
      this.$successToast(`Du opprettet eposttemplatet ${emailtemplate.name}`)
    },
    handleEntityUpdated(emailtemplate) {
      this.$refs.entityDialog.emailtemplate = emailtemplate
      this.$successToast(`Endringen av eposttemplatet ${emailtemplate.name} er lagret`)
    },
  },
}
</script>
