import BaseModel from './BaseModel'
import User from './User'
import UserPermission from './UserPermission'
import Apikey from './Apikey'
import ApikeyPermission from './ApikeyPermission'

export default class Permission extends BaseModel {
  static entity = 'permissions'
  static primaryKey = 'permission_id'

  static fields() {
    return {
      permission_id: this.attr(null),
      name: this.string(),
      users: this.belongsToMany(User, UserPermission, 'permission_id', 'user_id'),
      apikeys: this.belongsToMany(Apikey, ApikeyPermission, 'permission_id', 'apikey_id'),
    }
  }
}
