<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="4" offset-md="2" offset-lg="4">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Lead-typer</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createLeadtypeButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="!leadtypes" indeterminate></v-progress-linear>
          <v-list>
            <v-list-item v-for="leadtype in leadtypes" :key="leadtype.leadtype_id">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip label :color="leadtype.color" class="px-2 mr-2">
                    <v-icon :color="`${isDark(leadtype.color) ? 'white' : 'black'}`">{{
                      leadtype.icon
                    }}</v-icon>
                  </v-chip>
                  {{ leadtype.name }}
                </v-list-item-title>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateLeadtypeButton"
                @click="$refs.entityDialog.showDialog(model, leadtype)"
              />
              <TooltipButton
                v-bind="deleteLeadtypeButton"
                @click="
                  getDeleteConfirmation(
                    leadtype,
                    `Bekreft sletting av lead-type ${leadtype.name}`,
                    `Vil du virkelig slette lead-typen ${leadtype.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet lead-typen ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import color from '@/mixins/color'
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EntityDialog from '@/components/dialogs/EntityDialog'
import TooltipButton from '@/components/TooltipButton'
import Leadtype from '@/models/Leadtype'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [color, deleteConfirmationDialog],
  data: () => {
    return {
      loading: false,
      model: Leadtype,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny lead-type',
        editTitle: 'Rediger lead-type',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'color',
            type: 'color',
            label: 'Farge',
            rules: [this.$validationRules.required],
          },
          {
            name: 'icon',
            type: 'text',
            label: 'Ikon',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createLeadtypeButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('LEADTYPE_CREATE'),
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEADTYPE_CREATE')
            ? 'Opprett lead-type'
            : 'Du mangler tillatelse til å opprette lead-type',
        },
      }
    },
    updateLeadtypeButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('LEADTYPE_UPDATE'),
          color: this.$vuetify.theme.dark ? 'white' : 'secondary',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEADTYPE_UPDATE')
            ? 'Rediger lead-type'
            : 'Du mangler tillatelse til å redigere lead-type',
        },
      }
    },
    deleteLeadtypeButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('LEADTYPE_DELETE'),
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('LEADTYPE_DELETE')
            ? 'Slett lead-type'
            : 'Du mangler tillatelse til å slette lead-type',
        },
      }
    },
    leadtypes() {
      let leadtypes = Leadtype.query()
        .orderBy('name')
        .get()
      return leadtypes
    },
  },
  methods: {
    handleEntityCreated(leadtype) {
      this.$refs.entityDialog.leadtype = leadtype
      this.$successToast(`Du opprettet lead-typen ${leadtype.name}`)
    },
    handleEntityUpdated(leadtype) {
      this.$refs.entityDialog.leadtype = leadtype
      this.$successToast(`Endringen av lead-typen ${leadtype.name} er lagret`)
    },
  },
}
</script>
