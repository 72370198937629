import BaseModel from './BaseModel'

export default class Salesperson extends BaseModel {
  static entity = 'salespersons'
  static primaryKey = 'salesperson_id'

  static fields() {
    return {
      salesperson_id: this.attr(null),
      first_name: this.string(),
      last_name: this.string(),
      phone: this.string(),
      email: this.string(),
    }
  }

  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }
}
