<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>API-nøkler</v-toolbar-title>
            <v-spacer />
            <TooltipButton v-bind="createApikeyButton" @click="showApikeyDialog" />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item v-for="apikey in apikeys" :key="apikey.apikey_id">
              <v-list-item-content :class="`${apikey.disabled ? 'apikey--disabled' : ''}`">
                <v-list-item-title>
                  {{ apikey.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <strong>ID:</strong> {{ apikey.apikey_id }} •
                  <strong>Rettigheter:</strong>
                  <span v-for="permission in apikey.permissions" :key="permission.permission_id">
                    {{ permission.name }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <TooltipButton
                :button="{
                  icon: 'edit',
                  disabled:
                    !$currentUser.hasPermission('APIKEY_UPDATE') || loading || apikey.disabled,
                  color: 'white',
                }"
                :tooltip="{
                  openDelay: 500,
                  bottom: true,
                  text: $currentUser.hasPermission('APIKEY_UPDATE')
                    ? 'Rediger API-nøkkel'
                    : 'Du mangler tillatelse til å redigere API-nøkler',
                }"
                @click="showApikeyDialog(apikey)"
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <ApikeyDialog
      v-bind="apikeyDialog"
      @entityCreated="handleApikeyCreated"
      @entityUpdated="handleApikeyUpdated"
      @closeAndReset="closeAndResetApikeyDialog"
    />
  </v-container>
</template>

<script>
import { randomBytes } from 'crypto'
import ApikeyDialog from '@/components/dialogs/ApikeyDialog'
import TooltipButton from '@/components/TooltipButton'
import Apikey from '@/models/Apikey'
import ApikeyPermission from '@/models/ApikeyPermission'

export default {
  components: {
    ApikeyDialog,
    TooltipButton,
  },
  data() {
    return {
      loading: false,
      apikeyDialog: {
        show: false,
        apikey: null,
      },
    }
  },
  computed: {
    createApikeyButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('APIKEY_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('APIKEY_CREATE')
            ? 'Opprett API-nøkkel'
            : 'Du mangler tillatelse til å opprette API-nøkler',
        },
      }
    },
    apikeys() {
      return Apikey.query()
        .with('permissions')
        .orderBy('name')
        .get()
    },
  },
  async created() {
    try {
      await Promise.all([Apikey.fetchAll(), ApikeyPermission.fetchAll()])
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
  },
  methods: {
    handleApikeyCreated(apikey) {
      this.apikeyDialog.apikey = Apikey.query()
        .with('permissions')
        .where('apikey_id', apikey.apikey_id)
        .first()

      this.$successToast(`Du opprettet API-nøkkelen ${apikey.name}`)
    },
    handleApikeyUpdated(apikey) {
      this.apikeyDialog.apikey = Apikey.query()
        .with('permissions')
        .where('apikey_id', apikey.apikey_id)
        .first()

      this.$successToast(`Endringen av API-nøkkelen ${apikey.name} er lagret`)
    },
    showApikeyDialog(apikey = new Apikey({})) {
      if (apikey.apikey === undefined) {
        apikey.apikey = randomBytes(24).toString('hex')
      }

      this.apikeyDialog.apikey = apikey
      this.apikeyDialog.show = true
    },
    closeAndResetApikeyDialog() {
      this.apikeyDialog.show = false
      this.apikeyDialog.apikey = null
    },
  },
}
</script>

<style lang="less" scoped>
.apikey--disabled {
  text-decoration: line-through;
  filter: brightness(0.5);
}
</style>
