<template>
  <v-dialog v-if="conceptarea" value="show" persistent max-width="400px" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="headline primary white--text">
        {{ conceptarea.conceptarea_id ? 'Rediger konseptområde' : 'Opprett nytt konseptområde' }}
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" icon color="white" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" autocomplete="off">
          <v-select
            v-if="!(conceptarea.conceptarea_id && conceptarea.area_id)"
            v-model="conceptarea.area_id"
            :items="areas"
            :rules="[$validationRules.required]"
            item-text="name"
            item-value="area_id"
            label="Område"
            :disabled="loading"
            no-data-text="Du må opprette nytt område under Administrasjon > Områder."
            @change="handleAreaField"
          />
          <v-text-field
            v-model="conceptarea.max_companies"
            class="mb-6"
            label="Max antall bedrifter"
            hint="0 = ubegrenset"
            persistent-hint
            type="number"
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleMaxCompaniesField"
            @keyup.enter="handleMaxCompaniesField"
          />
          <v-text-field
            v-model="conceptarea.max_companies_per_lead"
            label="Bedrifter per lead"
            hint="0 = ubegrenset"
            persistent-hint
            type="number"
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleMaxCompaniesPerLeadField"
            @keyup.enter="handleMaxCompaniesPerLeadField"
          />
        </v-form>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import responseHelper from '@/mixins/responseHelper'
import ErrorAlert from '@/components/ErrorAlert'
import Area from '@/models/Area'
import Conceptarea from '@/models/Conceptarea'

export default {
  name: 'ConceptareaDialog',
  components: {
    ErrorAlert,
  },
  mixins: [entityPersistence, errorAlert, responseHelper],
  props: {
    show: Boolean,
    conceptarea: {
      type: Conceptarea,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
    }
  },
  computed: {
    entity() {
      return this.conceptarea
    },
    areas() {
      return Area.query()
        .whereHasNot('conceptareas', query => {
          query.where('concept_id', this.conceptarea.concept_id)
        })
        .get()
    },
    conceptareas() {
      return Conceptarea.query()
        .with('area')
        .where('$id', id => id != this.conceptarea.conceptarea_id)
        .where('concept_id', this.conceptarea.concept_id)
        .get()
    },
  },
  methods: {
    async handleAreaField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.conceptarea.conceptarea_id) {
        await this.createOrUpdateEntity(
          Conceptarea,
          { area_id: this.conceptarea.area_id },
          this.conceptarea.conceptarea_id
        )
      } else {
        await this.createEntity()
      }
    },

    async handleMaxCompaniesField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.conceptarea.conceptarea_id) {
        await this.createOrUpdateEntity(
          Conceptarea,
          { max_companies: this.conceptarea.max_companies },
          this.conceptarea.conceptarea_id
        )
      } else {
        await this.createEntity()
      }
    },

    async handleMaxCompaniesPerLeadField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (this.conceptarea.conceptarea_id) {
        await this.createOrUpdateEntity(
          Conceptarea,
          { max_companies_per_lead: this.conceptarea.max_companies_per_lead },
          this.conceptarea.conceptarea_id
        )
      } else {
        await this.createEntity()
      }
    },

    async createEntity() {
      this.clearErrorAlert()
      await this.createOrUpdateEntity(Conceptarea, {
        concept_id: this.conceptarea.concept_id,
        area_id: this.conceptarea.area_id,
        max_companies: this.conceptarea.max_companies,
        max_companies_per_lead: this.conceptarea.max_companies_per_lead,
      })
    },

    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },

    closeDialog() {
      this.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
