<template>
  <div>
    <v-container v-if="$currentUser && company" class="pa-16">
      <h1 class="mb-8">{{ company.name }}</h1>
      <v-row class="justify-space-between mb-2">
        <!-- leads -->
        <v-col cols="12" lg="5">
          <v-data-iterator
            :items="leads"
            item-key="lead_id"
            :loading="loading"
            v-bind.sync="leadsIterator"
            loading-text="Oppdaterer"
            no-data-text="Fant ingen leads"
            @update:page="fetchData"
            @update:items-per-page="fetchData"
            @hook:mounted="fetchData"
          >
            <template v-slot:default="{ items, isExpanded, expand }">
              <v-card v-for="lead in items" :key="lead.lead_id" class="mb-12">
                <v-card-title
                  :style="`background: ${lead.leadtype.color};`"
                  :class="
                    `${isDark(lead.leadtype.color) ? 'white--text' : 'grey--text text--darken-4'}`
                  "
                >
                  <v-row class="justify-space-between">
                    <v-col cols="11">
                      <v-icon
                        class="mr-2"
                        :color="`${isDark(lead.leadtype.color) ? 'white' : 'grey darken-4'}`"
                      >
                        {{ lead.leadtype.icon }}
                      </v-icon>
                      <template v-if="lead.conceptarea">
                        {{ lead.conceptarea.concept.name }}
                        {{ lead.conceptarea.area.name }}</template
                      ><template v-if="lead.conceptarea && lead.content.Navn">: </template>
                      <template v-if="lead.content.Navn">
                        {{ lead.content.Navn }}
                      </template>
                      <span class="text-caption">
                        {{ lead.created_timestamp.date | moment('DD.MM.YYYY') }}
                      </span>
                    </v-col>
                    <v-col cols="1" class="text-right">
                      <TooltipButton
                        v-bind="createLeadCompanyButton"
                        @click="showLeadCompanyDialog({ lead_id: lead.lead_id })"
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text
                  :class="`${$currentUser.pref_dark_mode ? 'grey--text text--lighten-3' : ''}`"
                  class="pt-4"
                >
                  <v-row v-for="[key, value] in Object.entries(lead.content)" :key="key">
                    <v-col
                      cols="12"
                      sm="4"
                      xl="3"
                      class="pt-0"
                      :class="{
                        'pb-0': $vuetify.breakpoint.xs,
                        'pb-1': $vuetify.breakpoint.smAndUp,
                      }"
                    >
                      <strong>{{ key | capitalizeFirstLetter }}:</strong>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                      xl="9"
                      class="pt-0"
                      :class="{
                        'pb-3': $vuetify.breakpoint.xs,
                        'pb-1': $vuetify.breakpoint.smAndUp,
                      }"
                    >
                      <template v-if="isExpanded(lead)">
                        <span class="pre-formatted">{{ value }}</span>
                      </template>
                      <template v-else>
                        {{ value | truncateString(EXPAND_THRESHOLD, true) }}
                      </template>
                    </v-col>
                  </v-row>
                  <v-btn
                    v-if="hasStringLongerThan(lead.content, EXPAND_THRESHOLD)"
                    fab
                    absolute
                    bottom
                    right
                    small
                    color="grey darken-3"
                    @click="expand(lead, !isExpanded(lead))"
                  >
                    <v-icon color="white">
                      {{ isExpanded(lead) ? 'expand_less' : 'expand_more' }}
                    </v-icon>
                  </v-btn>
                </v-card-text>
                <v-card-text
                  v-if="isExpanded(lead) && lead.leadCompanies.length"
                  :class="
                    `${
                      $currentUser.pref_dark_mode
                        ? 'blue-grey darken-4 grey--text text--lighten-3'
                        : 'grey lighten-3'
                    }`
                  "
                >
                </v-card-text>
              </v-card>
            </template>
          </v-data-iterator>
        </v-col>
        <v-col cols="12" lg="4">
          <!-- bedriftsdetaljer -->
          <v-card class="mb-8">
            <v-card-title class="justify-space-between primary white--text">
              Bedriftsdetaljer
              <TooltipButton
                v-bind="updateCompanyButton"
                @click="$refs.companyDialog.showDialog(company)"
              />
            </v-card-title>
            <v-card-text class="pa-4">
              <dl>
                <div class="d-sm-flex justify-space-between mb-2">
                  <dt>Status</dt>
                  <dd v-if="company.is_paying_customer && company.is_active_customer">
                    Aktiv kunde
                  </dd>
                  <dd v-if="company.is_paying_customer && !company.is_active_customer">
                    Tidligere kunde
                  </dd>
                  <dd v-if="!company.is_paying_customer">Prospekt</dd>
                </div>
                <div class="d-sm-flex justify-space-between mb-2">
                  <dt>Org.nr</dt>
                  <dd>
                    <a
                      :href="`https://proff.no/bransjes%C3%B8k?q=${company.orgnr}`"
                      target="_blank"
                    >
                      {{ company.orgnr | orgnr }}
                    </a>
                  </dd>
                </div>
                <div class="d-sm-flex justify-space-between mb-2">
                  <dt>Kontaktperson</dt>
                  <dd>
                    <a
                      :href="`https://proff.no/rolles%C3%B8k?q=${company.contactperson}`"
                      target="_blank"
                    >
                      {{ company.contactperson }}
                    </a>
                  </dd>
                </div>
                <div class="d-sm-flex justify-space-between mb-2">
                  <dt>E-post</dt>
                  <dd>
                    <a :href="`mailto:${company.emailaddress}`">{{ company.emailaddress }}</a>
                  </dd>
                </div>
                <div class="d-sm-flex justify-space-between">
                  <dt>Telefon</dt>
                  <dd>
                    <a :href="`tel:${company.phonenumber}`">
                      {{ company.phonenumber | phone }}
                    </a>
                  </dd>
                </div>
              </dl>
            </v-card-text>
          </v-card>
          <!-- bedriftsprofiler -->
          <v-card class="mb-8">
            <v-card-title class="justify-space-between primary white--text">
              Bedriftsprofiler
              <TooltipButton
                v-bind="createListingButton"
                @click="
                  $refs.listingDialog.showDialog({
                    company_id: company.company_id,
                    company: company,
                  })
                "
              />
            </v-card-title>
            <div v-if="Object.keys(websites).length" class="websites pa-4">
              <div v-for="(listings, website) in websites" :key="website">
                <h3>{{ website }}</h3>
                <v-simple-table class="listings">
                  <thead>
                    <tr>
                      <th>URL</th>
                      <th>Leads</th>
                      <th>Telefoner</th>
                      <th>Visninger</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="listing in listings" :key="listing.listing_id" class="listing">
                      <td class="listing__url">
                        <a :href="listing.url" target="_blank">{{ listing.url | pathFromUrl }}</a>
                      </td>
                      <td>{{ listing.leads.length }}</td>
                      <td>{{ listing.phonecall_count }}</td>
                      <td>{{ listing.impression_count }}</td>
                      <td class="listing__actions">
                        <TooltipButton
                          v-bind="updateListingButton"
                          @click="$refs.listingDialog.showDialog(listing)"
                        />
                        <TooltipButton
                          v-bind="deleteListingButton"
                          @click="
                            getDeleteConfirmation(
                              listing,
                              `Bekreft sletting av bedriftsprofil på ${listing.url}`,
                              `Vil du virkelig slette bedriftsprofilen på ${listing.url} for alltid?`
                            )
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div v-for="listing in listings" :key="listing.listing_id" class="listings">
                  <div class="listing__details d-flex justify-space-between align-center"></div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card class="mb-8">
            <v-card-title class="primary pb-5 white--text">
              Antall leads
            </v-card-title>
            <v-card-text class="pa-4">
              <p>
                {{ company.lead_count_30_days }}
                lead{{ company.lead_count_30_days !== 1 ? 's' : '' }}
                de siste 30 dagene
              </p>
              <p>
                {{ company.lead_count_90_days }}
                lead{{ company.lead_count_90_days !== 1 ? 's' : '' }}
                de siste 90 dagene
              </p>
              <p>
                {{ company.lead_count_365_days }}
                lead{{ company.lead_count_365_days !== 1 ? 's' : '' }}
                de siste 365 dagene
              </p>
              <p class="mb-0">
                {{ company.lead_count_all }}
                lead{{ company.lead_count_all !== 1 ? 's' : '' }}
                siden starten
              </p>
            </v-card-text>
          </v-card>
          <v-card class="mb-8">
            <v-card-title
              class="justify-space-between primary white--text"
              :class="getPausesTitleClass"
            >
              Pauser
              <TooltipButton
                v-bind="createPauseButton"
                @click="
                  showPauseDialog({
                    company_id: company.company_id,
                    user_id: $currentUser.user_id,
                    from_timestamp: {
                      date: getCurrentDate(),
                      time: getCurrentTime(),
                    },
                  })
                "
              />
            </v-card-title>
            <v-card-text v-if="company.pauses.length" class="pa-4">
              <div
                v-for="pause in company.pauses"
                :key="pause.pause_id"
                class="pause d-flex"
                :class="isBeforeCurrentTime(pause.to_timestamp) ? 'pause--over' : ''"
              >
                <div class="pause__content">
                  <div class="pause__time d-flex mb-1">
                    <span class="pause__from">
                      {{ pause.from_timestamp.date | moment('DD.MM.YYYY') }}
                    </span>
                    <span class="pause__to">
                      <template v-if="pause.to_timestamp.date">{{
                        pause.to_timestamp.date | moment('DD.MM.YYYY')
                      }}</template>
                      <template v-else>Inntil videre</template>
                    </span>
                  </div>
                  <p v-if="pause.comment" class="pause__comment mb-2">
                    {{ pause.comment }}
                  </p>
                  <v-chip small color="primary"
                    >{{ pause.pausereason.name }} - {{ pause.user.first_name }}</v-chip
                  >
                </div>
                <div class="pause__actions">
                  <TooltipButton v-bind="updatePauseButton" @click="showPauseDialog(pause)" />
                  <TooltipButton
                    v-bind="deletePauseButton"
                    @click="
                      getDeleteConfirmation(
                        pause,
                        `Bekreft sletting av pause ${formatDate(pause.from_timestamp.date)} fra ${
                          company.name
                        }`,
                        `Vil du virkelig slette pausen ${formatDate(
                          pause.from_timestamp.date
                        )} fra ${company.name} for alltid?`
                      )
                    "
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>

          <h2 class="mb-3">
            Konseptområder
            <TooltipButton
              v-bind="createConceptareaCompanyButton"
              @click="
                showConceptareaCompanyDialog({
                  company_id: company.company_id,
                })
              "
            />
          </h2>
          <v-card v-for="concept in concepts" :key="concept.concept_id" class="mb-8">
            <v-card-title class="justify-center success white--text">
              {{ concept.name }}
            </v-card-title>
            <v-card-text class="pa-4">
              <div
                v-for="area in areasByConcept[concept.concept_id]"
                :key="area.area_id"
                class="d-flex justify-space-between align-center"
              >
                <span>{{ area.name }}</span>
                <TooltipButton
                  v-bind="deleteConceptareaCompanyButton"
                  @click="deleteConceptareaCompany(concept.concept_id, area.area_id)"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <CompanyDialog ref="companyDialog" />

    <ListingDialog ref="listingDialog" />

    <PauseDialog
      ref="pauseDialog"
      v-bind="pauseDialog"
      @closeAndReset="closeAndResetPauseDialog"
      @entityCreated="setNewPause"
      @entityUpdated="handlePauseUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="closeAndResetDeleteConfirmationDialog('Slettingen er fullført')"
    />

    <ConceptareaCompanyDialog
      v-bind="conceptareaCompanyDialog"
      @closeAndReset="closeAndResetConceptareaCompanyDialog"
    />

    <LeadCompanyDialog
      v-bind="leadCompanyDialog"
      @closeAndReset="closeAndResetLeadCompanyDialog"
      @entityCreated="closeLeadCompanyDialogAndShowEmailDialog"
    />

    <EmailDialog
      ref="emailDialog"
      v-bind="emailDialog"
      @entityCreated="setNewEmailAndUpdateLeadCompany"
      @closeAndReset="closeAndResetEmailDialog"
      @mailSent="closeAndResetEmailDialog"
    />
  </div>
</template>

<script>
import CompanyDialog from '@/components/dialogs/CompanyDialog'
import ConceptareaCompanyDialog from '@/components/dialogs/ConceptareaCompanyDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EmailDialog from '@/components/dialogs/EmailDialog'
import LeadCompanyDialog from '@/components/dialogs/LeadCompanyDialog'
import ListingDialog from '@/components/dialogs/ListingDialog'
import PauseDialog from '@/components/dialogs/PauseDialog'
import TooltipButton from '@/components/TooltipButton'
import color from '@/mixins/color'
import dataTable from '@/mixins/dataTable'
import dateAndTime from '@/mixins/dateAndTime.js'
import conceptareaCompanyDialog from '@/mixins/dialogs/conceptareaCompanyDialog'
import deleteConfirmationDialog from '@/mixins/dialogs/deleteConfirmationDialog.js'
import emailDialog from '@/mixins/dialogs/emailDialog'
import leadCompanyDialog from '@/mixins/dialogs/leadCompanyDialog'
import listingDialog from '@/mixins/dialogs/listingDialog.js'
import pauseDialog from '@/mixins/dialogs/pauseDialog'
import responseHelper from '@/mixins/responseHelper'
import stringHelpers from '@/mixins/stringHelpers'
import Conceptarea from '@/models/Conceptarea'
import ConceptareaCompany from '@/models/ConceptareaCompany'
import Company from '@/models/Company'
import Lead from '@/models/Lead'
import LeadCompany from '@/models/LeadCompany'
import Listing from '@/models/Listing'
import Pause from '@/models/Pause'

export default {
  components: {
    CompanyDialog,
    ConceptareaCompanyDialog,
    DeleteConfirmationDialog,
    EmailDialog,
    LeadCompanyDialog,
    ListingDialog,
    PauseDialog,
    TooltipButton,
  },
  mixins: [
    color,
    dataTable,
    dateAndTime,
    conceptareaCompanyDialog,
    deleteConfirmationDialog,
    emailDialog,
    leadCompanyDialog,
    listingDialog,
    pauseDialog,
    responseHelper,
    stringHelpers,
  ],
  data: () => {
    return {
      EXPAND_THRESHOLD: 150,
      loading: false,
      leadsIterator: {
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [2, 10, 20, 40, 100],
        },
        options: {
          itemsPerPage: 20,
          page: 1,
        },
      },
    }
  },
  computed: {
    updateCompanyButton() {
      return {
        button: {
          icon: 'edit',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('COMPANY_UPDATE')) ||
            this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('COMPANY_UPDATE')
              ? 'Rediger bedrift'
              : 'Du mangler tillatelse til å redigere bedrifter',
        },
      }
    },
    getPausesTitleClass() {
      if (this.activePauses.length) {
        return 'error black--text'
      }

      if (this.futurePauses.length) {
        return 'warning black--text'
      }

      if (this.pastPauses.length) {
        return 'success'
      }
      return 'primary'
    },
    activePauses() {
      return this.company.pauses.filter(pause => pause.is_active)
    },
    futurePauses() {
      return this.company.pauses.filter(pause => pause.is_future)
    },
    pastPauses() {
      return this.company.pauses.filter(pause => pause.is_past)
    },
    company() {
      return Company.query()
        .with('conceptareas')
        .with('conceptareas.area')
        .with('conceptareas.concept')
        .with('listings')
        .with('listings.company')
        .with('listings.website')
        .with('listings.leads')
        .with('pauses')
        .with('pauses.pausereason')
        .with('pauses.user')
        .whereId(parseInt(this.$route.params.company_id))
        .first()
    },
    websites() {
      const listingsByDomain = this.company.listings.reduce((groupedByDomain, listing) => {
        const domainName = listing.website.domainname
        if (!groupedByDomain[domainName]) {
          groupedByDomain[domainName] = []
        }
        groupedByDomain[domainName].push(listing)
        return groupedByDomain
      }, {})

      return listingsByDomain
    },
    concepts() {
      if (!this.company.conceptareas.length) return []

      const allConcepts = this.company.conceptareas.map(conceptarea => {
        return {
          concept_id: conceptarea.concept.concept_id,
          name: conceptarea.concept.name,
        }
      })

      const uniqueConcepts = [
        ...new Map(
          allConcepts.map(concept => [JSON.stringify([concept.concept_id, concept.name]), concept])
        ).values(),
      ]

      return uniqueConcepts
    },
    areasByConcept() {
      if (!this.company.conceptareas.length) return []

      const areasByConcept = {}
      const conceptIds = this.concepts.map(concept => concept.concept_id)

      conceptIds.forEach(conceptId => {
        const areas = this.company.conceptareas
          .filter(conceptarea => conceptarea.concept_id == conceptId)
          .map(conceptarea => {
            return { area_id: conceptarea.area.area_id, name: conceptarea.area.name }
          })

        areasByConcept[conceptId] = areas
      })
      return areasByConcept
    },
    leads() {
      const entities = Lead.query()
        .with('conceptarea')
        .with('conceptarea.concept')
        .with('conceptarea.area')
        .with('leadtype')
        .orderBy(lead => lead.created_timestamp.date, 'desc')
        .orderBy(lead => lead.created_timestamp.time, 'desc')
        .get()
      return entities
    },
    createConceptareaCompanyButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('CONCEPTAREA_COMPANY_CREATE')) ||
            this.loading,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('CONCEPTAREA_COMPANY_CREATE')
              ? 'Legg til konseptområde'
              : 'Du mangler tillatelse til å legge til konseptområder',
        },
      }
    },
    deleteConceptareaCompanyButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: 'Fjern dette konseptområdet fra bedriften',
        },
      }
    },
    createLeadCompanyButton() {
      return {
        button: {
          icon: 'forward',
          disabled: this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: 'Viderefordel dette leadet til en annen bedrift',
        },
      }
    },
  },
  async beforeCreate() {
    try {
      await Company.fetchOne(parseInt(this.$route.params.company_id))
    } catch (err) {
      console.error('Error fetching company: ', err)
    }
  },
  methods: {
    async setNewEmailAndUpdateLeadCompany(email) {
      this.setNewEmail(email)

      if (this.selectedLeadCompany?.$id) {
        await LeadCompany.updateOne(this.selectedLeadCompany.$id, {
          email_id: email.email_id,
        })
      }
    },
    async fetchData() {
      this.loading = true

      const [conceptareaCompanies, leadCompanies] = await Promise.all([
        ConceptareaCompany.fetchByKey('company_id', this.company.company_id),
        LeadCompany.fetchPaginated({
          pagination: this.leadsIterator.options,
          search: {
            company_id: this.$route.params.company_id,
          },
        }),
        Listing.fetchByKey('company_id', this.company.company_id),
        Pause.fetchByKey('company_id', this.company.company_id),
      ])

      this.leadsIterator.serverItemsLength = leadCompanies.response.data.total

      const leadIds = this.getIdsFromEntities(leadCompanies, 'lead_id')
      const leads = await Lead.fetchBatch(leadIds)

      const leadConceptareaIds = this.getIdsFromEntities(leads, 'conceptarea_id')
      const conceptareaIds = this.getIdsFromEntities(conceptareaCompanies, 'conceptarea_id')

      await Conceptarea.fetchBatch([
        ...conceptareaIds,
        ...(leadConceptareaIds ? leadConceptareaIds : []),
      ])

      this.loading = false
    },
    async deleteConceptareaCompany(concept_id, area_id) {
      const conceptarea = Conceptarea.query()
        .where('concept_id', concept_id)
        .where('area_id', area_id)
        .first()

      const conceptareaCompany = ConceptareaCompany.find([
        conceptarea.conceptarea_id,
        this.company.company_id,
      ])

      this.loading = true
      await conceptareaCompany.delete()
      this.loading = false
    },
  },
}
</script>

<style scoped lang="less">
dt {
  font-weight: bold;
  &:after {
    content: ':';
    margin-right: 1em;
  }
}

.pause {
  gap: 1rem;
  justify-content: space-between;

  &--over .pause__content {
    text-decoration: line-through;
    opacity: 0.6;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__time {
    gap: 2rem;
  }

  &__from,
  &__to {
    &:before {
      display: block;
      text-transform: uppercase;
      line-height: 1;
      font-size: 0.75em;
      letter-spacing: 1px;
    }
  }

  &__from:before {
    content: 'Fra';
  }

  &__to:before {
    content: 'Til';
  }

  &__comment {
    font-style: italic;
    font-size: 0.9em;
    line-height: 1.5;
  }
}

.listing {
  &s {
    width: 100%;
  }

  &__url {
    a {
      font-size: 0.9em;
    }
  }

  &__actions {
    text-align: right;
  }
}
</style>
