import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.blue.darken4,
        success: colors.green.darken3,
        warning: colors.orange.darken2,
        base: colors.grey.darken4,
      },
      dark: {
        primary: colors.blue.darken4,
        success: colors.green.darken3,
        warning: colors.orange.darken2,
        base: colors.grey.darken4,
      },
    },
  },
})
