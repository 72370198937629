import debounce from 'debounce'

export default {
  data: () => {
    return {
      dataTable: {
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [50, 100, 200, 500],
        },
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: [],
          sortDesc: [],
        },
        serverItemsLength: 50,
        search: '',
      },
    }
  },
  watch: {
    'dataTable.search': {
      handler: debounce(function() {
        this.fetchData()
      }, 1250),
      deep: true,
    },
  },
}
