<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-btn
      fixed
      fab
      bottom
      right
      color="primary"
      @click="$refs.conceptDialog.showDialog(conceptModel)"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-row class="justify-end">
      <v-col>
        <h1>Konsepter</h1>
      </v-col>
      <v-col class="white-space-nowrap flex-grow-0 py-0">
        <v-switch
          v-model="$currentUser.pref_show_child_concepts"
          label="Vis underkonsepter"
          dense
          @change="toggleShowChildConcepts"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-card class="pa-6" width="100%">
        <ul>
          <li v-for="concept in concepts" :key="concept.concept_id">
            <router-link
              :to="{ name: 'concept', params: { concept_id: concept.concept_id } }"
              style="text-decoration: none;"
            >
              <template v-if="concept.parent"> {{ concept.parent.name }} • </template>
              {{ concept.name }}
            </router-link>
          </li>
        </ul>
      </v-card>
    </v-row>

    <EntityDialog
      ref="conceptDialog"
      v-bind="conceptDialog"
      @entityCreated="handleConceptCreated"
    />
  </v-container>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence'
import responseHelper from '@/mixins/responseHelper'
import EntityDialog from '@/components/dialogs/EntityDialog'
import Concept from '@/models/Concept'
import User from '@/models/User'

export default {
  components: { EntityDialog },
  mixins: [entityPersistence, responseHelper],
  data: () => {
    return {
      loading: false,
      showChildren: false,
      conceptModel: Concept,
    }
  },
  computed: {
    concepts() {
      if (this.$currentUser.pref_show_child_concepts) {
        return Concept.query()
          .with('parent')
          .get()
          .sort((a, b) => {
            const fullNameA = `${a.parent ? `${a.parent.name}.` : ''}${a.name}`
            const fullNameB = `${b.parent ? `${b.parent.name}.` : ''}${b.name}`
            return fullNameA.localeCompare(fullNameB)
          })
      }

      return Concept.query()
        .where('parent_id', null)
        .orderBy('name')
        .get()
    },
    conceptDialog() {
      return {
        createTitle: 'Opprett konsept',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'parent_id',
            type: 'select',
            label: 'Foreldrekonsept',
            items: Concept.query()
              .orderBy('name')
              .get(),
            itemText: 'name',
            itemValue: 'concept_id',
            clearable: true,
          },
        ],
      }
    },
  },
  async beforeCreate() {
    try {
      await Concept.fetchAll()
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
  },
  methods: {
    handleConceptCreated(instance) {
      this.$refs.conceptDialog.entity = instance
      this.$successToast(`Du opprettet konseptet ${instance.name}`)
    },
    async toggleShowChildConcepts() {
      try {
        await this.createOrUpdateEntity(
          User,
          { pref_show_child_concepts: this.$currentUser.pref_show_child_concepts },
          this.$currentUser.user_id
        )
      } catch (e) {
        console.error('Error: ', e)
        //not a critical error, do nothing
      }
    },
  },
}
</script>

<style scoped lang="less">
ul {
  list-style: none;
  padding: 0;

  @media (min-width: 800px) {
    columns: 2;
    column-gap: 1rem;
  }

  @media (min-width: 1264px) {
    columns: 3;
  }

  @media (min-width: 1880px) {
    columns: 4;
  }

  li {
    padding: 0.5em 0;
    vertical-align: top;
  }
}
</style>
