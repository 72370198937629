import BaseModel from '@/models/BaseModel'
import ApikeyPermission from '@/models/ApikeyPermission'
import Permission from '@/models/Permission'

export default class Apikey extends BaseModel {
  static entity = 'apikeys'
  static primaryKey = 'apikey_id'

  static fields() {
    return {
      apikey_id: this.attr(null),
      name: this.string(),
      apikey: this.string(),
      locked: this.boolean(false),
      disabled: this.boolean(false),
      permissions: this.belongsToMany(Permission, ApikeyPermission, 'apikey_id', 'permission_id'),
    }
  }
}
