export default {
  methods: {
    getIdsFromEntities(response, field) {
      if (!response) {
        return
      }

      if (response.entities[response.model.entity]) {
        return response.entities[response.model.entity]
          .map(entity => entity[field])
          .filter(id => id != null)
      }

      return []
    },
  },
}
