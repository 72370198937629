<template>
  <v-container v-if="$currentUser" class="pa-16">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success">
            <v-toolbar-title class="white--text">Tillatelser</v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="$currentUser.hasPermission('PERMISSION_CREATE')"
              color="white"
              icon
              @click="editPermission({ active: true })"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear v-if="!permissions" indeterminate></v-progress-linear>
          <v-list>
            <v-list-item v-for="permission in permissions" :key="permission.permission_id">
              <v-list-item-content>
                <v-list-item-title v-text="permission.name"></v-list-item-title>
              </v-list-item-content>

              <v-btn
                v-if="$currentUser.hasPermission('PERMISSION_UPDATE')"
                icon
                @click="editPermission(permission)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn
                v-if="$currentUser.hasPermission('PERMISSION_DELETE')"
                icon
                @click="deletePermission(permission)"
              >
                <v-icon color="error">delete_forever</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showPermissionDialog" width="400" persistent>
      <v-card v-if="selectedPermission">
        <v-card-title class="headline primary white--text">
          <template v-if="selectedPermission.permission_id">Rediger tillatelse</template>
          <template v-else>Opprett tillatelse</template>
          <v-spacer />
          <v-btn v-if="!loading" icon color="white" @click="closePermissionDialog">
            <v-icon>close</v-icon>
          </v-btn>
          <v-progress-circular v-else indeterminate color="white" size="36" />
        </v-card-title>

        <v-card-text>
          <v-form ref="form" autocomplete="off">
            <v-text-field
              v-model="selectedPermission.name"
              label="Navn"
              :rules="[$validationRules.required]"
              :disabled="loading"
              @blur="handleNameField"
              @keyup.enter="handleNameField"
            />
          </v-form>
          <ErrorAlert v-bind="errorAlert" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import ErrorAlert from '@/components/ErrorAlert'
import Permission from '@/models/Permission'

export default {
  components: {
    ErrorAlert,
  },
  mixins: [entityPersistence, errorAlert],
  data: () => {
    return {
      loading: false,
      selectedPermission: null,
      showPermissionDialog: false,
      showDeleteConfirm: false,
      rules: {
        required: value => !!value || 'Påkrevd',
      },
    }
  },
  computed: {
    entity() {
      return this.selectedPermission
    },

    permissions() {
      return Permission.query()
        .orderBy('name')
        .get()
    },
  },
  methods: {
    closePermissionDialog() {
      this.selectedPermission = null
      this.showPermissionDialog = false
    },
    editPermission(permission) {
      this.selectedPermission = permission
      this.showPermissionDialog = true
    },
    async handleNameField() {
      if (!this.showPermissionDialog || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.selectedPermission.permission_id) {
        await this.createOrUpdateEntity(
          Permission,
          { name: this.selectedPermission.name },
          this.selectedPermission.permission_id
        )
      } else {
        await this.createEntity()
      }
    },
    async deletePermission(permission) {
      this.loading = true
      this.clearErrorAlert()

      try {
        await Permission.deleteOne(permission.permission_id)
        this.selectedPermission = null
        this.showDeleteConfirm = false
        this.showPermissionDialog = false
      } catch (e) {
        this.handleError(`Det oppsto en feil: ${e.message}`)
      }
      this.loading = false
    },
    async createEntity() {
      this.clearErrorAlert()
      this.selectedPermission = await this.createOrUpdateEntity(Permission, {
        name: this.selectedPermission.name,
      })
    },
  },
}
</script>
